export const FLOOR_3_DATA = [
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 11,
    sceneName: 'scene_A011',
    sceneName_future: 'scene_A011_future',
    name: 'A11',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 5,
      4: 20,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 12,
    sceneName: 'scene_A012',
    sceneName_future: 'scene_A012_future',
    name: 'A12',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      2: 6,
      4: 21,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 13,
    sceneName: 'scene_A013',
    sceneName_future: 'scene_A013_future',
    name: 'A13',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      2: 7,
      4: 22,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 14,
    sceneName: 'scene_A014',
    sceneName_future: 'scene_A014_future',
    name: 'A14',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      2: 8,
      4: 23,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 15,
    sceneName: 'scene_A015',
    sceneName_future: 'scene_A015_future',
    name: 'A15',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      2: 8,
      4: 24,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 16,
    sceneName: 'scene_A016',
    sceneName_future: 'scene_A016_future',
    name: 'A16',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      2: 8,
      4: 25,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 17,
    sceneName: 'scene_A017',
    sceneName_future: 'scene_A017_future',
    name: 'A17',
    lookAt: '-189, 0, 120',
    face: 'south',

    relatedApartments: {
      2: 8,
      4: 26,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 18,
    sceneName: 'scene_A018',
    sceneName_future: 'scene_A018_future',
    name: 'A18',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 9,
      4: 27,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 19,
    sceneName: 'scene_A019',
    sceneName_future: 'scene_A019_future',
    name: 'A19',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 10,
      4: 28,
    },
  },
]
