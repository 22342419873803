export const FLOOR_6_DATA = [
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 38,
    sceneName: 'scene_A038',
    sceneName_future: 'scene_A038_future',
    name: 'A38',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      5: 29,
      7: 47,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 39,
    sceneName: 'scene_A039',
    sceneName_future: 'scene_A039_future',
    name: 'A39',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      5: 30,
      7: 48,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 40,
    sceneName: 'scene_A040',
    sceneName_future: 'scene_A040_future',
    name: 'A40',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      5: 31,
      7: 49,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 41,
    sceneName: 'scene_A041',
    sceneName_future: 'scene_A041_future',
    name: 'A41',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      5: 32,
      7: 50,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 42,
    sceneName: 'scene_A042',
    sceneName_future: 'scene_A042_future',
    name: 'A42',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      5: 33,
      7: 51,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 43,
    sceneName: 'scene_A043',
    sceneName_future: 'scene_A043_future',
    name: 'A43',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      5: 34,
      7: 52,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 44,
    sceneName: 'scene_A044',
    sceneName_future: 'scene_A044_future',
    name: 'A44',
    lookAt: '-189, 0, 120',
    face: 'south',

    relatedApartments: {
      5: 35,
      7: 53,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 45,
    sceneName: 'scene_A045',
    sceneName_future: 'scene_A045_future',
    name: 'A45',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      5: 36,
      7: 54,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 46,
    sceneName: 'scene_A046',
    sceneName_future: 'scene_A046_future',
    name: 'A46',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      5: 37,
      7: 55,
    },
  },
]
