import * as React from 'react'
import styled from 'styled-components'

export default function Floor5({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="5"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_00000083790275358450881470000005034345430719224718_">
        <g id="static_00000114044698350951475360000007648033134264605852_">
          <rect
            id="plate_00000160905630175029346080000005447948830385383590_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(5, 29)}
          onMouseOver={() => setElementOnHover('A29')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A29"
            className={`st1 ${
              sceneName === 'scene_A029'
                ? 'active-apt'
                : elementOnHover === 'A29'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061
		77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301 42.2604675,72.9745026 33.9492569,72.9745026
		33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>

        <g
          onClick={() => changeView(5, 30)}
          onMouseOver={() => setElementOnHover('A30')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A30"
            className={`st1 ${
              sceneName === 'scene_A030'
                ? 'active-apt'
                : elementOnHover === 'A30'
                ? 'on-hover'
                : ''
            }`}
            points="41.2536697,10.6093349
		42.2604675,55.7983246 77.8867569,55.7983246 77.8867569,45.5375061 85.0117569,45.7250023 85.0117569,10.5916872 	"
          />
        </g>

        <g
          onClick={() => changeView(5, 31)}
          onMouseOver={() => setElementOnHover('A31')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A31"
            className={`st1 ${
              sceneName === 'scene_A031'
                ? 'active-apt'
                : elementOnHover === 'A31'
                ? 'on-hover'
                : ''
            }`}
            x="85.0117569"
            y="10.5916872"
            width="38.3938446"
            height="44.0395699"
          />
        </g>

        <g
          onClick={() => changeView(5, 32)}
          onMouseOver={() => setElementOnHover('A32')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A32"
            className={`st1 ${
              sceneName === 'scene_A032'
                ? 'active-apt'
                : elementOnHover === 'A32'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,72.1625061
		85.0117569,72.1625061 85.0117569,54.6312561 122.8242569,54.6312561 122.8242569,57.7250099 132.0742645,57.7250061
		132.0742645,70.6000061 122.8242569,70.6000061 	"
          />
        </g>

        <g
          onClick={() => changeView(5, 33)}
          onMouseOver={() => setElementOnHover('A33')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A33"
            className={`st1 ${
              sceneName === 'scene_A033'
                ? 'active-apt'
                : elementOnHover === 'A33'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,96.6420212
		85.0117569,96.6420212 85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 123.1149292,72.4125061
		122.8242569,83.7250061 132.0742645,83.7250061 132.0742645,96.3031311 122.8242569,96.3031311 	"
          />
        </g>
        <g
          onClick={() => changeView(5, 34)}
          onMouseOver={() => setElementOnHover('A34')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A34"
            className={`st1 ${
              sceneName === 'scene_A034'
                ? 'active-apt'
                : elementOnHover === 'A34'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,123.2562561
		103.4805069,123.2562561 103.4805069,113.8500061 85.0117569,114.1732712 85.0117569,96.6420212 122.8242569,96.6420212
		122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.3500061 122.8242569,120.3500061 	"
          />
        </g>
        <g
          onClick={() => changeView(5, 35)}
          onMouseOver={() => setElementOnHover('A35')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A35"
            className={`st1 ${
              sceneName === 'scene_A035'
                ? 'active-apt'
                : elementOnHover === 'A35'
                ? 'on-hover'
                : ''
            }`}
            points="123.3919067,149.6000061
		89.5169067,149.6000061 89.5169067,141.4750061 85.0117569,141.2381592 85.0117569,113.8500061 103.4805069,113.8500061
		103.6507797,123.7069016 122.8242569,123.7069016 	"
          />
        </g>
        <g
          onClick={() => changeView(5, 36)}
          onMouseOver={() => setElementOnHover('A36')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A36"
            className={`st1 ${
              sceneName === 'scene_A036'
                ? 'active-apt'
                : elementOnHover === 'A36'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,106.7914276
		77.4492569,106.7914276 77.4492569,141.4750061 43.0117569,141.7875061 42.750164,132.4725342 33.9492569,132.4725342
		33.9492569,119.5975266 42.750164,119.4750061 	"
          />
        </g>
        <g
          onClick={() => changeView(5, 37)}
          onMouseOver={() => setElementOnHover('A37')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A37"
            className={`st1 ${
              sceneName === 'scene_A037'
                ? 'active-apt'
                : elementOnHover === 'A37'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061
		70.8242493,74.2875061 70.8242569,90.3806229 77.4492569,90.3806229 77.4492569,106.7914276 42.8933792,107.0390778
		42.750164,101.9393234 33.9492569,101.9393234 33.9492569,89.0643234 42.750164,88.9417953 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000160902853008207968170000013610432339414521530_">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,141.4750061 42.8242569,141.4750061 	"
        />
        <polyline
          className="st3"
          points="41.2536697,19.9926529 41.2536697,10.6093349 123.4056015,10.6093349 123.4056015,19.4634018 	"
        />
        <polyline
          className="st3"
          points="122.8242569,57.7250061 132.0742645,57.7250061 132.0742645,70.6000061 122.8242569,70.6000061 	"
        />
        <polyline
          className="st3"
          points="122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.2250061 122.8242569,120.2250061
		"
        />
        <polyline
          className="st3"
          points="123.3919067,141.4750061 123.3919067,149.6000061 89.5169067,149.6000061 89.5169067,141.4750061
		"
        />
        <polyline
          className="st3"
          points="42.750164,132.4725342 33.9492569,132.4725342 33.9492569,119.4750061 42.750164,119.4750061 	"
        />
        <polyline
          className="st3"
          points="42.4986992,101.9198456 33.9492569,101.9198456 33.9492569,89.0065994 42.750164,89.0065994 	"
        />
        <polyline
          className="st3"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,10.5916872 85.0117569,45.7250023 85.0117569,141.4750061 	"
        />
        <polyline
          className="st4"
          points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,45.5375061 85.0117569,45.5375061
		77.8867569,45.5375061 77.8867569,141.4750061 	"
        />
        <line
          className="st4"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st4"
          x1="42.5387955"
          y1="106.7914276"
          x2="77.8867569"
          y2="106.7914276"
        />
        <polyline
          className="st4"
          points="85.0117569,113.8500061 103.4805069,113.8500061 103.4805069,123.2562561 122.8242569,123.2562561
		"
        />
        <polyline
          className="st3"
          points="122.8242569,96.4750061 132.0742645,96.4750061 132.0742645,83.7250061 122.8242569,83.7250061 	"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="72.1625061"
          x2="122.8242569"
          y2="72.1625061"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="54.6312561"
          x2="122.8242569"
          y2="54.6312561"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="96.6420212"
          x2="122.2897873"
          y2="96.3031311"
        />
        <polyline
          className="st4"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 	"
        />
        <polyline
          className="st4"
          points="70.8242569,73.9698181 70.8242569,90.3806229 77.8867569,90.3806229 	"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A029'
              ? 'white-text'
              : elementOnHover === 'A29'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 29)}
          onMouseOver={() => setElementOnHover('A29')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M53.80228,66.53751l1.5586-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H54.8184l-.4458,1.2295Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.1973,5.1973,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M60.72953,66.059v.47852H58.04691a.883.883,0,0,1,.0586-.34571,1.81755,1.81755,0,0,1,.32763-.54,5.09172,5.09172,0,0,1,.65186-.61425,5.52476,5.52476,0,0,0,.89453-.86036,1.02725,1.02725,0,0,0,.23242-.59863.66748.66748,0,0,0-.21191-.5.763.763,0,0,0-.55225-.20312.776.776,0,0,0-.57568.21582.8154.8154,0,0,0-.21875.59765l-.51221-.05273a1.25988,1.25988,0,0,1,.396-.873,1.35108,1.35108,0,0,1,.92187-.30078,1.28672,1.28672,0,0,1,.92432.32422,1.063,1.063,0,0,1,.34082.80273,1.215,1.215,0,0,1-.09961.47852,1.85643,1.85643,0,0,1-.331.49609,8.00205,8.00205,0,0,1-.76807.71387c-.29883.251-.49121.42188-.57568.51074a1.62575,1.62575,0,0,0-.21045.27051Z"
          />
          <path
            class="cls-7"
            d="M61.62211,65.599l.479-.04395a.82918.82918,0,0,0,.23242.48926.63807.63807,0,0,0,.43995.15235.76636.76636,0,0,0,.40283-.1045.86265.86265,0,0,0,.28369-.28125,1.65334,1.65334,0,0,0,.18555-.47461,2.50316,2.50316,0,0,0,.0747-.60937c0-.02246-.001-.05566-.00244-.09961a1.14458,1.14458,0,0,1-.40869.38672,1.10949,1.10949,0,0,1-.56055.14746,1.13457,1.13457,0,0,1-.85254-.36523,1.33609,1.33609,0,0,1-.34863-.9629,1.369,1.369,0,0,1,.36377-.99414,1.21685,1.21685,0,0,1,.91211-.377,1.29827,1.29827,0,0,1,.72412.21387,1.32778,1.32778,0,0,1,.498.60742,2.98486,2.98486,0,0,1,.17041,1.1416,3.69446,3.69446,0,0,1-.169,1.23926,1.44684,1.44684,0,0,1-.50244.70117,1.296,1.296,0,0,1-.78174.24121A1.07273,1.07273,0,0,1,61.62211,65.599Zm2.04-1.791a.97634.97634,0,0,0-.228-.68066.71619.71619,0,0,0-.5498-.252.75711.75711,0,0,0-.57862.27148,1.00553,1.00553,0,0,0-.24609.70313.86557.86557,0,0,0,.23389.6289.76766.76766,0,0,0,.57714.24219.73669.73669,0,0,0,.56885-.24219A.95281.95281,0,0,0,63.66215,63.808Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A030'
              ? 'white-text'
              : elementOnHover === 'A30'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 30)}
          onMouseOver={() => setElementOnHover('A30')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M53.80228,38.79971l1.5586-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H54.8184l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.2876-.84179a5.19764,5.19764,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M58.11381,37.72842l.498-.0664a1.10081,1.10081,0,0,0,.292.61035.71987.71987,0,0,0,.50244.18652A.806.806,0,0,0,60,38.21573a.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57032-.22266,1.42222,1.42222,0,0,0-.35156.05566l.05566-.4375a.71292.71292,0,0,0,.08008.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92124.92124,0,0,0-.249.54785l-.498-.08789a1.28433,1.28433,0,0,1,.415-.77734,1.20023,1.20023,0,0,1,.80567-.27539,1.3323,1.3323,0,0,1,.61181.14258,1.03654,1.03654,0,0,1,.42774.38964.9969.9969,0,0,1,.148.52247.85781.85781,0,0,1-.14112.47949.96679.96679,0,0,1-.418.34277.94616.94616,0,0,1,.55909.34473,1.05061,1.05061,0,0,1,.19921.65527,1.19761,1.19761,0,0,1-.38769.90039,1.36036,1.36036,0,0,1-.98.37012,1.27679,1.27679,0,0,1-.88721-.31836A1.23405,1.23405,0,0,1,58.11381,37.72842Z"
          />
          <path
            class="cls-7"
            d="M61.5474,36.79874a3.72109,3.72109,0,0,1,.14795-1.15918,1.44444,1.44444,0,0,1,.44043-.67676,1.127,1.127,0,0,1,.73486-.23828,1.19489,1.19489,0,0,1,.57276.13183,1.09875,1.09875,0,0,1,.40722.37891,2.06014,2.06014,0,0,1,.252.60352,4.03643,4.03643,0,0,1,.09131.96,3.70863,3.70863,0,0,1-.147,1.15234,1.44447,1.44447,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23926,1.11382,1.11382,0,0,1-.92187-.4209A2.67418,2.67418,0,0,1,61.5474,36.79874Zm.51221,0a2.54215,2.54215,0,0,0,.23389,1.33007.66861.66861,0,0,0,1.15429-.002,2.54593,2.54593,0,0,0,.23389-1.32812,2.54609,2.54609,0,0,0-.23389-1.332.694.694,0,0,0-.583-.32911.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,62.05961,36.79874Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A031'
              ? 'white-text'
              : elementOnHover === 'A31'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 31)}
          onMouseOver={() => setElementOnHover('A31')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M103.21,37.72842l.49805-.0664a1.10088,1.10088,0,0,0,.292.61035.71987.71987,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57032-.22266,1.42229,1.42229,0,0,0-.35156.05566l.05567-.4375a.71277.71277,0,0,0,.08007.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92124.92124,0,0,0-.249.54785l-.49805-.08789a1.28433,1.28433,0,0,1,.415-.77734,1.20023,1.20023,0,0,1,.80567-.27539,1.3323,1.3323,0,0,1,.61181.14258,1.03654,1.03654,0,0,1,.42774.38964.9969.9969,0,0,1,.14795.52247.85781.85781,0,0,1-.14112.47949.96671.96671,0,0,1-.418.34277.94617.94617,0,0,1,.55908.34473,1.05061,1.05061,0,0,1,.19922.65527,1.19762,1.19762,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.27679,1.27679,0,0,1-.88721-.31836A1.23405,1.23405,0,0,1,103.21,37.72842Z"
          />
          <path
            class="cls-7"
            d="M108.52055,38.79971H108.022v-3.1748a2.59009,2.59009,0,0,1-.47168.34277,3.39763,3.39763,0,0,1-.5249.25781v-.48144a3.02807,3.02807,0,0,0,.731-.47656,1.87326,1.87326,0,0,0,.44287-.543h.32129Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A032'
              ? 'white-text'
              : elementOnHover === 'A32'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 32)}
          onMouseOver={() => setElementOnHover('A32')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M98.899,65.57364l1.5586-4.05859h.57861l1.66065,4.05859H102.085l-.47314-1.22949H99.91508l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.197,5.197,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M103.21049,64.50235l.498-.06641a1.10088,1.10088,0,0,0,.292.61036.71987.71987,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24317.82052.82052,0,0,0,.24219-.60351.76565.76565,0,0,0-.22413-.56641.775.775,0,0,0-.57031-.22265,1.42175,1.42175,0,0,0-.35156.05566l.05566-.4375a.71292.71292,0,0,0,.08008.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.60694.60694,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92121.92121,0,0,0-.249.54785l-.49805-.08789a1.28432,1.28432,0,0,1,.415-.77735,1.20028,1.20028,0,0,1,.80567-.27539,1.33243,1.33243,0,0,1,.61181.14258,1.03649,1.03649,0,0,1,.42774.38965.99684.99684,0,0,1,.14795.52246.85781.85781,0,0,1-.14112.47949.96674.96674,0,0,1-.418.34278.94615.94615,0,0,1,.55909.34472,1.05064,1.05064,0,0,1,.19921.65528,1.19764,1.19764,0,0,1-.38769.90039,1.36035,1.36035,0,0,1-.98.37011,1.27678,1.27678,0,0,1-.88721-.31835A1.23408,1.23408,0,0,1,103.21049,64.50235Z"
          />
          <path
            class="cls-7"
            d="M109.26273,65.09512v.47852h-2.68261a.8828.8828,0,0,1,.05859-.3457,1.81735,1.81735,0,0,1,.32764-.54,5.09081,5.09081,0,0,1,.65185-.61426,5.52523,5.52523,0,0,0,.89453-.86035,1.02731,1.02731,0,0,0,.23243-.59864.66746.66746,0,0,0-.21192-.5.763.763,0,0,0-.55224-.20312.776.776,0,0,0-.57569.21582.81545.81545,0,0,0-.21875.59766l-.5122-.05274a1.25989,1.25989,0,0,1,.396-.873,1.35111,1.35111,0,0,1,.92188-.30078,1.2867,1.2867,0,0,1,.92431.32422,1.063,1.063,0,0,1,.34082.80274,1.215,1.215,0,0,1-.09961.47851,1.85614,1.85614,0,0,1-.331.4961,8.00354,8.00354,0,0,1-.76807.71386c-.29883.251-.49121.42188-.57568.51075a1.62635,1.62635,0,0,0-.21045.2705Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A033'
              ? 'white-text'
              : elementOnHover === 'A33'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 33)}
          onMouseOver={() => setElementOnHover('A33')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M98.89848,86.21915l1.55859-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H99.91459l-.4458,1.2295Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M103.21,85.14786l.49805-.06641a1.10084,1.10084,0,0,0,.292.61035.71988.71988,0,0,0,.50244.18653.806.806,0,0,0,.59375-.24317.82054.82054,0,0,0,.24219-.60351.76568.76568,0,0,0-.22412-.56641.775.775,0,0,0-.57032-.22266,1.4219,1.4219,0,0,0-.35156.05567l.05567-.4375a.71517.71517,0,0,0,.08007.00586,1.02,1.02,0,0,0,.57276-.167.56751.56751,0,0,0,.25488-.51172.60694.60694,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67831.67831,0,0,0-.48438.18261.92127.92127,0,0,0-.249.54786l-.49805-.08789a1.28432,1.28432,0,0,1,.415-.77735,1.20028,1.20028,0,0,1,.80567-.27539,1.3323,1.3323,0,0,1,.61181.14258,1.03649,1.03649,0,0,1,.42774.38965.99684.99684,0,0,1,.14795.52246.85781.85781,0,0,1-.14112.47949.96664.96664,0,0,1-.418.34277.94617.94617,0,0,1,.55908.34473,1.05063,1.05063,0,0,1,.19922.65527,1.19765,1.19765,0,0,1-.3877.9004,1.36035,1.36035,0,0,1-.98.37011,1.27674,1.27674,0,0,1-.88721-.31836A1.234,1.234,0,0,1,103.21,85.14786Z"
          />
          <path
            class="cls-7"
            d="M106.64652,85.14786l.49805-.06641a1.10091,1.10091,0,0,0,.292.61035.71991.71991,0,0,0,.50244.18653.806.806,0,0,0,.59375-.24317.82054.82054,0,0,0,.24219-.60351.76568.76568,0,0,0-.22412-.56641.775.775,0,0,0-.57031-.22266,1.4219,1.4219,0,0,0-.35157.05567l.05567-.4375a.71516.71516,0,0,0,.08008.00586,1.02,1.02,0,0,0,.57275-.167.56751.56751,0,0,0,.25488-.51172.607.607,0,0,0-.18554-.4541.658.658,0,0,0-.479-.17969.67827.67827,0,0,0-.48437.18261.92121.92121,0,0,0-.249.54786l-.498-.08789a1.28427,1.28427,0,0,1,.415-.77735,1.20026,1.20026,0,0,1,.80566-.27539,1.33238,1.33238,0,0,1,.61182.14258,1.03653,1.03653,0,0,1,.42773.38965.99684.99684,0,0,1,.14795.52246.8578.8578,0,0,1-.14111.47949.96673.96673,0,0,1-.418.34277.94614.94614,0,0,1,.55908.34473,1.05063,1.05063,0,0,1,.19922.65527,1.19762,1.19762,0,0,1-.3877.9004,1.36035,1.36035,0,0,1-.98.37011,1.27673,1.27673,0,0,1-.8872-.31836A1.234,1.234,0,0,1,106.64652,85.14786Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A034'
              ? 'white-text'
              : elementOnHover === 'A34'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 34)}
          onMouseOver={() => setElementOnHover('A34')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M98.898,108.4252l1.55859-4.05859h.57861l1.66065,4.05859H102.084l-.47314-1.22949H99.9141l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M103.20951,107.35391l.49805-.0664a1.10088,1.10088,0,0,0,.292.61035.71991.71991,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57031-.22266,1.42182,1.42182,0,0,0-.35157.05566l.05567-.4375a.71276.71276,0,0,0,.08008.00586,1.01992,1.01992,0,0,0,.57275-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18554-.4541.658.658,0,0,0-.479-.17969.67828.67828,0,0,0-.48437.18262.92118.92118,0,0,0-.249.54785l-.498-.08789a1.28422,1.28422,0,0,1,.415-.77734A1.2002,1.2002,0,0,1,104.48,104.35a1.33249,1.33249,0,0,1,.61182.14257,1.03659,1.03659,0,0,1,.42773.38965.99686.99686,0,0,1,.14795.52246.85793.85793,0,0,1-.14111.4795.96679.96679,0,0,1-.418.34277.94609.94609,0,0,1,.55908.34473,1.05059,1.05059,0,0,1,.19922.65527,1.19763,1.19763,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.27678,1.27678,0,0,1-.8872-.31836A1.234,1.234,0,0,1,103.20951,107.35391Z"
          />
          <path
            class="cls-7"
            d="M108.24027,108.4252v-.97168h-1.76074v-.457l1.85205-2.62988h.40723v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.2705,1.8291Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A035'
              ? 'white-text'
              : elementOnHover === 'A35'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 35)}
          onMouseOver={() => setElementOnHover('A35')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M98.89848,134.1752l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M103.21,133.10391l.49805-.0664a1.10088,1.10088,0,0,0,.292.61035.71987.71987,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57032-.22266,1.42182,1.42182,0,0,0-.35156.05566l.05567-.4375a.71277.71277,0,0,0,.08007.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92124.92124,0,0,0-.249.54785l-.49805-.08789a1.28433,1.28433,0,0,1,.415-.77734,1.20023,1.20023,0,0,1,.80567-.27539,1.33242,1.33242,0,0,1,.61181.14257,1.03656,1.03656,0,0,1,.42774.38965.99686.99686,0,0,1,.14795.52246.85786.85786,0,0,1-.14112.4795.96671.96671,0,0,1-.418.34277.94612.94612,0,0,1,.55908.34473,1.05059,1.05059,0,0,1,.19922.65527,1.19763,1.19763,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.27679,1.27679,0,0,1-.88721-.31836A1.23405,1.23405,0,0,1,103.21,133.10391Z"
          />
          <path
            class="cls-7"
            d="M106.64359,133.11172l.52295-.04394a.93828.93828,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.7952.7952,0,0,0,.60889-.27051,1.02224,1.02224,0,0,0,.24951-.71972.92428.92428,0,0,0-.23974-.67285.83624.83624,0,0,0-.627-.24708.87559.87559,0,0,0-.43457.10938.85049.85049,0,0,0-.30468.28418l-.46778-.06055.39307-2.085h2.01807v.47656H107.524l-.21875,1.09082a1.31954,1.31954,0,0,1,.76661-.25488,1.2122,1.2122,0,0,1,.897.36817,1.28481,1.28481,0,0,1,.36523.94628,1.47853,1.47853,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49317,1.31122,1.31122,0,0,1-.9038-.31055A1.20812,1.20812,0,0,1,106.64359,133.11172Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A036'
              ? 'white-text'
              : elementOnHover === 'A36'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 36)}
          onMouseOver={() => setElementOnHover('A36')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M53.8018,125.974l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M58.11332,124.90274l.498-.06641a1.10088,1.10088,0,0,0,.292.61036.71987.71987,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82056.82056,0,0,0,.24219-.60352.76568.76568,0,0,0-.22412-.56641.77507.77507,0,0,0-.57032-.22265,1.42182,1.42182,0,0,0-.35156.05566l.05567-.4375a.71277.71277,0,0,0,.08007.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92124.92124,0,0,0-.249.54785l-.498-.08789a1.28435,1.28435,0,0,1,.415-.77735,1.20028,1.20028,0,0,1,.80567-.27539,1.33243,1.33243,0,0,1,.61181.14258,1.03656,1.03656,0,0,1,.42774.38965.99684.99684,0,0,1,.148.52246.85781.85781,0,0,1-.14112.47949.96665.96665,0,0,1-.418.34278.94613.94613,0,0,1,.55908.34472,1.05064,1.05064,0,0,1,.19922.65528,1.19765,1.19765,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.2768,1.2768,0,0,1-.88721-.31836A1.23405,1.23405,0,0,1,58.11332,124.90274Z"
          />
          <path
            class="cls-7"
            d="M64.13236,122.90958l-.4956.03906a.94165.94165,0,0,0-.188-.42676.66117.66117,0,0,0-.49854-.21289.68313.68313,0,0,0-.418.13281,1.13784,1.13784,0,0,0-.3711.501,2.62529,2.62529,0,0,0-.14111.93848,1.15777,1.15777,0,0,1,.44043-.40723,1.19114,1.19114,0,0,1,.54541-.13281,1.12749,1.12749,0,0,1,.84814.36718,1.31616,1.31616,0,0,1,.3501.94825,1.56143,1.56143,0,0,1-.16455.71,1.1987,1.1987,0,0,1-.45264.502,1.23647,1.23647,0,0,1-.65332.17481,1.275,1.275,0,0,1-1.01562-.458,2.31307,2.31307,0,0,1-.39307-1.51074,2.70145,2.70145,0,0,1,.43457-1.71,1.24808,1.24808,0,0,1,1.02149-.46582,1.08689,1.08689,0,0,1,1.15136,1.01075Zm-2.03467,1.75a1.156,1.156,0,0,0,.10938.49218.81187.81187,0,0,0,.30566.3584.76656.76656,0,0,0,.4126.12305.70466.70466,0,0,0,.54248-.25391,1.00266,1.00266,0,0,0,.227-.69238.93838.93838,0,0,0-.22412-.66309.73792.73792,0,0,0-.56445-.24218.76953.76953,0,0,0-.57324.24218A.87314.87314,0,0,0,62.09769,124.65958Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A037'
              ? 'white-text'
              : elementOnHover === 'A37'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 37)}
          onMouseOver={() => setElementOnHover('A37')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            class="cls-7"
            d="M53.8018,91.99893l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            class="cls-7"
            d="M58.11332,90.92764l.498-.0664a1.10088,1.10088,0,0,0,.292.61035.71987.71987,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57032-.22266,1.42182,1.42182,0,0,0-.35156.05566l.05567-.4375a.71277.71277,0,0,0,.08007.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92124.92124,0,0,0-.249.54785l-.498-.08789a1.28433,1.28433,0,0,1,.415-.77734,1.20023,1.20023,0,0,1,.80567-.27539,1.33242,1.33242,0,0,1,.61181.14257,1.03656,1.03656,0,0,1,.42774.38965.99686.99686,0,0,1,.148.52246.85782.85782,0,0,1-.14112.4795.96671.96671,0,0,1-.418.34277.94609.94609,0,0,1,.55908.34473,1.05059,1.05059,0,0,1,.19922.65527,1.19762,1.19762,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.2768,1.2768,0,0,1-.88721-.31836A1.23405,1.23405,0,0,1,58.11332,90.92764Z"
          />
          <path
            class="cls-7"
            d="M61.58012,88.47256v-.47949h2.627v.3877a5.53506,5.53506,0,0,0-.76807,1.09668,6.65047,6.65047,0,0,0-.58838,1.40625,5.227,5.227,0,0,0-.19091,1.11523H62.1475a4.95767,4.95767,0,0,1,.18848-1.15722,6.06243,6.06243,0,0,1,.51611-1.30762,5.59157,5.59157,0,0,1,.71533-1.06153Z"
          />
        </g>
      </g>
      <g id="state_2_" className="st9">
        <path
          id="plate_12_"
          className="st10"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />
        <text
          id="_x33__3_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st10 st11 st12"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st10 st11 st12"
        >
          2021
        </text>
        <g className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="_x33__2_" className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="down_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g id="up_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 29 ? (
          <g id="A29">
            <linearGradient
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              style={{
                fill: 'url(#A11cone_00000116942025443687874220000014186524061492083896_)',
              }}
              d="
			M33.1855202,69.3973846c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 30 ? (
          <g id="A30">
            <linearGradient
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              style={{
                fill: 'url(#A11cone_00000049918791379197865380000014062642777780403637_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 31 ? (
          <g id="A31">
            <linearGradient
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              style={{
                fill: 'url(#A11cone_00000101824782659766024400000003894925743768571553_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 32 ? (
          <g id="A32">
            <linearGradient
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              gradientUnits="userSpaceOnUse"
              x1="211.7723846"
              y1="309.9545898"
              x2="211.7723846"
              y2="265.0445862"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000104685683903993578170000014400541892270177670_)',
              }}
              d="
			M123.146759,67.1088257c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.2567596,41.838829V85.62883L123.146759,67.1088257z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 33 ? (
          <g id="A33">
            <linearGradient
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              gradientUnits="userSpaceOnUse"
              x1="237.6501465"
              y1="309.7736206"
              x2="237.6501465"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              style={{
                fill: 'url(#A11cone_00000132062808605534171860000004161307431760505756_)',
              }}
              d="
			M122.9657669,92.9865952c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,67.7165985v43.7900009L122.9657669,92.9865952z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 34 ? (
          <g id="A34">
            <linearGradient
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              gradientUnits="userSpaceOnUse"
              x1="261.5904846"
              y1="309.7736206"
              x2="261.5904846"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              style={{
                fill: 'url(#A11cone_00000161621834216018166600000011104810949275289753_)',
              }}
              d="
			M122.9657669,116.926918c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,91.6569214v43.7899933L122.9657669,116.926918z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 35 ? (
          <g id="A35">
            <linearGradient
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              gradientUnits="userSpaceOnUse"
              x1="293.358429"
              y1="288.4524536"
              x2="293.358429"
              y2="243.5424652"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              style={{
                fill: 'url(#A11cone_00000046338767822234361200000006092721421211844490_)',
              }}
              d="
			M101.6446304,148.69487c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0399933,0.2100067-2.0299988,0.6000061-2.9199982L56.754631,123.4248734v43.7900009L101.6446304,148.69487z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 36 ? (
          <g id="A36">
            <linearGradient
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              gradientUnits="userSpaceOnUse"
              x1="273.7284241"
              y1="219.9933624"
              x2="273.7284241"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              style={{
                fill: 'url(#A11cone_00000119105400635965422190000001418125762866781067_)',
              }}
              d="
			M33.1855202,129.0648804c-0.3699951-0.8899994-0.5800018-1.8600082-0.5800018-2.890007
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900085L33.1855202,129.0648804z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 37 ? (
          <g id="A37">
            <linearGradient
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              gradientUnits="userSpaceOnUse"
              x1="242.9080505"
              y1="220.4395905"
              x2="242.9080505"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              style={{
                fill: 'url(#A11cone_00000030460207653560477860000005665363980855605161_)',
              }}
              d="
			M33.6317444,98.2444992c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900009L33.6317444,98.2444992z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_2_">
        <path
          id="plate_11_"
          className="st6"
          d="M172.1359558,110.5138321h-0.0000153c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802
		V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802h0.0000153
		c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_10_"
          className="st14"
          d="M178.3349304,90.8048477l-0.243988-38.549572
		c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688l0.243988,38.549572
		c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__00000137811791965440926010000000229068312505897893_">
          <path
            className="st15"
            d="M170.3613739,84.6244202l0.7851563-0.0664063c0.0585938,0.3823242,0.1933594,0.6694336,0.4052734,0.8618164
			c0.2109375,0.1923828,0.4667969,0.2885742,0.765625,0.2885742c0.3603516,0,0.6640625-0.1357422,0.9140625-0.4067383
			c0.2490234-0.2714844,0.3730469-0.6313477,0.3730469-1.0795898c0-0.4262695-0.1191406-0.7626953-0.359375-1.0092773
			c-0.2392578-0.2460938-0.5527344-0.3696289-0.9404297-0.3696289c-0.2402344,0-0.4580078,0.0546875-0.6513672,0.1640625
			c-0.1943359,0.109375-0.3466797,0.2514648-0.4570313,0.4257813l-0.7021484-0.0913086l0.5898438-3.1269531h3.0273438v0.7143555
			h-2.4287109l-0.328125,1.6362305c0.3652344-0.2548828,0.7480469-0.3823242,1.1494141-0.3823242
			c0.5322266,0,0.9804688,0.184082,1.3457031,0.5522461s0.5478516,0.8417969,0.5478516,1.4204102
			c0,0.5507813-0.1601563,1.0268555-0.4814453,1.4282227c-0.390625,0.4926758-0.9228516,0.7392578-1.5986328,0.7392578
			c-0.5537109,0-1.0058594-0.1552734-1.3554688-0.465332C170.6113739,85.54776,170.4111786,85.1366272,170.3613739,84.6244202z"
          />
        </g>
        <path
          id="up_9_"
          className="st16"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_9_"
          className="st16"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st6"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st6"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  29: [37.7855263, 66.627388],
  30: [62.2647095, 14.9237556],
  31: [103.8317642, 14.6850052],
  32: [127.7467651, 64.338829],
  33: [127.565773, 90.2165985],
  34: [127.565773, 114.1569214],
  35: [106.2446365, 145.924881],
  36: [37.7855263, 126.2948761],
  37: [38.2317505, 95.4745026],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    font-family: 'ArialMT';
  }
  .st8 {
    font-size: 5.6692901px;
  }
  .st9 {
    display: none;
  }
  .st10 {
    display: inline;
    fill: #3f3f3c;
  }
  .st11 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st12 {
    font-size: 8.5039396px;
  }
  .st13 {
    display: inline;
  }
  .st14 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #d3d2cf;
  }
  .st16 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: url(#A11cone_00000031908242821372644320000010099770893659807883_);
  }
  .st18 {
    fill: #c65805;
  }
  .st19 {
    fill: url(#A11cone_00000030460933582450340840000016738180085453283200_);
  }
  .st20 {
    fill: url(#A11cone_00000083803232135376585140000011405799044347850120_);
  }
  .st21 {
    fill: url(#A11cone_00000181045771646907618530000009514442668071759542_);
  }
  .st22 {
    fill: url(#A11cone_00000155131472214916524750000011241014034238265226_);
  }
  .st23 {
    fill: url(#A11cone_00000027604712313906409150000013128361352687773609_);
  }
  .st24 {
    fill: url(#A11cone_00000070101668780062261030000012829174151800702085_);
  }
  .st25 {
    fill: url(#A11cone_00000157289664823482042060000003122567549782656903_);
  }
  .st26 {
    fill: url(#A11cone_00000004528441680822221230000012008214006472699031_);
  }

  #A11,
  #A12,
  #A13,
  #A14,
  #A15,
  #B39,
  #B40,
  #B41,
  #C65,
  #C66,
  #C67,
  #C68,
  #C69,
  #C70 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-7 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
