import * as React from 'react'
import styled from 'styled-components'

export default function Floor6({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="5"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_00000083790275358450881470000005034345430719224718_">
        <g id="static_00000114044698350951475360000007648033134264605852_">
          <rect
            id="plate_00000160905630175029346080000005447948830385383590_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(6, 38)}
          onMouseOver={() => setElementOnHover('A38')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A38"
            className={`st1 ${
              sceneName === 'scene_A038'
                ? 'active-apt'
                : elementOnHover === 'A38'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061
		77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301 42.2604675,72.9745026 33.9492569,72.9745026
		33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>

        <g
          onClick={() => changeView(6, 39)}
          onMouseOver={() => setElementOnHover('A39')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A39"
            className={`st1 ${
              sceneName === 'scene_A039'
                ? 'active-apt'
                : elementOnHover === 'A39'
                ? 'on-hover'
                : ''
            }`}
            points="41.2536697,10.6093349
		42.2604675,55.7983246 77.8867569,55.7983246 77.8867569,45.5375061 85.0117569,45.7250023 85.0117569,10.5916872 	"
          />
        </g>

        <g
          onClick={() => changeView(6, 40)}
          onMouseOver={() => setElementOnHover('A40')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A40"
            className={`st1 ${
              sceneName === 'scene_A040'
                ? 'active-apt'
                : elementOnHover === 'A40'
                ? 'on-hover'
                : ''
            }`}
            x="85.0117569"
            y="10.5916872"
            width="38.3938446"
            height="44.0395699"
          />
        </g>

        <g
          onClick={() => changeView(6, 41)}
          onMouseOver={() => setElementOnHover('A41')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A41"
            className={`st1 ${
              sceneName === 'scene_A041'
                ? 'active-apt'
                : elementOnHover === 'A41'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,72.1625061
		85.0117569,72.1625061 85.0117569,54.6312561 122.8242569,54.6312561 122.8242569,57.7250099 132.0742645,57.7250061
		132.0742645,70.6000061 122.8242569,70.6000061 	"
          />
        </g>

        <g
          onClick={() => changeView(6, 42)}
          onMouseOver={() => setElementOnHover('A42')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A42"
            className={`st1 ${
              sceneName === 'scene_A042'
                ? 'active-apt'
                : elementOnHover === 'A42'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,96.6420212
		85.0117569,96.6420212 85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 123.1149292,72.4125061
		122.8242569,83.7250061 132.0742645,83.7250061 132.0742645,96.3031311 122.8242569,96.3031311 	"
          />
        </g>
        <g
          onClick={() => changeView(6, 43)}
          onMouseOver={() => setElementOnHover('A43')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A43"
            className={`st1 ${
              sceneName === 'scene_A043'
                ? 'active-apt'
                : elementOnHover === 'A43'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,123.2562561
		103.4805069,123.2562561 103.4805069,113.8500061 85.0117569,114.1732712 85.0117569,96.6420212 122.8242569,96.6420212
		122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.3500061 122.8242569,120.3500061 	"
          />
        </g>
        <g
          onClick={() => changeView(6, 44)}
          onMouseOver={() => setElementOnHover('A44')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A44"
            className={`st1 ${
              sceneName === 'scene_A044'
                ? 'active-apt'
                : elementOnHover === 'A44'
                ? 'on-hover'
                : ''
            }`}
            points="123.3919067,149.6000061
		89.5169067,149.6000061 89.5169067,141.4750061 85.0117569,141.2381592 85.0117569,113.8500061 103.4805069,113.8500061
		103.6507797,123.7069016 122.8242569,123.7069016 	"
          />
        </g>
        <g
          onClick={() => changeView(6, 45)}
          onMouseOver={() => setElementOnHover('A45')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A45"
            className={`st1 ${
              sceneName === 'scene_A045'
                ? 'active-apt'
                : elementOnHover === 'A45'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,106.7914276
		77.4492569,106.7914276 77.4492569,141.4750061 43.0117569,141.7875061 42.750164,132.4725342 33.9492569,132.4725342
		33.9492569,119.5975266 42.750164,119.4750061 	"
          />
        </g>
        <g
          onClick={() => changeView(6, 46)}
          onMouseOver={() => setElementOnHover('A46')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A46"
            className={`st1 ${
              sceneName === 'scene_A046'
                ? 'active-apt'
                : elementOnHover === 'A46'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061
		70.8242493,74.2875061 70.8242569,90.3806229 77.4492569,90.3806229 77.4492569,106.7914276 42.8933792,107.0390778
		42.750164,101.9393234 33.9492569,101.9393234 33.9492569,89.0643234 42.750164,88.9417953 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000021113600375709047690000001384572297452693159_">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,141.4750061 42.8242569,141.4750061 	"
        />
        <polyline
          className="st3"
          points="41.2536697,19.9926529 41.2536697,10.6093349 123.4056015,10.6093349 123.4056015,19.4634018 	"
        />
        <polyline
          className="st3"
          points="122.8242569,57.7250061 132.0742645,57.7250061 132.0742645,70.6000061 122.8242569,70.6000061 	"
        />
        <polyline
          className="st3"
          points="122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.2250061 122.8242569,120.2250061
		"
        />
        <polyline
          className="st3"
          points="123.3919067,141.4750061 123.3919067,149.6000061 89.5169067,149.6000061 89.5169067,141.4750061
		"
        />
        <polyline
          className="st3"
          points="42.750164,132.4725342 33.9492569,132.4725342 33.9492569,119.4750061 42.750164,119.4750061 	"
        />
        <polyline
          className="st3"
          points="42.4986992,101.9198456 33.9492569,101.9198456 33.9492569,89.0065994 42.750164,89.0065994 	"
        />
        <polyline
          className="st3"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,10.5916872 85.0117569,45.7250023 85.0117569,141.4750061 	"
        />
        <polyline
          className="st4"
          points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,45.5375061 85.0117569,45.5375061
		77.8867569,45.5375061 77.8867569,141.4750061 	"
        />
        <line
          className="st4"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st4"
          x1="42.5387955"
          y1="106.7914276"
          x2="77.8867569"
          y2="106.7914276"
        />
        <polyline
          className="st4"
          points="85.0117569,113.8500061 103.4805069,113.8500061 103.4805069,123.2562561 122.8242569,123.2562561
		"
        />
        <polyline
          className="st3"
          points="122.8242569,96.4750061 132.0742645,96.4750061 132.0742645,83.7250061 122.8242569,83.7250061 	"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="72.1625061"
          x2="122.8242569"
          y2="72.1625061"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="54.6312561"
          x2="122.8242569"
          y2="54.6312561"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="96.6420212"
          x2="122.2897873"
          y2="96.3031311"
        />
        <polyline
          className="st4"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 	"
        />
        <polyline
          className="st4"
          points="70.8242569,73.9698181 70.8242569,90.3806229 77.8867569,90.3806229 	"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A038'
              ? 'white-text'
              : elementOnHover === 'A38'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 38)}
          onMouseOver={() => setElementOnHover('A38')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.80228,66.53751l1.5586-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H54.8184l-.4458,1.2295Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.1973,5.1973,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.11381,65.46622l.498-.06641a1.10078,1.10078,0,0,0,.292.61035.71988.71988,0,0,0,.50244.18653A.806.806,0,0,0,60,65.95352a.82054.82054,0,0,0,.24219-.60351.76568.76568,0,0,0-.22412-.56641.775.775,0,0,0-.57032-.22266,1.42183,1.42183,0,0,0-.35156.05567l.05566-.4375a.71532.71532,0,0,0,.08008.00586,1.02,1.02,0,0,0,.57276-.167.56749.56749,0,0,0,.25488-.51171.607.607,0,0,0-.18555-.45411.658.658,0,0,0-.479-.17968.67831.67831,0,0,0-.48438.18261.92124.92124,0,0,0-.249.54786l-.498-.08789a1.28432,1.28432,0,0,1,.415-.77735,1.20028,1.20028,0,0,1,.80567-.27539,1.3323,1.3323,0,0,1,.61181.14258,1.03649,1.03649,0,0,1,.42774.38965.99684.99684,0,0,1,.148.52246.85781.85781,0,0,1-.14112.47949.96673.96673,0,0,1-.418.34277.94616.94616,0,0,1,.55909.34473,1.05063,1.05063,0,0,1,.19921.65527,1.19761,1.19761,0,0,1-.38769.90039,1.36036,1.36036,0,0,1-.98.37012,1.27674,1.27674,0,0,1-.88721-.31836A1.234,1.234,0,0,1,58.11381,65.46622Z"
          />
          <path
            className="cls-7"
            d="M62.314,64.33633a.93825.93825,0,0,1-.45947-.32324.84908.84908,0,0,1-.14941-.5039.98618.98618,0,0,1,.31836-.74512,1.34,1.34,0,0,1,1.70263.00683,1.0011,1.0011,0,0,1,.32373.752.826.826,0,0,1-.148.49121.93143.93143,0,0,1-.45019.32226,1.09505,1.09505,0,0,1,.56885.39356,1.07662,1.07662,0,0,1,.19531.64746,1.16487,1.16487,0,0,1-.36817.875,1.49936,1.49936,0,0,1-1.9375-.001,1.18362,1.18362,0,0,1-.36816-.88769,1.07145,1.07145,0,0,1,.20068-.66309A1.01124,1.01124,0,0,1,62.314,64.33633Zm-.26025,1.03028a.90122.90122,0,0,0,.10107.41211.71018.71018,0,0,0,.3003.30859.87561.87561,0,0,0,.4292.10938.80386.80386,0,0,0,.58984-.2295.787.787,0,0,0,.23242-.584.7984.7984,0,0,0-.23926-.59473.82021.82021,0,0,0-.59961-.23535.79282.79282,0,0,0-.814.81348Zm.16064-1.874a.63385.63385,0,0,0,.18555.47071.65551.65551,0,0,0,.48145.18261.64305.64305,0,0,0,.47216-.18164.59987.59987,0,0,0,.18409-.44433.62211.62211,0,0,0-.18946-.46094.69751.69751,0,0,0-.94531-.00391A.58886.58886,0,0,0,62.21439,63.49258Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A039'
              ? 'white-text'
              : elementOnHover === 'A39'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 39)}
          onMouseOver={() => setElementOnHover('A39')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.80228,38.79971l1.5586-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H54.8184l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.2876-.84179a5.19764,5.19764,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.11381,37.72842l.498-.0664a1.10081,1.10081,0,0,0,.292.61035.71987.71987,0,0,0,.50244.18652A.806.806,0,0,0,60,38.21573a.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57032-.22266,1.42222,1.42222,0,0,0-.35156.05566l.05566-.4375a.71292.71292,0,0,0,.08008.00586,1.02,1.02,0,0,0,.57276-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18555-.4541.65792.65792,0,0,0-.479-.17969.67832.67832,0,0,0-.48438.18262.92124.92124,0,0,0-.249.54785l-.498-.08789a1.28433,1.28433,0,0,1,.415-.77734,1.20023,1.20023,0,0,1,.80567-.27539,1.3323,1.3323,0,0,1,.61181.14258,1.03654,1.03654,0,0,1,.42774.38964.9969.9969,0,0,1,.148.52247.85781.85781,0,0,1-.14112.47949.96679.96679,0,0,1-.418.34277.94616.94616,0,0,1,.55909.34473,1.05061,1.05061,0,0,1,.19921.65527,1.19761,1.19761,0,0,1-.38769.90039,1.36036,1.36036,0,0,1-.98.37012,1.27679,1.27679,0,0,1-.88721-.31836A1.23405,1.23405,0,0,1,58.11381,37.72842Z"
          />
          <path
            className="cls-7"
            d="M61.62211,37.86124l.479-.044a.82921.82921,0,0,0,.23242.48926.63806.63806,0,0,0,.43995.15234.76636.76636,0,0,0,.40283-.10449.86265.86265,0,0,0,.28369-.28125,1.65346,1.65346,0,0,0,.18555-.47461,2.50322,2.50322,0,0,0,.0747-.60937c0-.02246-.001-.05567-.00244-.09961a1.14458,1.14458,0,0,1-.40869.38672,1.10949,1.10949,0,0,1-.56055.14746,1.13455,1.13455,0,0,1-.85254-.36524,1.336,1.336,0,0,1-.34863-.96289,1.369,1.369,0,0,1,.36377-.99414,1.21681,1.21681,0,0,1,.91211-.377,1.29827,1.29827,0,0,1,.72412.21387,1.32778,1.32778,0,0,1,.498.60742,2.98482,2.98482,0,0,1,.17041,1.1416,3.6945,3.6945,0,0,1-.169,1.23926,1.44684,1.44684,0,0,1-.50244.70117,1.296,1.296,0,0,1-.78174.24121,1.07273,1.07273,0,0,1-1.14062-1.00781Zm2.04-1.791a.97634.97634,0,0,0-.228-.68066.7162.7162,0,0,0-.5498-.252.75712.75712,0,0,0-.57862.27149,1.00551,1.00551,0,0,0-.24609.70312.86558.86558,0,0,0,.23389.62891.76766.76766,0,0,0,.57714.24219.73669.73669,0,0,0,.56885-.24219A.95283.95283,0,0,0,63.66215,36.07022Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A040'
              ? 'white-text'
              : elementOnHover === 'A40'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 40)}
          onMouseOver={() => setElementOnHover('A40')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M104.80424,38.79971V37.828H103.0435v-.457l1.85205-2.62988h.40722V37.371h.54785v.457h-.54785v.97168Zm0-1.42871V35.5419l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M106.64359,36.79874a3.72109,3.72109,0,0,1,.14795-1.15918,1.44451,1.44451,0,0,1,.44043-.67676,1.127,1.127,0,0,1,.73487-.23828,1.19487,1.19487,0,0,1,.57275.13183,1.09886,1.09886,0,0,1,.40723.37891,2.0604,2.0604,0,0,1,.25195.60352,4.03591,4.03591,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15234,1.44456,1.44456,0,0,1-.43848.67871,1.12849,1.12849,0,0,1-.73779.23926,1.11384,1.11384,0,0,1-.92188-.4209A2.67418,2.67418,0,0,1,106.64359,36.79874Zm.51221,0a2.54215,2.54215,0,0,0,.23389,1.33007.66861.66861,0,0,0,1.15429-.002,2.54583,2.54583,0,0,0,.23389-1.32812,2.546,2.546,0,0,0-.23389-1.332.694.694,0,0,0-.583-.32911.639.639,0,0,0-.54786.29A2.575,2.575,0,0,0,107.1558,36.79874Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A041'
              ? 'white-text'
              : elementOnHover === 'A41'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 41)}
          onMouseOver={() => setElementOnHover('A41')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.899,65.57364l1.5586-4.05859h.57861l1.66065,4.05859H102.085l-.47314-1.22949H99.91508l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.197,5.197,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M104.80473,65.57364V64.602H103.044v-.457l1.852-2.62988h.40723v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M108.521,65.57364h-.49853V62.39883a2.59009,2.59009,0,0,1-.47168.34278,3.39854,3.39854,0,0,1-.5249.25781V62.518a3.02722,3.02722,0,0,0,.731-.47656,1.873,1.873,0,0,0,.44288-.543h.32128Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A042'
              ? 'white-text'
              : elementOnHover === 'A42'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 42)}
          onMouseOver={() => setElementOnHover('A42')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,86.21915l1.55859-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H99.91459l-.4458,1.2295Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M104.80424,86.21915v-.97168H103.0435v-.457l1.85205-2.62989h.40722v2.62989h.54785v.457h-.54785v.97168Zm0-1.42871V82.96133l-1.27051,1.82911Z"
          />
          <path
            className="cls-7"
            d="M109.26225,85.74063v.47852h-2.68262a.88288.88288,0,0,1,.05859-.34571,1.81759,1.81759,0,0,1,.32764-.54,5.09154,5.09154,0,0,1,.65185-.61425,5.52584,5.52584,0,0,0,.89454-.86035,1.02736,1.02736,0,0,0,.23242-.59864.66745.66745,0,0,0-.21192-.5.763.763,0,0,0-.55224-.20312.776.776,0,0,0-.57569.21582.81544.81544,0,0,0-.21875.59765l-.5122-.05273a1.25987,1.25987,0,0,1,.396-.873,1.35113,1.35113,0,0,1,.92188-.30078,1.2867,1.2867,0,0,1,.92431.32422,1.063,1.063,0,0,1,.34082.80273,1.21516,1.21516,0,0,1-.0996.47852,1.85625,1.85625,0,0,1-.33106.49609,8.00205,8.00205,0,0,1-.76807.71387c-.29882.251-.49121.42188-.57568.51074a1.62681,1.62681,0,0,0-.21045.27051Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A043'
              ? 'white-text'
              : elementOnHover === 'A43'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 43)}
          onMouseOver={() => setElementOnHover('A43')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.898,108.4252l1.55859-4.05859h.57861l1.66065,4.05859H102.084l-.47314-1.22949H99.9141l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M104.80375,108.4252v-.97168H103.043v-.457l1.85205-2.62988h.40722v2.62988h.54786v.457h-.54786v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M106.646,107.35391l.49805-.0664a1.101,1.101,0,0,0,.292.61035.71991.71991,0,0,0,.50245.18652.806.806,0,0,0,.59375-.24316.82053.82053,0,0,0,.24218-.60352.76562.76562,0,0,0-.22412-.5664.775.775,0,0,0-.57031-.22266,1.42168,1.42168,0,0,0-.35156.05566l.05566-.4375a.71276.71276,0,0,0,.08008.00586,1.01992,1.01992,0,0,0,.57275-.167.5675.5675,0,0,0,.25489-.51172.607.607,0,0,0-.18555-.4541.65794.65794,0,0,0-.479-.17969.67829.67829,0,0,0-.48437.18262.92118.92118,0,0,0-.249.54785l-.498-.08789a1.28433,1.28433,0,0,1,.415-.77734,1.2002,1.2002,0,0,1,.80566-.27539,1.33246,1.33246,0,0,1,.61182.14257,1.03659,1.03659,0,0,1,.42773.38965.99677.99677,0,0,1,.14795.52246.85785.85785,0,0,1-.14111.4795.96679.96679,0,0,1-.418.34277.94606.94606,0,0,1,.55908.34473,1.05053,1.05053,0,0,1,.19922.65527,1.19762,1.19762,0,0,1-.38769.90039,1.36036,1.36036,0,0,1-.98.37012,1.27679,1.27679,0,0,1-.88721-.31836A1.23411,1.23411,0,0,1,106.646,107.35391Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A044'
              ? 'white-text'
              : elementOnHover === 'A44'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 44)}
          onMouseOver={() => setElementOnHover('A44')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,134.1752l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M104.80424,134.1752v-.97168H103.0435v-.457l1.85205-2.62988h.40722v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M108.24076,134.1752v-.97168H106.48v-.457l1.85205-2.62988h.40723v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A045'
              ? 'white-text'
              : elementOnHover === 'A45'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 45)}
          onMouseOver={() => setElementOnHover('A45')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.8018,125.974l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M59.70756,125.974v-.97168H57.94682v-.457l1.852-2.62988h.40722v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M61.54691,124.91055l.523-.04394a.93828.93828,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.7952.7952,0,0,0,.60889-.27051,1.0222,1.0222,0,0,0,.24951-.71973.92431.92431,0,0,0-.23974-.67285.83627.83627,0,0,0-.627-.24707.87559.87559,0,0,0-.43457.10938.85049.85049,0,0,0-.30468.28418l-.46778-.06055.39307-2.085h2.01807v.47656H62.42728l-.21875,1.09082a1.31954,1.31954,0,0,1,.76661-.25488,1.21223,1.21223,0,0,1,.897.36816,1.28485,1.28485,0,0,1,.36523.94629,1.47855,1.47855,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49317,1.31122,1.31122,0,0,1-.9038-.31055A1.20814,1.20814,0,0,1,61.54691,124.91055Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A046'
              ? 'white-text'
              : elementOnHover === 'A46'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 46)}
          onMouseOver={() => setElementOnHover('A46')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.8018,91.99893l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M59.70756,91.99893v-.97168H57.94682v-.457l1.852-2.62988h.40722v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M64.13236,88.93448l-.4956.03906a.94165.94165,0,0,0-.188-.42676.66117.66117,0,0,0-.49854-.21289.68307.68307,0,0,0-.418.13282,1.1378,1.1378,0,0,0-.3711.501,2.62529,2.62529,0,0,0-.14111.93848,1.15777,1.15777,0,0,1,.44043-.40723,1.19114,1.19114,0,0,1,.54541-.13281,1.12746,1.12746,0,0,1,.84814.36719,1.31612,1.31612,0,0,1,.3501.94824,1.5614,1.5614,0,0,1-.16455.71,1.1987,1.1987,0,0,1-.45264.502,1.23647,1.23647,0,0,1-.65332.17481,1.275,1.275,0,0,1-1.01562-.458,2.313,2.313,0,0,1-.39307-1.51074,2.70142,2.70142,0,0,1,.43457-1.71,1.24805,1.24805,0,0,1,1.02149-.46582,1.08688,1.08688,0,0,1,1.15136,1.01074Zm-2.03467,1.75a1.156,1.156,0,0,0,.10938.49219.8119.8119,0,0,0,.30566.35839.76648.76648,0,0,0,.4126.123.70468.70468,0,0,0,.54248-.2539,1.0027,1.0027,0,0,0,.227-.69239.93834.93834,0,0,0-.22412-.66308.7379.7379,0,0,0-.56445-.24219.76951.76951,0,0,0-.57324.24219A.8731.8731,0,0,0,62.09769,90.68448Z"
          />
        </g>
      </g>
      <g id="state_2_" className="st9">
        <path
          id="plate_12_"
          className="st10"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />
        <text
          id="_x33__3_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st10 st11 st12"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st10 st11 st12"
        >
          2021
        </text>
        <g className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="_x33__2_" className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="down_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g id="up_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 38 ? (
          <g id="A38">
            <linearGradient
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              style={{
                fill: 'url(#A11cone_00000116942025443687874220000014186524061492083896_)',
              }}
              d="
			M33.1855202,69.3973846c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 39 ? (
          <g id="A39">
            <linearGradient
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              style={{
                fill: 'url(#A11cone_00000049918791379197865380000014062642777780403637_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 40 ? (
          <g id="A40">
            <linearGradient
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              style={{
                fill: 'url(#A11cone_00000101824782659766024400000003894925743768571553_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 41 ? (
          <g id="A41">
            <linearGradient
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              gradientUnits="userSpaceOnUse"
              x1="211.7723846"
              y1="309.9545898"
              x2="211.7723846"
              y2="265.0445862"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000104685683903993578170000014400541892270177670_)',
              }}
              d="
			M123.146759,67.1088257c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.2567596,41.838829V85.62883L123.146759,67.1088257z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 42 ? (
          <g id="A42">
            <linearGradient
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              gradientUnits="userSpaceOnUse"
              x1="237.6501465"
              y1="309.7736206"
              x2="237.6501465"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              style={{
                fill: 'url(#A11cone_00000132062808605534171860000004161307431760505756_)',
              }}
              d="
			M122.9657669,92.9865952c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,67.7165985v43.7900009L122.9657669,92.9865952z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 43 ? (
          <g id="A43">
            <linearGradient
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              gradientUnits="userSpaceOnUse"
              x1="261.5904846"
              y1="309.7736206"
              x2="261.5904846"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              style={{
                fill: 'url(#A11cone_00000161621834216018166600000011104810949275289753_)',
              }}
              d="
			M122.9657669,116.926918c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,91.6569214v43.7899933L122.9657669,116.926918z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 44 ? (
          <g id="A44">
            <linearGradient
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              gradientUnits="userSpaceOnUse"
              x1="293.358429"
              y1="288.4524536"
              x2="293.358429"
              y2="243.5424652"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              style={{
                fill: 'url(#A11cone_00000046338767822234361200000006092721421211844490_)',
              }}
              d="
			M101.6446304,148.69487c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0399933,0.2100067-2.0299988,0.6000061-2.9199982L56.754631,123.4248734v43.7900009L101.6446304,148.69487z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 45 ? (
          <g id="A45">
            <linearGradient
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              gradientUnits="userSpaceOnUse"
              x1="273.7284241"
              y1="219.9933624"
              x2="273.7284241"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              style={{
                fill: 'url(#A11cone_00000119105400635965422190000001418125762866781067_)',
              }}
              d="
			M33.1855202,129.0648804c-0.3699951-0.8899994-0.5800018-1.8600082-0.5800018-2.890007
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900085L33.1855202,129.0648804z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 46 ? (
          <g id="A46">
            <linearGradient
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              gradientUnits="userSpaceOnUse"
              x1="242.9080505"
              y1="220.4395905"
              x2="242.9080505"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              style={{
                fill: 'url(#A11cone_00000030460207653560477860000005665363980855605161_)',
              }}
              d="
			M33.6317444,98.2444992c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900009L33.6317444,98.2444992z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_2_">
        <path
          id="plate_11_"
          className="st6"
          d="M172.1359558,110.5138321h-0.0000153c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802
		V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802h0.0000153
		c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_10_"
          className="st14"
          d="M178.3349304,90.8048477l-0.243988-38.549572
		c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688l0.243988,38.549572
		c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__00000120524356956290916440000014314947689778832002_">
          <path
            className="st15"
            d="M174.2402802,81.622467l-0.7431641,0.0581055c-0.0664063-0.293457-0.1611328-0.5063477-0.2822266-0.6391602
			c-0.2021484-0.2133789-0.4521484-0.3198242-0.7480469-0.3198242c-0.2382813,0-0.4472656,0.0664063-0.6269531,0.1992188
			c-0.2353516,0.171875-0.4208984,0.4223633-0.5566406,0.7514648c-0.1357422,0.3295898-0.2060547,0.7988281-0.2109375,1.4077148
			c0.1796875-0.2739258,0.3994141-0.4775391,0.6601563-0.6103516c0.2597656-0.1328125,0.5322266-0.1992188,0.8173828-0.1992188
			c0.4980469,0,0.9228516,0.1831055,1.2724609,0.550293c0.3505859,0.3666992,0.5253906,0.8408203,0.5253906,1.421875
			c0,0.3818359-0.0820313,0.7373047-0.2470703,1.0649414c-0.1640625,0.328125-0.390625,0.5795898-0.6787109,0.7539063
			s-0.6142578,0.2617188-0.9794922,0.2617188c-0.6230469,0-1.1308594-0.2290039-1.5244141-0.6875
			c-0.3925781-0.4580078-0.5898438-1.2128906-0.5898438-2.2651367c0-1.1762695,0.2177734-2.0317383,0.6523438-2.565918
			c0.3789063-0.465332,0.8896484-0.6977539,1.5322266-0.6977539c0.4785156,0,0.8710938,0.1342773,1.1767578,0.402832
			C173.9961395,80.7782288,174.1787567,81.1493225,174.2402802,81.622467z M171.1885223,84.2469788
			c0,0.2573242,0.0537109,0.5039063,0.1640625,0.7387695c0.1083984,0.2353516,0.2617188,0.4145508,0.4580078,0.5380859
			c0.1972656,0.1230469,0.4033203,0.1845703,0.6191406,0.1845703c0.3154297,0,0.5869141-0.1274414,0.8134766-0.3818359
			c0.2275391-0.2548828,0.3408203-0.6005859,0.3408203-1.0380859c0-0.4208984-0.1123047-0.7524414-0.3359375-0.9946289
			c-0.2246094-0.2421875-0.5068359-0.3632813-0.8476563-0.3632813c-0.3378906,0-0.6240234,0.1210938-0.859375,0.3632813
			S171.1885223,83.8539124,171.1885223,84.2469788z"
          />
        </g>
        <path
          id="up_9_"
          className="st16"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_9_"
          className="st16"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st6"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st6"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  38: [37.7855263, 66.627388],
  39: [62.2647095, 14.9237556],
  40: [103.8317642, 14.6850052],
  41: [127.7467651, 64.338829],
  42: [127.565773, 90.2165985],
  43: [127.565773, 114.1569214],
  44: [106.2446365, 145.924881],
  45: [37.7855263, 126.2948761],
  46: [38.2317505, 95.4745026],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    font-family: 'ArialMT';
  }
  .st8 {
    font-size: 5.6692901px;
  }
  .st9 {
    display: none;
  }
  .st10 {
    display: inline;
    fill: #3f3f3c;
  }
  .st11 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st12 {
    font-size: 8.5039396px;
  }
  .st13 {
    display: inline;
  }
  .st14 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #d3d2cf;
  }
  .st16 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: url(#A11cone_00000031908242821372644320000010099770893659807883_);
  }
  .st18 {
    fill: #c65805;
  }
  .st19 {
    fill: url(#A11cone_00000030460933582450340840000016738180085453283200_);
  }
  .st20 {
    fill: url(#A11cone_00000083803232135376585140000011405799044347850120_);
  }
  .st21 {
    fill: url(#A11cone_00000181045771646907618530000009514442668071759542_);
  }
  .st22 {
    fill: url(#A11cone_00000155131472214916524750000011241014034238265226_);
  }
  .st23 {
    fill: url(#A11cone_00000027604712313906409150000013128361352687773609_);
  }
  .st24 {
    fill: url(#A11cone_00000070101668780062261030000012829174151800702085_);
  }
  .st25 {
    fill: url(#A11cone_00000157289664823482042060000003122567549782656903_);
  }
  .st26 {
    fill: url(#A11cone_00000004528441680822221230000012008214006472699031_);
  }

  #A11,
  #A12,
  #A13,
  #A14,
  #A15,
  #B39,
  #B40,
  #B41,
  #C65,
  #C66,
  #C67,
  #C68,
  #C69,
  #C70 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-7 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
