import * as React from 'react'
import styled from 'styled-components'

import NavigationPanel from 'components/NavigationPanel'

const TourWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
`

export default function Krpano({ apartmentsCMS, floorsStatus }) {
  const [krpano, setKrpano] = React.useState(null)
  const [hLookAt, setHLookAt] = React.useState(0)

  const krpano_onready_callback = React.useCallback(krpano_interface => {
    setKrpano(krpano_interface)
  }, [])

  React.useEffect(() => {
    const initKrpano = () => {
      window.embedpano({
        swf: 'vtour/tour.swf', // path to flash viewer (use null if no flash fallback will be required)
        id: 'krpanoSWFObject',
        xml: 'vtour/tour.xml',
        target: 'tour',
        bgcolor: '#FFFFFF',
        consolelog: false, // trace krpano messages also to the browser console
        passQueryParameters: true, // pass query parameters of the url to krpano
        onready: krpano_onready_callback,
      })
    }
    initKrpano()
  }, [krpano_onready_callback])

  React.useEffect(() => {
    const getHLookAt = () => {
      window.getHLookAt = krpano => {
        if (krpano) {
          setHLookAt(krpano.get('view.hlookat'))
        }
      }
    }
    getHLookAt()
  })

  return (
    <>
      <TourWrapper id="tour" />
      <NavigationPanel
        krpano={krpano}
        hLookAt={hLookAt + 97}
        apartmentsCMS={apartmentsCMS}
        floorsStatus={floorsStatus}
      />
    </>
  )
}
