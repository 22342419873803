export const FLOOR_8_DATA = [
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 56,
    sceneName: 'scene_A056',
    sceneName_future: 'scene_A056_future',
    name: 'A56',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      7: 47,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 57,
    sceneName: 'scene_A057',
    sceneName_future: 'scene_A057_future',
    name: 'A57',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      7: 48,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: `${58}_north`,
    sceneName: 'scene_A058_north',
    sceneName_future: 'scene_A058_north_future',
    name: 'A58_north',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      7: 49,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: `${58}_east`,
    sceneName: 'scene_A058_east',
    sceneName_future: 'scene_A058_east_future',
    name: 'A58_east',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      7: 49,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 59,
    sceneName: 'scene_A059',
    sceneName_future: 'scene_A059_future',
    name: 'A59',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      7: 51,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 60,
    sceneName: 'scene_A060',
    sceneName_future: 'scene_A060_future',
    name: 'A60',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      7: 54,
    },
  },
]
