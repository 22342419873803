import * as React from 'react'
import styled from 'styled-components'

export default function Floor1({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_3_">
        <g id="static_3_">
          <rect
            id="plate_13_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(1, 1)}
          onMouseOver={() => setElementOnHover('A01')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A01"
            className={`st1 ${
              sceneName === 'scene_A001'
                ? 'active-apt'
                : elementOnHover === 'A01'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061 70.8242569,74.2875061 70.8242569,90.5394669 42.8933792,90.787117
		42.750164,89.0350266 33.9492569,89.0350266 33.9492569,74.2875061 	"
          />
        </g>

        <g
          onClick={() => changeView(1, 2)}
          onMouseOver={() => setElementOnHover('A02')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A02"
            className={`st1 ${
              sceneName === 'scene_A002'
                ? 'active-apt'
                : elementOnHover === 'A02'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301
		42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>

        <g
          onClick={() => changeView(1, 3)}
          onMouseOver={() => setElementOnHover('A03')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A03"
            className={`st1 ${
              sceneName === 'scene_A003'
                ? 'active-apt'
                : elementOnHover === 'A03'
                ? 'on-hover'
                : ''
            }`}
            points="41.8242569,42.5557785
		42.2604675,38.8682785 77.8867569,38.8682785 77.8867569,56.4255905 42.1680069,56.1079025 42.2604675,55.430275 	"
          />
        </g>

        <g
          onClick={() => changeView(1, 4)}
          onMouseOver={() => setElementOnHover('A04')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A04"
            className={`st1 ${
              sceneName === 'scene_A004'
                ? 'active-apt'
                : elementOnHover === 'A04'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242493,53.9484215 85.0117493,53.9484215 85.0117569,10.5916872
		122.8242493,10.6093349 	"
          />
        </g>

        <g
          onClick={() => changeView(1, 61)}
          onMouseOver={() => setElementOnHover('A61')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A61"
            className={`st1 ${
              sceneName === 'scene_A061'
                ? 'active-apt'
                : elementOnHover === 'A61'
                ? 'on-hover'
                : ''
            }`}
            points="41.9791946,10.6093349 42.2604675,38.8682785 77.8867569,38.8682785 77.8867569,31.0122547
		85.0117569,31.1997509 85.0117569,10.5916872 	"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,141.4750061 42.8242569,141.4750061 	"
        />
        <line
          className="st2"
          x1="122.8242569"
          y1="9.9264984"
          x2="122.8242569"
          y2="11.2921705"
        />
        <line
          className="st2"
          x1="41.9791946"
          y1="11.3257523"
          x2="41.9791946"
          y2="9.8929167"
        />
        <line
          className="st3"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st3"
          x1="77.8867569"
          y1="56.4125061"
          x2="42.2604675"
          y2="56.4125061"
        />
        <polyline
          className="st3"
          points="42.5387955,90.3806229 77.8867569,90.3806229 77.8867569,31.0375061 85.0117569,31.0375061 	"
        />
        <polyline
          className="st3"
          points="85.0117569,9.8929167 85.0117569,45.7250023 85.0117569,113.8500061 103.4805069,113.8500061
		103.4805069,123.2562561 103.5500183,141.3742523 	"
        />
        <line
          className="st4"
          x1="85.0117569"
          y1="72.1625061"
          x2="122.8242569"
          y2="72.1625061"
        />
        <line
          className="st4"
          x1="85.0117569"
          y1="54.6312561"
          x2="122.8242569"
          y2="54.6312561"
        />
        <line
          className="st4"
          x1="85.0117569"
          y1="96.6420212"
          x2="122.2897873"
          y2="96.3031311"
        />
        <polyline
          className="st3"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 	"
        />
        <line
          className="st3"
          x1="70.8242569"
          y1="73.9698181"
          x2="70.8242569"
          y2="90.3806229"
        />
        <line
          className="st3"
          x1="41.9791946"
          y1="38.7999191"
          x2="77.8867569"
          y2="38.7999191"
        />
        <line
          className="st2"
          x1="65.9805069"
          y1="10.6093349"
          x2="68.3242569"
          y2="10.6093349"
        />
        <line
          className="st2"
          x1="102.7461319"
          y1="10.5916872"
          x2="105.0898819"
          y2="10.5916872"
        />
        <polyline
          className="st5"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <line
          className="st3"
          x1="37.3867569"
          y1="89.2875061"
          x2="42.2604675"
          y2="89.2875061"
        />
        <polyline
          className="st5"
          points="42.2604675,89.2875061 33.9492569,89.2875061 33.9492569,74.2877579 41.8242569,74.2877579 	"
        />
        <polyline
          className="st5"
          points="42.0164375,19.8493614 42.0164375,10.609334 122.8242493,10.6093349 122.8242493,19.4131508 	"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A001'
              ? 'white-text'
              : elementOnHover === 'A01'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 1)}
          onMouseOver={() => setElementOnHover('A01')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11332,84.62393l1.55859-4.05859h.57862l1.66064,4.05859h-.61181l-.47315-1.22949H55.12943l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.51269-.2876-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.42191,82.623a3.721,3.721,0,0,1,.148-1.15918,1.44451,1.44451,0,0,1,.44043-.67676,1.12693,1.12693,0,0,1,.73487-.23828,1.19487,1.19487,0,0,1,.57275.13183,1.09886,1.09886,0,0,1,.40723.37891,2.06034,2.06034,0,0,1,.25195.60351,4.03606,4.03606,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15234,1.44461,1.44461,0,0,1-.43848.67871,1.12849,1.12849,0,0,1-.73779.23926,1.11384,1.11384,0,0,1-.92188-.4209A2.6742,2.6742,0,0,1,58.42191,82.623Zm.51221,0A2.54215,2.54215,0,0,0,59.168,83.953a.66861.66861,0,0,0,1.15429-.00195,3.89887,3.89887,0,0,0,0-2.66016.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54786.29A2.575,2.575,0,0,0,58.93412,82.623Z"
          />
          <path
            className="cls-7"
            d="M63.73539,84.62393h-.49853v-3.1748a2.59044,2.59044,0,0,1-.47168.34277,3.39768,3.39768,0,0,1-.52491.25781v-.48144a3.02807,3.02807,0,0,0,.731-.47656,1.87293,1.87293,0,0,0,.44287-.543h.32129Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A002'
              ? 'white-text'
              : elementOnHover === 'A02'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 2)}
          onMouseOver={() => setElementOnHover('A02')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11381,66.41251l1.55859-4.0586H56.251l1.66064,4.0586h-.61182L56.8267,65.183H55.12992l-.4458,1.2295Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.5127-.2876-.8418a5.1985,5.1985,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.4224,64.41153a3.72109,3.72109,0,0,1,.14795-1.15918,1.44449,1.44449,0,0,1,.44043-.67676,1.127,1.127,0,0,1,.73486-.23828,1.19489,1.19489,0,0,1,.57276.13184,1.09873,1.09873,0,0,1,.40722.3789,2.06014,2.06014,0,0,1,.252.60352,4.03649,4.03649,0,0,1,.09131.96,3.70859,3.70859,0,0,1-.147,1.15234,1.44447,1.44447,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23926,1.11379,1.11379,0,0,1-.92187-.4209A2.67418,2.67418,0,0,1,58.4224,64.41153Zm.51221,0a2.54219,2.54219,0,0,0,.23389,1.33008.66861.66861,0,0,0,1.15429-.002,3.89884,3.89884,0,0,0,0-2.66015.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,58.93461,64.41153Z"
          />
          <path
            className="cls-7"
            d="M64.47758,65.934v.47852H61.795a.88285.88285,0,0,1,.05859-.34571,1.8178,1.8178,0,0,1,.32764-.54,5.09172,5.09172,0,0,1,.65186-.61425,5.52562,5.52562,0,0,0,.89453-.86036,1.02731,1.02731,0,0,0,.23242-.59863.66748.66748,0,0,0-.21191-.5.763.763,0,0,0-.55225-.20312.776.776,0,0,0-.57568.21582.81536.81536,0,0,0-.21875.59765l-.51221-.05273a1.25991,1.25991,0,0,1,.396-.873,1.35113,1.35113,0,0,1,.92188-.30078,1.28672,1.28672,0,0,1,.92432.32422,1.063,1.063,0,0,1,.34082.80273,1.21517,1.21517,0,0,1-.09961.47852,1.85625,1.85625,0,0,1-.33106.49609,8.00166,8.00166,0,0,1-.76806.71387c-.29883.251-.49121.42188-.57569.51074a1.62681,1.62681,0,0,0-.21045.27051Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A003'
              ? 'white-text'
              : elementOnHover === 'A03'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 3)}
          onMouseOver={() => setElementOnHover('A03')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.08646,49.391l1.5586-4.05859h.57861L57.88432,49.391H57.2725l-.47314-1.22949H55.10258l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.2876-.84179a5.19764,5.19764,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.39506,47.39005a3.72075,3.72075,0,0,1,.148-1.15918,1.44436,1.44436,0,0,1,.44043-.67676,1.12689,1.12689,0,0,1,.73486-.23828,1.19487,1.19487,0,0,1,.57275.13183,1.0987,1.0987,0,0,1,.40723.37891,2.06007,2.06007,0,0,1,.252.60351,4.03606,4.03606,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15234,1.44454,1.44454,0,0,1-.43848.67871,1.12845,1.12845,0,0,1-.73779.23926,1.11382,1.11382,0,0,1-.92187-.4209A2.6742,2.6742,0,0,1,58.39506,47.39005Zm.51221,0a2.54217,2.54217,0,0,0,.23388,1.33007.66862.66862,0,0,0,1.1543-.002,2.54593,2.54593,0,0,0,.23389-1.32812,2.54616,2.54616,0,0,0-.23389-1.332.69407.69407,0,0,0-.583-.3291.639.639,0,0,0-.54785.29A2.57492,2.57492,0,0,0,58.90727,47.39005Z"
          />
          <path
            className="cls-7"
            d="M61.83451,48.31973l.498-.0664a1.10088,1.10088,0,0,0,.292.61035.71991.71991,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57031-.22266,1.42182,1.42182,0,0,0-.35157.05566l.05567-.4375a.71276.71276,0,0,0,.08008.00586,1.01992,1.01992,0,0,0,.57275-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18554-.4541.658.658,0,0,0-.479-.17969.67828.67828,0,0,0-.48437.18262.92118.92118,0,0,0-.249.54785l-.498-.08789a1.28422,1.28422,0,0,1,.415-.77734,1.2002,1.2002,0,0,1,.80566-.27539,1.33249,1.33249,0,0,1,.61182.14257,1.03659,1.03659,0,0,1,.42773.38965.99686.99686,0,0,1,.14795.52246.85791.85791,0,0,1-.14111.4795.96679.96679,0,0,1-.418.34277.94609.94609,0,0,1,.55908.34473,1.05059,1.05059,0,0,1,.19922.65527,1.19762,1.19762,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.27678,1.27678,0,0,1-.8872-.31836A1.234,1.234,0,0,1,61.83451,48.31973Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A004'
              ? 'white-text'
              : elementOnHover === 'A04'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 4)}
          onMouseOver={() => setElementOnHover('A04')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20707,36.79874a3.72109,3.72109,0,0,1,.14795-1.15918,1.44436,1.44436,0,0,1,.44043-.67676,1.12694,1.12694,0,0,1,.73486-.23828,1.19492,1.19492,0,0,1,.57276.13183,1.09875,1.09875,0,0,1,.40722.37891,2.06014,2.06014,0,0,1,.252.60352,4.03651,4.03651,0,0,1,.0913.96,3.70867,3.70867,0,0,1-.147,1.15234,1.44447,1.44447,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23926,1.11382,1.11382,0,0,1-.92187-.4209A2.67418,2.67418,0,0,1,103.20707,36.79874Zm.51221,0a2.54217,2.54217,0,0,0,.23388,1.33007.66862.66862,0,0,0,1.1543-.002,2.54593,2.54593,0,0,0,.23389-1.32812,2.54609,2.54609,0,0,0-.23389-1.332.694.694,0,0,0-.583-.32911.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,103.71928,36.79874Z"
          />
          <path
            className="cls-7"
            d="M108.24076,38.79971V37.828H106.48v-.457l1.85205-2.62988h.40723V37.371h.54785v.457h-.54785v.97168Zm0-1.42871V35.5419l-1.27051,1.8291Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A061'
              ? 'white-text'
              : elementOnHover === 'A61'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 61)}
          onMouseOver={() => setElementOnHover('A61')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.09525,31.03751l1.5586-4.0586h.57861l1.66065,4.0586h-.61182l-.47315-1.2295H55.11137l-.4458,1.2295Zm1.1709-1.666h1.376l-.42383-1.124q-.19336-.5127-.2876-.8418a5.1985,5.1985,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M60.9893,27.97305l-.49561.03907a.94138.94138,0,0,0-.188-.42676.66115.66115,0,0,0-.49853-.21289.68312.68312,0,0,0-.418.13281,1.13782,1.13782,0,0,0-.37109.501,2.62516,2.62516,0,0,0-.14112.93847,1.15774,1.15774,0,0,1,.44043-.40722,1.19115,1.19115,0,0,1,.54541-.13282,1.12748,1.12748,0,0,1,.84815.36719,1.31617,1.31617,0,0,1,.35009.94824,1.56128,1.56128,0,0,1-.16455.71,1.19877,1.19877,0,0,1-.45263.502,1.23649,1.23649,0,0,1-.65332.1748,1.27494,1.27494,0,0,1-1.01563-.458,2.313,2.313,0,0,1-.39307-1.51074,2.70148,2.70148,0,0,1,.43457-1.71,1.24808,1.24808,0,0,1,1.02149-.46582,1.0869,1.0869,0,0,1,1.15137,1.01074Zm-2.03467,1.75a1.156,1.156,0,0,0,.10937.49219.812.812,0,0,0,.30567.3584.76654.76654,0,0,0,.4126.123.70471.70471,0,0,0,.54248-.25391,1.00271,1.00271,0,0,0,.227-.69238.93839.93839,0,0,0-.22412-.66309.738.738,0,0,0-.56446-.24219.76957.76957,0,0,0-.57324.24219A.87311.87311,0,0,0,58.95463,29.72305Z"
          />
          <path
            className="cls-7"
            d="M63.71732,31.03751h-.49853V27.8627a2.59,2.59,0,0,1-.47168.34277,3.39912,3.39912,0,0,1-.5249.25782v-.48145a3.02752,3.02752,0,0,0,.731-.47656,1.8728,1.8728,0,0,0,.44287-.543h.32129Z"
          />
        </g>
      </g>
      <g id="state_2_" className="st9">
        <path
          id="plate_12_"
          className="st10"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />
        <text
          id="_x33__3_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st10 st11 st12"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st10 st11 st12"
        >
          2021
        </text>
        <g className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="_x33__2_" className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="down_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g id="up_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 1 ? (
          <g id="A01_00000161608555848386179600000009878408196148479617_">
            <linearGradient
              id="A11cone_00000120538181151021528980000008923166356143173513_"
              gradientUnits="userSpaceOnUse"
              x1="229.038559"
              y1="220.4395905"
              x2="229.038559"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000046301205331163834020000002076902043362108855_"
              style={{
                fill: 'url(#A11cone_00000120538181151021528980000008923166356143173513_)',
              }}
              d="
			M33.6317444,84.375c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400085,0.2100067-2.0300064,0.6000061-2.9199982l-44.9100037-19.4600029v43.7900047L33.6317444,84.375z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 2 ? (
          <g id="A02_00000159443147967942037900000017152081009243815868_">
            <linearGradient
              id="A11cone_00000177462432894350950800000002343577989722957728_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone"
              style={{
                fill: 'url(#A11cone_00000177462432894350950800000002343577989722957728_)',
              }}
              d="M33.1855202,69.3973846
			c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982
			L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 3 ? (
          <g id="A03">
            <linearGradient
              id="A11cone_00000177462145272256552340000008245868532437860027_"
              gradientUnits="userSpaceOnUse"
              x1="194.9481354"
              y1="230.4420929"
              x2="194.9481354"
              y2="185.5320892"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000074412922326238041190000007361132677057823653_"
              style={{
                fill: 'url(#A11cone_00000177462145272256552340000008245868532437860027_)',
              }}
              d="
			M43.6342545,50.284584c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-1.2557445,25.0145893v43.7900009L43.6342545,50.284584z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 4 ? (
          <g id="A04_00000181769660711712626670000002301819996166066853_">
            <linearGradient
              id="A11cone_00000120559440697201516890000007693653268239161477_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000016055994746572630200000005119066722710925238_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000120559440697201516890000007693653268239161477_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 61 ? (
          <g id="A61_00000035498655630565547720000003113169033805950858_">
            <linearGradient
              id="A11cone_00000020388543780406243410000004963003870111338887_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000155862107164258549950000007174105641519592081_"
              style={{
                fill: 'url(#A11cone_00000020388543780406243410000004963003870111338887_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_2_">
        <path
          id="plate_11_"
          className="st6"
          d="M172.1359558,110.5138321h-0.0000153c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802
		V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802h0.0000153
		c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_10_"
          className="st14"
          d="M178.3349304,90.8048477l-0.243988-38.549572
		c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688l0.243988,38.549572
		c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__1_">
          <path
            className="st15"
            d="M173.1768036,86.2191467h-0.7470703v-4.7626953
			c-0.1796875,0.171875-0.4160156,0.3432617-0.7080078,0.5146484c-0.2919922,0.171875-0.5546875,0.3007813-0.7871094,0.3862305
			v-0.722168c0.4179688-0.1967773,0.7832031-0.4345703,1.0966797-0.7143555
			c0.3125-0.2797852,0.5341797-0.5507813,0.6640625-0.8139648h0.4814453V86.2191467z"
          />
        </g>
        <path
          id="up_9_"
          className="st16"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_9_"
          className="st16"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down">
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st6"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st6"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  1: [38.2317505, 81.6049957],
  2: [37.7855263, 66.627388],
  3: [48.2342606, 47.5145874],
  4: [103.8317642, 14.6850052],
  61: [62.2647095, 14.9237556],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    font-family: 'ArialMT';
  }
  .st8 {
    font-size: 5.6692901px;
  }
  .st9 {
    display: none;
  }
  .st10 {
    display: inline;
    fill: #3f3f3c;
  }
  .st11 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st12 {
    font-size: 8.5039396px;
  }
  .st13 {
    display: inline;
  }
  .st14 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #d3d2cf;
  }
  .st16 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: url(#A11cone_00000165950121356661274900000005955419098121609639_);
  }
  .st18 {
    fill: #c65805;
  }
  .st19 {
    fill: url(#A11cone_00000118395533367907066920000005533633577887091881_);
  }
  .st20 {
    fill: url(#A11cone_00000169547363529857742840000005967155240734721179_);
  }
  .st21 {
    fill: url(#A11cone_00000167356716143013184690000014548145173387530431_);
  }
  .st22 {
    fill: url(#A11cone_00000128487310457797679110000012516210301160714653_);
  }

  #A01,
  #A02,
  #A03,
  #A04,
  #A61 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #up:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .disable-lift-btn,
  #down:hover {
    cursor: not-allowed;

    .st0 {
      fill: var(--yit-grey);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-7 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
