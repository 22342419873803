import * as React from 'react'
import styled from 'styled-components'

export default function Floor7({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="5"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_00000083790275358450881470000005034345430719224718_">
        <g id="static_00000114044698350951475360000007648033134264605852_">
          <rect
            id="plate_00000160905630175029346080000005447948830385383590_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(7, 47)}
          onMouseOver={() => setElementOnHover('A47')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A47"
            className={`st1 ${
              sceneName === 'scene_A047'
                ? 'active-apt'
                : elementOnHover === 'A47'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061
		77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301 42.2604675,72.9745026 33.9492569,72.9745026
		33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>

        <g
          onClick={() => changeView(7, 48)}
          onMouseOver={() => setElementOnHover('A48')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A48"
            className={`st1 ${
              sceneName === 'scene_A048'
                ? 'active-apt'
                : elementOnHover === 'A48'
                ? 'on-hover'
                : ''
            }`}
            points="41.2536697,10.6093349
		42.2604675,55.7983246 77.8867569,55.7983246 77.8867569,45.5375061 85.0117569,45.7250023 85.0117569,10.5916872 	"
          />
        </g>

        <g
          onClick={() => changeView(7, 49)}
          onMouseOver={() => setElementOnHover('A49')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A49"
            className={`st1 ${
              sceneName === 'scene_A049'
                ? 'active-apt'
                : elementOnHover === 'A49'
                ? 'on-hover'
                : ''
            }`}
            x="85.0117569"
            y="10.5916872"
            width="38.3938446"
            height="44.0395699"
          />
        </g>

        <g
          onClick={() => changeView(7, 50)}
          onMouseOver={() => setElementOnHover('A50')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A50"
            className={`st1 ${
              sceneName === 'scene_A050'
                ? 'active-apt'
                : elementOnHover === 'A50'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,72.1625061
		85.0117569,72.1625061 85.0117569,54.6312561 122.8242569,54.6312561 122.8242569,57.7250099 132.0742645,57.7250061
		132.0742645,70.6000061 122.8242569,70.6000061 	"
          />
        </g>

        <g
          onClick={() => changeView(7, 51)}
          onMouseOver={() => setElementOnHover('A51')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A51"
            className={`st1 ${
              sceneName === 'scene_A051'
                ? 'active-apt'
                : elementOnHover === 'A51'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,96.6420212
		85.0117569,96.6420212 85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 123.1149292,72.4125061
		122.8242569,83.7250061 132.0742645,83.7250061 132.0742645,96.3031311 122.8242569,96.3031311 	"
          />
        </g>
        <g
          onClick={() => changeView(7, 52)}
          onMouseOver={() => setElementOnHover('A52')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A52"
            className={`st1 ${
              sceneName === 'scene_A052'
                ? 'active-apt'
                : elementOnHover === 'A52'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,123.2562561
		103.4805069,123.2562561 103.4805069,113.8500061 85.0117569,114.1732712 85.0117569,96.6420212 122.8242569,96.6420212
		122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.3500061 122.8242569,120.3500061 	"
          />
        </g>
        <g
          onClick={() => changeView(7, 53)}
          onMouseOver={() => setElementOnHover('A53')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A53"
            className={`st1 ${
              sceneName === 'scene_A053'
                ? 'active-apt'
                : elementOnHover === 'A53'
                ? 'on-hover'
                : ''
            }`}
            points="123.3919067,149.6000061
		89.5169067,149.6000061 89.5169067,141.4750061 85.0117569,141.2381592 85.0117569,113.8500061 103.4805069,113.8500061
		103.6507797,123.7069016 122.8242569,123.7069016 	"
          />
        </g>
        <g
          onClick={() => changeView(7, 54)}
          onMouseOver={() => setElementOnHover('A54')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A54"
            className={`st1 ${
              sceneName === 'scene_A054'
                ? 'active-apt'
                : elementOnHover === 'A54'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,106.7914276
		77.4492569,106.7914276 77.4492569,141.4750061 43.0117569,141.7875061 42.750164,132.4725342 33.9492569,132.4725342
		33.9492569,119.5975266 42.750164,119.4750061 	"
          />
        </g>
        <g
          onClick={() => changeView(7, 55)}
          onMouseOver={() => setElementOnHover('A55')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A55"
            className={`st1 ${
              sceneName === 'scene_A055'
                ? 'active-apt'
                : elementOnHover === 'A55'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061
		70.8242493,74.2875061 70.8242569,90.3806229 77.4492569,90.3806229 77.4492569,106.7914276 42.8933792,107.0390778
		42.750164,101.9393234 33.9492569,101.9393234 33.9492569,89.0643234 42.750164,88.9417953 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000057126096226135516490000004864273927485384099_">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,141.4750061 42.8242569,141.4750061 	"
        />
        <polyline
          className="st3"
          points="41.2536697,19.9926529 41.2536697,10.6093349 123.4056015,10.6093349 123.4056015,19.4634018 	"
        />
        <polyline
          className="st3"
          points="122.8242569,57.7250061 132.0742645,57.7250061 132.0742645,70.6000061 122.8242569,70.6000061 	"
        />
        <polyline
          className="st3"
          points="122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.2250061 122.8242569,120.2250061
		"
        />
        <polyline
          className="st3"
          points="123.3919067,141.4750061 123.3919067,149.6000061 89.5169067,149.6000061 89.5169067,141.4750061
		"
        />
        <polyline
          className="st3"
          points="42.750164,132.4725342 33.9492569,132.4725342 33.9492569,119.4750061 42.750164,119.4750061 	"
        />
        <polyline
          className="st3"
          points="42.4986992,101.9198456 33.9492569,101.9198456 33.9492569,89.0065994 42.750164,89.0065994 	"
        />
        <polyline
          className="st3"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,10.5916872 85.0117569,45.7250023 85.0117569,141.4750061 	"
        />
        <polyline
          className="st4"
          points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,45.5375061 85.0117569,45.5375061
		77.8867569,45.5375061 77.8867569,141.4750061 	"
        />
        <line
          className="st4"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st4"
          x1="42.5387955"
          y1="106.7914276"
          x2="77.8867569"
          y2="106.7914276"
        />
        <polyline
          className="st4"
          points="85.0117569,113.8500061 103.4805069,113.8500061 103.4805069,123.2562561 122.8242569,123.2562561
		"
        />
        <polyline
          className="st3"
          points="122.8242569,96.4750061 132.0742645,96.4750061 132.0742645,83.7250061 122.8242569,83.7250061 	"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="72.1625061"
          x2="122.8242569"
          y2="72.1625061"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="54.6312561"
          x2="122.8242569"
          y2="54.6312561"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="96.6420212"
          x2="122.2897873"
          y2="96.3031311"
        />
        <polyline
          className="st4"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 	"
        />
        <polyline
          className="st4"
          points="70.8242569,73.9698181 70.8242569,90.3806229 77.8867569,90.3806229 	"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A047'
              ? 'white-text'
              : elementOnHover === 'A47'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 47)}
          onMouseOver={() => setElementOnHover('A47')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.80228,66.53751l1.5586-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H54.8184l-.4458,1.2295Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.1973,5.1973,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M59.70805,66.53751v-.97168H57.9473v-.457l1.85206-2.62989h.40722V65.1088h.54785v.457h-.54785v.97168Zm0-1.42871V63.27969L58.43754,65.1088Z"
          />
          <path
            className="cls-7"
            d="M61.58061,63.01114v-.47949h2.627v.38769a5.53535,5.53535,0,0,0-.76807,1.09668,6.65,6.65,0,0,0-.58838,1.40625,5.2271,5.2271,0,0,0-.19092,1.11524H62.148a4.95819,4.95819,0,0,1,.18847-1.15723,6.06246,6.06246,0,0,1,.51612-1.30762,5.59147,5.59147,0,0,1,.71533-1.06152Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A048'
              ? 'white-text'
              : elementOnHover === 'A48'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 48)}
          onMouseOver={() => setElementOnHover('A48')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.80228,38.79971l1.5586-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H54.8184l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.2876-.84179a5.19764,5.19764,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M59.70805,38.79971V37.828H57.9473v-.457l1.85206-2.62988h.40722V37.371h.54785v.457h-.54785v.97168Zm0-1.42871V35.5419L58.43754,37.371Z"
          />
          <path
            className="cls-7"
            d="M62.314,36.59854a.93825.93825,0,0,1-.45947-.32324.84911.84911,0,0,1-.14941-.50391.98617.98617,0,0,1,.31836-.74511,1.34,1.34,0,0,1,1.70263.00683,1.00108,1.00108,0,0,1,.32373.752.826.826,0,0,1-.148.49122.9315.9315,0,0,1-.45019.32226,1.095,1.095,0,0,1,.56885.39356,1.07662,1.07662,0,0,1,.19531.64746,1.16489,1.16489,0,0,1-.36817.875,1.49936,1.49936,0,0,1-1.9375-.001,1.18362,1.18362,0,0,1-.36816-.88769,1.07143,1.07143,0,0,1,.20068-.66309A1.01119,1.01119,0,0,1,62.314,36.59854Zm-.26025,1.03027a.90116.90116,0,0,0,.10107.41211.7102.7102,0,0,0,.3003.3086.8756.8756,0,0,0,.4292.10937.80385.80385,0,0,0,.58984-.22949.787.787,0,0,0,.23242-.584.7984.7984,0,0,0-.23926-.59473.82017.82017,0,0,0-.59961-.23535.79282.79282,0,0,0-.814.81347Zm.16064-1.874a.63383.63383,0,0,0,.18555.4707.65551.65551,0,0,0,.48145.18262.64309.64309,0,0,0,.47216-.18164.59987.59987,0,0,0,.18409-.44433.62211.62211,0,0,0-.18946-.46094.69748.69748,0,0,0-.94531-.00391A.58886.58886,0,0,0,62.21439,35.75479Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A049'
              ? 'white-text'
              : elementOnHover === 'A49'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 49)}
          onMouseOver={() => setElementOnHover('A49')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M104.80424,38.79971V37.828H103.0435v-.457l1.85205-2.62988h.40722V37.371h.54785v.457h-.54785v.97168Zm0-1.42871V35.5419l-1.27051,1.8291Z"
          />
          <path
            className="cls-7"
            d="M106.7183,37.86124l.479-.044a.82916.82916,0,0,0,.23243.48926.638.638,0,0,0,.43994.15234.76633.76633,0,0,0,.40283-.10449.86265.86265,0,0,0,.28369-.28125,1.65346,1.65346,0,0,0,.18555-.47461,2.50322,2.50322,0,0,0,.0747-.60937c0-.02246-.001-.05567-.00244-.09961a1.14458,1.14458,0,0,1-.40869.38672,1.10949,1.10949,0,0,1-.56054.14746,1.13452,1.13452,0,0,1-.85254-.36524,1.336,1.336,0,0,1-.34864-.96289,1.369,1.369,0,0,1,.36377-.99414,1.21681,1.21681,0,0,1,.91211-.377,1.29827,1.29827,0,0,1,.72412.21387,1.32778,1.32778,0,0,1,.498.60742,2.98482,2.98482,0,0,1,.17041,1.1416,3.69478,3.69478,0,0,1-.16894,1.23926,1.44692,1.44692,0,0,1-.50245.70117,1.29593,1.29593,0,0,1-.78173.24121,1.07273,1.07273,0,0,1-1.14063-1.00781Zm2.04-1.791a.97634.97634,0,0,0-.228-.68066.7162.7162,0,0,0-.5498-.252.75713.75713,0,0,0-.57862.27149,1.00551,1.00551,0,0,0-.24609.70312.86558.86558,0,0,0,.23389.62891.76767.76767,0,0,0,.57715.24219.73667.73667,0,0,0,.56884-.24219A.95283.95283,0,0,0,108.75834,36.07022Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A050'
              ? 'white-text'
              : elementOnHover === 'A50'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 50)}
          onMouseOver={() => setElementOnHover('A50')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.899,65.57364l1.5586-4.05859h.57861l1.66065,4.05859H102.085l-.47314-1.22949H99.91508l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.197,5.197,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20756,64.51016l.52295-.04394a.93817.93817,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.79525.79525,0,0,0,.60889-.27051,1.02225,1.02225,0,0,0,.24951-.71973.92426.92426,0,0,0-.23975-.67285.83625.83625,0,0,0-.62695-.24707.87554.87554,0,0,0-.43457.10938.85052.85052,0,0,0-.30469.28418l-.46777-.06055.39306-2.085h2.01807v.47656h-1.61914l-.21875,1.09082a1.31949,1.31949,0,0,1,.7666-.25488,1.21223,1.21223,0,0,1,.897.36816,1.28482,1.28482,0,0,1,.36524.94629,1.4786,1.4786,0,0,1-.3208.95215,1.28544,1.28544,0,0,1-1.06592.49316,1.31126,1.31126,0,0,1-.90381-.31054A1.20809,1.20809,0,0,1,103.20756,64.51016Z"
          />
          <path
            className="cls-7"
            d="M106.64408,63.57266a3.72109,3.72109,0,0,1,.14795-1.15918,1.44449,1.44449,0,0,1,.44043-.67676,1.127,1.127,0,0,1,.73486-.23828,1.19489,1.19489,0,0,1,.57276.13184,1.09875,1.09875,0,0,1,.40722.37891,2.06,2.06,0,0,1,.252.60351,4.03655,4.03655,0,0,1,.09131.96,3.7087,3.7087,0,0,1-.147,1.15235,1.44457,1.44457,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23925,1.11381,1.11381,0,0,1-.92187-.42089A2.67422,2.67422,0,0,1,106.64408,63.57266Zm.51221,0a2.54216,2.54216,0,0,0,.23389,1.33008.66861.66861,0,0,0,1.15429-.00195,3.89887,3.89887,0,0,0,0-2.66016.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,107.15629,63.57266Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A051'
              ? 'white-text'
              : elementOnHover === 'A51'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 51)}
          onMouseOver={() => setElementOnHover('A51')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,86.21915l1.55859-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H99.91459l-.4458,1.2295Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20707,85.15567l.52295-.04395a.9382.9382,0,0,0,.27.57422.73.73,0,0,0,.51074.19239.79525.79525,0,0,0,.60889-.27051,1.02225,1.02225,0,0,0,.24951-.71973.92424.92424,0,0,0-.23975-.67285.83624.83624,0,0,0-.62695-.24707.87556.87556,0,0,0-.43457.10938.85058.85058,0,0,0-.30469.28417l-.46777-.06054.39307-2.085h2.01806v.47656h-1.61914l-.21875,1.09082a1.31949,1.31949,0,0,1,.7666-.25488,1.21227,1.21227,0,0,1,.897.36816,1.28485,1.28485,0,0,1,.36523.94629,1.47855,1.47855,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49316,1.31126,1.31126,0,0,1-.90381-.31054A1.20809,1.20809,0,0,1,103.20707,85.15567Z"
          />
          <path
            className="cls-7"
            d="M108.52055,86.21915H108.022V83.04434a2.59009,2.59009,0,0,1-.47168.34278,3.39945,3.39945,0,0,1-.5249.25781v-.48145a3.02778,3.02778,0,0,0,.731-.47656,1.87313,1.87313,0,0,0,.44287-.543h.32129Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A052'
              ? 'white-text'
              : elementOnHover === 'A52'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 52)}
          onMouseOver={() => setElementOnHover('A52')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.898,108.4252l1.55859-4.05859h.57861l1.66065,4.05859H102.084l-.47314-1.22949H99.9141l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20658,107.36172l.52295-.04394a.93822.93822,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.79521.79521,0,0,0,.60889-.27051,1.02224,1.02224,0,0,0,.24951-.71972.92424.92424,0,0,0-.23975-.67285.83621.83621,0,0,0-.62695-.24708.87559.87559,0,0,0-.43457.10938.8506.8506,0,0,0-.30469.28418l-.46777-.06055.39307-2.085h2.01806v.47656H104.087l-.21875,1.09082a1.31953,1.31953,0,0,1,.7666-.25488,1.21222,1.21222,0,0,1,.897.36817,1.28481,1.28481,0,0,1,.36523.94628,1.47853,1.47853,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49317,1.31127,1.31127,0,0,1-.90381-.31055A1.20811,1.20811,0,0,1,103.20658,107.36172Z"
          />
          <path
            className="cls-7"
            d="M109.26176,107.94669v.47851h-2.68262a.8828.8828,0,0,1,.05859-.3457,1.81768,1.81768,0,0,1,.32764-.54,5.091,5.091,0,0,1,.65186-.61426,5.52567,5.52567,0,0,0,.89453-.86035,1.02736,1.02736,0,0,0,.23242-.59863.66746.66746,0,0,0-.21191-.5.763.763,0,0,0-.55225-.20313.776.776,0,0,0-.57568.21582.8154.8154,0,0,0-.21875.59766l-.51221-.05274a1.25985,1.25985,0,0,1,.396-.873,1.35108,1.35108,0,0,1,.92188-.30078,1.28672,1.28672,0,0,1,.92432.32421,1.06306,1.06306,0,0,1,.34082.80274,1.21512,1.21512,0,0,1-.09961.47851,1.85644,1.85644,0,0,1-.33106.4961,8.003,8.003,0,0,1-.76806.71387c-.29883.251-.49122.42187-.57569.51074a1.6264,1.6264,0,0,0-.21045.27051Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A053'
              ? 'white-text'
              : elementOnHover === 'A53'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 53)}
          onMouseOver={() => setElementOnHover('A53')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,134.1752l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20707,133.11172l.52295-.04394a.93822.93822,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.79521.79521,0,0,0,.60889-.27051,1.02224,1.02224,0,0,0,.24951-.71972.92424.92424,0,0,0-.23975-.67285.83621.83621,0,0,0-.62695-.24708.87556.87556,0,0,0-.43457.10938.8506.8506,0,0,0-.30469.28418l-.46777-.06055.39307-2.085h2.01806v.47656h-1.61914l-.21875,1.09082a1.31949,1.31949,0,0,1,.7666-.25488,1.21224,1.21224,0,0,1,.897.36817,1.28481,1.28481,0,0,1,.36523.94628,1.47853,1.47853,0,0,1-.3208.95215,1.28544,1.28544,0,0,1-1.06592.49317,1.31127,1.31127,0,0,1-.90381-.31055A1.20807,1.20807,0,0,1,103.20707,133.11172Z"
          />
          <path
            className="cls-7"
            d="M106.64652,133.10391l.49805-.0664a1.101,1.101,0,0,0,.292.61035.71991.71991,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57031-.22266,1.42182,1.42182,0,0,0-.35157.05566l.05567-.4375a.71276.71276,0,0,0,.08008.00586,1.01992,1.01992,0,0,0,.57275-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18554-.4541.658.658,0,0,0-.479-.17969.67828.67828,0,0,0-.48437.18262.92118.92118,0,0,0-.249.54785l-.498-.08789a1.28428,1.28428,0,0,1,.415-.77734A1.2002,1.2002,0,0,1,107.917,130.1a1.33249,1.33249,0,0,1,.61182.14257,1.03659,1.03659,0,0,1,.42773.38965.99686.99686,0,0,1,.14795.52246.85785.85785,0,0,1-.14111.4795.96679.96679,0,0,1-.418.34277.94609.94609,0,0,1,.55908.34473,1.05059,1.05059,0,0,1,.19922.65527,1.1976,1.1976,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.27678,1.27678,0,0,1-.8872-.31836A1.23406,1.23406,0,0,1,106.64652,133.10391Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A054'
              ? 'white-text'
              : elementOnHover === 'A54'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 54)}
          onMouseOver={() => setElementOnHover('A54')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.8018,125.974l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.11039,124.91055l.52295-.04394a.93822.93822,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.79521.79521,0,0,0,.60889-.27051,1.02225,1.02225,0,0,0,.24951-.71973.92427.92427,0,0,0-.23975-.67285.83624.83624,0,0,0-.627-.24707.87556.87556,0,0,0-.43457.10938.8506.8506,0,0,0-.30469.28418l-.46777-.06055.39307-2.085H60.6099v.47656H58.99076l-.21875,1.09082a1.31949,1.31949,0,0,1,.7666-.25488,1.21225,1.21225,0,0,1,.897.36816,1.28485,1.28485,0,0,1,.36523.94629,1.47855,1.47855,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49317,1.31127,1.31127,0,0,1-.90381-.31055A1.20809,1.20809,0,0,1,58.11039,124.91055Z"
          />
          <path
            className="cls-7"
            d="M63.14408,125.974v-.97168H61.38334v-.457l1.85205-2.62988h.40723v2.62988h.54785v.457h-.54785v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A055'
              ? 'white-text'
              : elementOnHover === 'A55'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 55)}
          onMouseOver={() => setElementOnHover('A55')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M53.8018,91.99893l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.11039,90.93546l.52295-.044a.93822.93822,0,0,0,.27.57422.72994.72994,0,0,0,.51074.19238.79521.79521,0,0,0,.60889-.27051,1.02222,1.02222,0,0,0,.24951-.71972.92424.92424,0,0,0-.23975-.67285.8362.8362,0,0,0-.627-.24707.87556.87556,0,0,0-.43457.10937.8506.8506,0,0,0-.30469.28418l-.46777-.06055.39307-2.085H60.6099v.47656H58.99076L58.772,89.56339a1.31943,1.31943,0,0,1,.7666-.25489,1.21222,1.21222,0,0,1,.897.36817,1.28482,1.28482,0,0,1,.36523.94629,1.47852,1.47852,0,0,1-.3208.95214,1.2854,1.2854,0,0,1-1.06592.49317,1.31127,1.31127,0,0,1-.90381-.31055A1.20807,1.20807,0,0,1,58.11039,90.93546Z"
          />
          <path
            className="cls-7"
            d="M61.54691,90.93546l.523-.044a.93828.93828,0,0,0,.27.57422.73.73,0,0,0,.51074.19238.7952.7952,0,0,0,.60889-.27051,1.02217,1.02217,0,0,0,.24951-.71972.92428.92428,0,0,0-.23974-.67285.83624.83624,0,0,0-.627-.24707.87558.87558,0,0,0-.43457.10937.85049.85049,0,0,0-.30468.28418l-.46778-.06055.39307-2.085h2.01807v.47656H62.42728l-.21875,1.09083a1.31948,1.31948,0,0,1,.76661-.25489,1.2122,1.2122,0,0,1,.897.36817,1.28482,1.28482,0,0,1,.36523.94629,1.47852,1.47852,0,0,1-.3208.95214,1.2854,1.2854,0,0,1-1.06592.49317,1.31122,1.31122,0,0,1-.9038-.31055A1.20812,1.20812,0,0,1,61.54691,90.93546Z"
          />
        </g>
      </g>
      <g id="state_2_" className="st9">
        <path
          id="plate_12_"
          className="st10"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />
        <text
          id="_x33__3_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st10 st11 st12"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st10 st11 st12"
        >
          2021
        </text>
        <g className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="_x33__2_" className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="down_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g id="up_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 47 ? (
          <g id="A47">
            <linearGradient
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              style={{
                fill: 'url(#A11cone_00000116942025443687874220000014186524061492083896_)',
              }}
              d="
			M33.1855202,69.3973846c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 48 ? (
          <g id="A48">
            <linearGradient
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              style={{
                fill: 'url(#A11cone_00000049918791379197865380000014062642777780403637_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 49 ? (
          <g id="A49">
            <linearGradient
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              style={{
                fill: 'url(#A11cone_00000101824782659766024400000003894925743768571553_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 50 ? (
          <g id="A50">
            <linearGradient
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              gradientUnits="userSpaceOnUse"
              x1="211.7723846"
              y1="309.9545898"
              x2="211.7723846"
              y2="265.0445862"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000104685683903993578170000014400541892270177670_)',
              }}
              d="
			M123.146759,67.1088257c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.2567596,41.838829V85.62883L123.146759,67.1088257z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 51 ? (
          <g id="A51">
            <linearGradient
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              gradientUnits="userSpaceOnUse"
              x1="237.6501465"
              y1="309.7736206"
              x2="237.6501465"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              style={{
                fill: 'url(#A11cone_00000132062808605534171860000004161307431760505756_)',
              }}
              d="
			M122.9657669,92.9865952c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,67.7165985v43.7900009L122.9657669,92.9865952z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 52 ? (
          <g id="A52">
            <linearGradient
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              gradientUnits="userSpaceOnUse"
              x1="261.5904846"
              y1="309.7736206"
              x2="261.5904846"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              style={{
                fill: 'url(#A11cone_00000161621834216018166600000011104810949275289753_)',
              }}
              d="
			M122.9657669,116.926918c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,91.6569214v43.7899933L122.9657669,116.926918z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 53 ? (
          <g id="A53">
            <linearGradient
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              gradientUnits="userSpaceOnUse"
              x1="293.358429"
              y1="288.4524536"
              x2="293.358429"
              y2="243.5424652"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              style={{
                fill: 'url(#A11cone_00000046338767822234361200000006092721421211844490_)',
              }}
              d="
			M101.6446304,148.69487c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0399933,0.2100067-2.0299988,0.6000061-2.9199982L56.754631,123.4248734v43.7900009L101.6446304,148.69487z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 54 ? (
          <g id="A54">
            <linearGradient
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              gradientUnits="userSpaceOnUse"
              x1="273.7284241"
              y1="219.9933624"
              x2="273.7284241"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              style={{
                fill: 'url(#A11cone_00000119105400635965422190000001418125762866781067_)',
              }}
              d="
			M33.1855202,129.0648804c-0.3699951-0.8899994-0.5800018-1.8600082-0.5800018-2.890007
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900085L33.1855202,129.0648804z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 55 ? (
          <g id="A55">
            <linearGradient
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              gradientUnits="userSpaceOnUse"
              x1="242.9080505"
              y1="220.4395905"
              x2="242.9080505"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              style={{
                fill: 'url(#A11cone_00000030460207653560477860000005665363980855605161_)',
              }}
              d="
			M33.6317444,98.2444992c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900009L33.6317444,98.2444992z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_2_">
        <path
          id="plate_11_"
          className="st6"
          d="M172.1359558,110.5138321h-0.0000153c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802
		V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802h0.0000153
		c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_10_"
          className="st14"
          d="M178.3349304,90.8048477l-0.243988-38.549572
		c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688l0.243988,38.549572
		c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__00000113346875707487532540000000084117161377660577_">
          <path
            className="st15"
            d="M170.4111786,80.9291077v-0.7182617h3.9414063v0.5810547
			c-0.3876953,0.4125977-0.7724609,0.9609375-1.1523438,1.6445313c-0.3808594,0.6835938-0.6748047,1.3867188-0.8828125,2.109375
			c-0.1494141,0.5092773-0.2451172,1.0668945-0.2861328,1.6733398h-0.7685547
			c0.0087891-0.4790039,0.1025391-1.0576172,0.2822266-1.7358398s0.4384766-1.3320313,0.7744141-1.9619141
			c0.3369141-0.6293945,0.6943359-1.1606445,1.0732422-1.5922852H170.4111786z"
          />
        </g>
        <path
          id="up_9_"
          className="st16"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_9_"
          className="st16"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st6"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st6"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  47: [37.7855263, 66.627388],
  48: [62.2647095, 14.9237556],
  49: [103.8317642, 14.6850052],
  50: [127.7467651, 64.338829],
  51: [127.565773, 90.2165985],
  52: [127.565773, 114.1569214],
  53: [106.2446365, 145.924881],
  54: [37.7855263, 126.2948761],
  55: [38.2317505, 95.4745026],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    font-family: 'ArialMT';
  }
  .st8 {
    font-size: 5.6692901px;
  }
  .st9 {
    display: none;
  }
  .st10 {
    display: inline;
    fill: #3f3f3c;
  }
  .st11 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st12 {
    font-size: 8.5039396px;
  }
  .st13 {
    display: inline;
  }
  .st14 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #d3d2cf;
  }
  .st16 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: url(#A11cone_00000031908242821372644320000010099770893659807883_);
  }
  .st18 {
    fill: #c65805;
  }
  .st19 {
    fill: url(#A11cone_00000030460933582450340840000016738180085453283200_);
  }
  .st20 {
    fill: url(#A11cone_00000083803232135376585140000011405799044347850120_);
  }
  .st21 {
    fill: url(#A11cone_00000181045771646907618530000009514442668071759542_);
  }
  .st22 {
    fill: url(#A11cone_00000155131472214916524750000011241014034238265226_);
  }
  .st23 {
    fill: url(#A11cone_00000027604712313906409150000013128361352687773609_);
  }
  .st24 {
    fill: url(#A11cone_00000070101668780062261030000012829174151800702085_);
  }
  .st25 {
    fill: url(#A11cone_00000157289664823482042060000003122567549782656903_);
  }
  .st26 {
    fill: url(#A11cone_00000004528441680822221230000012008214006472699031_);
  }

  #A11,
  #A12,
  #A13,
  #A14,
  #A15,
  #B39,
  #B40,
  #B41,
  #C65,
  #C66,
  #C67,
  #C68,
  #C69,
  #C70 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-7 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
