export const FLOOR_5_DATA = [
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 29,
    sceneName: 'scene_A029',
    sceneName_future: 'scene_A029_future',
    name: 'A29',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      4: 20,
      6: 38,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 30,
    sceneName: 'scene_A030',
    sceneName_future: 'scene_A030_future',
    name: 'A30',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      4: 21,
      6: 39,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 31,
    sceneName: 'scene_A031',
    sceneName_future: 'scene_A031_future',
    name: 'A31',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      4: 22,
      6: 40,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 32,
    sceneName: 'scene_A032',
    sceneName_future: 'scene_A032_future',
    name: 'A32',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      4: 23,
      6: 41,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 33,
    sceneName: 'scene_A033',
    sceneName_future: 'scene_A033_future',
    name: 'A33',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      4: 24,
      6: 42,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 34,
    sceneName: 'scene_A034',
    sceneName_future: 'scene_A034_future',
    name: 'A34',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      4: 25,
      6: 43,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 35,
    sceneName: 'scene_A035',
    sceneName_future: 'scene_A035_future',
    name: 'A35',
    lookAt: '-189, 0, 120',
    face: 'south',

    relatedApartments: {
      4: 26,
      6: 44,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 36,
    sceneName: 'scene_A036',
    sceneName_future: 'scene_A036_future',
    name: 'A36',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      4: 27,
      6: 45,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 37,
    sceneName: 'scene_A037',
    sceneName_future: 'scene_A037_future',
    name: 'A37',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      4: 28,
      6: 46,
    },
  },
]
