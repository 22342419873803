import * as React from 'react'
import styled from 'styled-components/macro'

import ArrowIcon from 'icons/arrow'
import { FLOORS_DATA } from 'data/floors_data'
import Collapsible from './Collapsible'
import Floor8 from 'floor-planes/floor8'
import Floor7 from 'floor-planes/floor7'
import Floor6 from 'floor-planes/floor6'
import Floor5 from 'floor-planes/floor5'
import Floor4 from 'floor-planes/floor4'
import Floor3 from 'floor-planes/floor3'
import Floor2 from 'floor-planes/floor2'
import Floor1 from 'floor-planes/floor1'

const initialApartment = {
  floor: 8,
  building: 'A',
  apartmentNumber: `${58}_north`,
  sceneName: 'scene_A058_north',
  sceneName_future: 'scene_A058_north_future',
  name: 'A58_north',
  lookAt: '-8, 0, 120',
  face: 'north',

  relatedApartments: {
    7: 49,
  },
}

export default function NavigationPanel({ krpano, hLookAt }) {
  const [activeFloor, setActiveFloor] = React.useState(initialApartment.floor)
  const [activeApartment, setActiveApartment] = React.useState(initialApartment)
  const [collapse, setCollapse] = React.useState(false)
  const [isSceneNameFuture, setIsSceneNameFuture] = React.useState(false)
  const [currentFace, setCurrentFace] = React.useState('')
  const floors = Object.keys(FLOORS_DATA)

  React.useEffect(() => {
    if (
      window.matchMedia('(orientation: landscape)').matches &&
      window.matchMedia('(max-device-width: 667px)').matches
    ) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [])

  const loadScene = React.useCallback(
    (sceneName, lookAt, activeFace) => {
      if (krpano) {
        const hlookat = krpano.get('view.hlookat')
        const vlookat = krpano.get('view.vlookat')
        const fov = krpano.get('view.fov')
        const distortion = krpano.get('view.distortion')

        krpano.call(
          `loadscene(${sceneName}, null, MERGE, BLEND(0.5, easeInCubic)); lookat(${lookAt});`,
        )

        if (currentFace === activeFace) {
          krpano.set('view.hlookat', hlookat)
          krpano.set('view.vlookat', vlookat)
          krpano.set('view.fov', fov)
          krpano.set('view.distortion', distortion)
        }
      }
    },
    [currentFace, krpano],
  )

  const changeView = React.useCallback(
    (floorNumber, aptNumber) => {
      const apartment = FLOORS_DATA[floorNumber].find(
        e => e.apartmentNumber === aptNumber,
      )
      const lookAt = apartment.lookAt
      const activeFace = apartment.face
      const sceneName = isSceneNameFuture
        ? apartment.sceneName_future
        : apartment.sceneName
      setActiveApartment(apartment)
      setCurrentFace(activeFace)
      loadScene(sceneName, lookAt, activeFace)
    },
    [isSceneNameFuture, loadScene],
  )

  const changeFloor = navCase => {
    console.log('navCase:', navCase)
    const { relatedApartments, floor } = activeApartment
    const targetedFloor = changeActiveFloor(navCase) || floor
    const targetedApartmentNumber = relatedApartments[targetedFloor]

    !!relatedApartments[targetedFloor] &&
      changeView(targetedFloor, targetedApartmentNumber)
  }

  const changeActiveFloor = navCase => {
    let targetedFloor
    const { relatedApartments } = activeApartment

    if (navCase === 'up' && activeFloor < Number(floors[floors.length - 1])) {
      targetedFloor = activeFloor + 1
      !!relatedApartments[targetedFloor] && setActiveFloor(targetedFloor)
    }
    if (navCase === 'down' && activeFloor > Number(floors[0])) {
      targetedFloor = activeFloor - 1
      !!relatedApartments[targetedFloor] && setActiveFloor(targetedFloor)
    }
    return targetedFloor
  }

  const changeApartInCollapsible = navCase => {
    const apartments = Object.values(FLOORS_DATA[activeFloor]).map(
      e => e.apartmentNumber,
    )
    const { apartmentNumber } = activeApartment
    const apartmentIndex = apartments.indexOf(apartmentNumber)

    if (navCase === 'next') {
      if (apartmentIndex < apartments.length - 1) {
        changeView(activeFloor, apartments[apartmentIndex + 1])
      } else if (apartmentIndex === apartments.length - 1) {
        changeView(activeFloor, apartments[0])
      }
    }
    if (navCase === 'previous') {
      if (apartmentIndex > 0) {
        changeView(activeFloor, apartments[apartmentIndex - 1])
      } else if (apartmentIndex === 0) {
        changeView(activeFloor, apartments[apartments.length - 1])
      }
    }
  }

  React.useEffect(() => {
    // Initialize first view
    changeView(activeFloor, activeApartment.apartmentNumber)
  }, [activeApartment.apartmentNumber, activeFloor, changeView])

  const toggleSceneNameFuture = () => setIsSceneNameFuture(!isSceneNameFuture)

  const floorPlanProps = {
    activeApartment,
    changeFloor,
    changeView,
    hLookAt,
    toggleSceneNameFuture,
    isSceneNameFuture,
  }

  return (
    <Wrapper>
      <Collapsible collapse={collapse} setCollapse={setCollapse}>
        {collapse ? (
          <ApartmentInfo>
            <ElevatorIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  onClick={() => changeFloor('up')}
                  disabled={activeFloor === Number(floors[floors.length - 1])}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="180deg"
                  onClick={() => changeFloor('down')}
                  disabled={activeFloor === Number(floors[0])}
                />
              </div>
            </ElevatorIcons>
            <p className="floor">Kerros {activeApartment.floor}</p>
            <p className="apartment">Huoneisto {activeApartment.name}</p>
            <ApartmentIcons>
              <div style={{ marginRight: '10px' }}>
                <ArrowIcon
                  rotateDeg="-90deg"
                  onClick={() => changeApartInCollapsible('next')}
                />
              </div>
              <div>
                <ArrowIcon
                  rotateDeg="90deg"
                  onClick={() => changeApartInCollapsible('previous')}
                />
              </div>
            </ApartmentIcons>
          </ApartmentInfo>
        ) : (
          <div style={{ position: 'relative' }}>
            <SwitchComponents active={activeFloor}>
              <Floor1 floor={1} {...floorPlanProps} />
              <Floor2 floor={2} {...floorPlanProps} />
              <Floor3 floor={3} {...floorPlanProps} />
              <Floor4 floor={4} {...floorPlanProps} />
              <Floor5 floor={5} {...floorPlanProps} />
              <Floor6 floor={6} {...floorPlanProps} />
              <Floor7 floor={7} {...floorPlanProps} />
              <Floor8 floor={8} {...floorPlanProps} />
            </SwitchComponents>
            <Text>Tietoa 360 Maisemakone</Text>
          </div>
        )}
      </Collapsible>
    </Wrapper>
  )
}

function SwitchComponents({ active, children }) {
  return !!children
    ? children.filter(child => child.props.floor === active)
    : null
}

const Text = styled.a`
  position: absolute;
  right: -65px;
  top: 90px;
  transform: rotate(270deg);
  font-size: 13px;
  opacity: 0.5;
  color: #3f3f3c;
  user-select: none;

  @media (max-width: 575px) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    display: none;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 510px;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  background-color: rgb(213, 212, 207);
  z-index: 2;

  @media (max-width: 575px) {
    right: 0;
    width: 100%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    width: 300px;
    right: 0;
    bottom: 0;
  }
`

const ApartmentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    min-width: 50px;
    margin: 0;
    padding: 10px 20px;
    color: var(--light);
    font-size: 14px;
  }

  .floor {
    background-color: var(--yit-blue);
    color: var(--yit-white);
  }

  .apartment {
    min-width: 120px;
    margin-right: 0;
    background-color: var(--yit-blue-80);
    color: var(--yit-white);
  }

  @media (max-width: 575px) {
    p {
      padding: 5px 10px;
      font-size: 10px;
    }

    .apartment {
      min-width: 80px;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--yit-blue-80);
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    p {
      padding: 5px;
      font-size: 10px;
    }

    .floor {
      min-width: 60px;
    }

    .apartment {
      min-width: 90px;
      margin-right: 0;
      background-color: var(--yit-blue-80);
    }
  }
`

const ElevatorIcons = styled.div`
  display: flex;
  margin-right: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-right: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-right: 14px;
  }
`
const ApartmentIcons = styled.div`
  display: flex;
  margin-left: 2rem;
  padding-top: 5px;

  @media (max-width: 575px) {
    margin-left: 1rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 850px) and (min--moz-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 14px;
  }
`
