import { FLOOR_1_DATA } from './floor_1_data'
import { FLOOR_2_DATA } from './floor_2_data'
import { FLOOR_3_DATA } from './floor_3_data'
import { FLOOR_4_DATA } from './floor_4_data'
import { FLOOR_5_DATA } from './floor_5_data'
import { FLOOR_6_DATA } from './floor_6_data'
import { FLOOR_7_DATA } from './floor_7_data'
import { FLOOR_8_DATA } from './floor_8_data'

export const FLOORS_DATA = {
  1: FLOOR_1_DATA,
  2: FLOOR_2_DATA,
  3: FLOOR_3_DATA,
  4: FLOOR_4_DATA,
  5: FLOOR_5_DATA,
  6: FLOOR_6_DATA,
  7: FLOOR_7_DATA,
  8: FLOOR_8_DATA,
}
