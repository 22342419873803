import * as React from 'react'
import styled from 'styled-components'

export default function Floor2({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_00000075146746112064964650000001075172412526630830_">
        <g id="static_00000039824772558286107730000002524845150549975743_">
          <rect
            id="plate_00000042015385333605109030000009469192871524770186_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(2, 5)}
          onMouseOver={() => setElementOnHover('A05')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A05"
            className={`st1 ${
              sceneName === 'scene_A005'
                ? 'active-apt'
                : elementOnHover === 'A05'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061
		77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301 42.2604675,72.9745026 33.9492569,72.9745026
		33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>

        <g
          onClick={() => changeView(2, 6)}
          onMouseOver={() => setElementOnHover('A06')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A06"
            className={`st1 ${
              sceneName === 'scene_A006'
                ? 'active-apt'
                : elementOnHover === 'A06'
                ? 'on-hover'
                : ''
            }`}
            points="41.2536697,10.6093349
		42.2604675,55.7983246 77.8867569,55.7983246 77.8867569,45.5375061 85.0117569,45.7250023 85.0117569,10.5916872 	"
          />
        </g>

        <g
          onClick={() => changeView(2, 7)}
          onMouseOver={() => setElementOnHover('A07')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A07"
            className={`st1 ${
              sceneName === 'scene_A007'
                ? 'active-apt'
                : elementOnHover === 'A07'
                ? 'on-hover'
                : ''
            }`}
            x="85.0117569"
            y="10.5916872"
            width="38.3938446"
            height="44.0395699"
          />
        </g>

        <g
          onClick={() => changeView(2, 8)}
          onMouseOver={() => setElementOnHover('A08')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A08"
            className={`st1 ${
              sceneName === 'scene_A008'
                ? 'active-apt'
                : elementOnHover === 'A08'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,72.1625061
		85.0117569,72.1625061 85.0117569,54.6312561 122.8242569,54.6312561 122.8242569,57.7250099 132.0742645,57.7250061
		132.0742645,70.6000061 122.8242569,70.6000061 	"
          />
        </g>

        <g
          onClick={() => changeView(2, 9)}
          onMouseOver={() => setElementOnHover('A09')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A09"
            className={`st1 ${
              sceneName === 'scene_A009'
                ? 'active-apt'
                : elementOnHover === 'A09'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,106.7914276
		77.4492569,106.7914276 77.4492569,141.4750061 43.0117569,141.7875061 42.750164,132.4725342 33.9492569,132.4725342
		33.9492569,119.5975266 42.750164,119.4750061 	"
          />
        </g>
        <g
          onClick={() => changeView(2, 10)}
          onMouseOver={() => setElementOnHover('A10')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A10"
            className={`st1 ${
              sceneName === 'scene_A010'
                ? 'active-apt'
                : elementOnHover === 'A10'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061
		70.8242493,74.2875061 70.8242569,90.3806229 77.4492569,90.3806229 77.4492569,106.7914276 42.8933792,107.0390778
		42.750164,101.9393234 33.9492569,101.9393234 33.9492569,89.0643234 42.750164,88.9417953 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000038389395106861488850000013443522332089521319_">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,141.4750061 42.8242569,141.4750061 	"
        />
        <polyline
          className="st3"
          points="41.2536697,19.9926529 41.2536697,10.6093349 123.4056015,10.6093349 123.4056015,19.4634018 	"
        />
        <polyline
          className="st3"
          points="122.8242569,57.7250061 132.0742645,57.7250061 132.0742645,70.6000061 122.8242569,70.6000061 	"
        />
        <polyline
          className="st3"
          points="42.750164,132.4725342 33.9492569,132.4725342 33.9492569,119.4750061 42.750164,119.4750061 	"
        />
        <polyline
          className="st3"
          points="42.4986992,101.9198456 33.9492569,101.9198456 33.9492569,89.0065994 42.750164,89.0065994 	"
        />
        <polyline
          className="st3"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,10.5916872 85.0117569,45.7250023 85.0117569,141.4750061 	"
        />
        <polyline
          className="st4"
          points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,45.5375061 85.0117569,45.5375061
		77.8867569,45.5375061 77.8867569,141.4750061 	"
        />
        <line
          className="st4"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st4"
          x1="42.5387955"
          y1="106.7914276"
          x2="77.8867569"
          y2="106.7914276"
        />
        <polyline
          className="st4"
          points="85.0117569,113.8500061 103.4805069,113.8500061 103.4805069,123.2562561 122.8242569,123.2562561
		"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="72.1625061"
          x2="122.8242569"
          y2="72.1625061"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="54.6312561"
          x2="122.8242569"
          y2="54.6312561"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="96.6420212"
          x2="122.2897873"
          y2="96.3031311"
        />
        <polyline
          className="st4"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 	"
        />
        <polyline
          className="st4"
          points="70.8242569,73.9698181 70.8242569,90.3806229 77.8867569,90.3806229 	"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A005'
              ? 'white-text'
              : elementOnHover === 'A05'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 5)}
          onMouseOver={() => setElementOnHover('A05')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11381,66.53751l1.55859-4.0586H56.251l1.66064,4.0586h-.61182L56.8267,65.308H55.12992l-.4458,1.2295Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.5127-.2876-.8418a5.1985,5.1985,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.4224,64.53653a3.72109,3.72109,0,0,1,.14795-1.15918,1.44449,1.44449,0,0,1,.44043-.67676,1.127,1.127,0,0,1,.73486-.23828,1.19489,1.19489,0,0,1,.57276.13184,1.09873,1.09873,0,0,1,.40722.3789,2.06014,2.06014,0,0,1,.252.60352,4.03649,4.03649,0,0,1,.09131.96,3.70859,3.70859,0,0,1-.147,1.15234,1.44447,1.44447,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23926,1.11379,1.11379,0,0,1-.92187-.4209A2.67418,2.67418,0,0,1,58.4224,64.53653Zm.51221,0a2.54219,2.54219,0,0,0,.23389,1.33008.66861.66861,0,0,0,1.15429-.002,3.89884,3.89884,0,0,0,0-2.66015.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,58.93461,64.53653Z"
          />
          <path
            className="cls-7"
            d="M61.85893,65.474l.52294-.04395a.93825.93825,0,0,0,.27.57422.73.73,0,0,0,.51075.19239.79522.79522,0,0,0,.60888-.27051,1.0222,1.0222,0,0,0,.24951-.71973.92424.92424,0,0,0-.23974-.67285.83625.83625,0,0,0-.627-.24707.87564.87564,0,0,0-.43457.10937.85061.85061,0,0,0-.30469.28418l-.46778-.06054.39307-2.085h2.01807v.47656H62.7393L62.52055,64.102a1.31949,1.31949,0,0,1,.7666-.25488,1.21223,1.21223,0,0,1,.897.36816,1.28486,1.28486,0,0,1,.36524.94629,1.47855,1.47855,0,0,1-.32081.95215,1.28541,1.28541,0,0,1-1.06591.49316,1.31126,1.31126,0,0,1-.90381-.31054A1.20809,1.20809,0,0,1,61.85893,65.474Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A006'
              ? 'white-text'
              : elementOnHover === 'A06'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 6)}
          onMouseOver={() => setElementOnHover('A06')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11381,38.79971l1.55859-4.05859H56.251l1.66064,4.05859h-.61182l-.47314-1.22949H55.12992l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.51269-.2876-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.4224,36.79874a3.72109,3.72109,0,0,1,.14795-1.15918,1.44444,1.44444,0,0,1,.44043-.67676,1.127,1.127,0,0,1,.73486-.23828,1.19489,1.19489,0,0,1,.57276.13183,1.09875,1.09875,0,0,1,.40722.37891,2.06014,2.06014,0,0,1,.252.60352,4.03643,4.03643,0,0,1,.09131.96,3.70863,3.70863,0,0,1-.147,1.15234,1.44447,1.44447,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23926,1.11382,1.11382,0,0,1-.92187-.4209A2.67418,2.67418,0,0,1,58.4224,36.79874Zm.51221,0a2.54215,2.54215,0,0,0,.23389,1.33007.66861.66861,0,0,0,1.15429-.002,2.54593,2.54593,0,0,0,.23389-1.32812,2.54609,2.54609,0,0,0-.23389-1.332.694.694,0,0,0-.583-.32911.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,58.93461,36.79874Z"
          />
          <path
            className="cls-7"
            d="M64.44437,35.73526l-.4956.03906a.94144.94144,0,0,0-.188-.42676.66115.66115,0,0,0-.49853-.21289.68308.68308,0,0,0-.418.13282,1.1378,1.1378,0,0,0-.3711.501,2.62552,2.62552,0,0,0-.14111.93848,1.15777,1.15777,0,0,1,.44043-.40723,1.19114,1.19114,0,0,1,.54541-.13281,1.12745,1.12745,0,0,1,.84814.36719,1.31613,1.31613,0,0,1,.3501.94824,1.5614,1.5614,0,0,1-.16455.71,1.1987,1.1987,0,0,1-.45264.502,1.23635,1.23635,0,0,1-.65332.17481A1.27493,1.27493,0,0,1,62.23,38.411,2.313,2.313,0,0,1,61.837,36.9003a2.70142,2.70142,0,0,1,.43457-1.71,1.24805,1.24805,0,0,1,1.02149-.46582,1.08686,1.08686,0,0,1,1.15136,1.01074Zm-2.03466,1.75a1.156,1.156,0,0,0,.10937.49219.81206.81206,0,0,0,.30567.3584.76649.76649,0,0,0,.41259.123.70467.70467,0,0,0,.54248-.2539,1.00269,1.00269,0,0,0,.22705-.69239.93834.93834,0,0,0-.22412-.66308.73793.73793,0,0,0-.56445-.24219.76956.76956,0,0,0-.57324.24219A.8731.8731,0,0,0,62.40971,37.48526Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A007'
              ? 'white-text'
              : elementOnHover === 'A07'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 7)}
          onMouseOver={() => setElementOnHover('A07')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20707,36.79874a3.72109,3.72109,0,0,1,.14795-1.15918,1.44436,1.44436,0,0,1,.44043-.67676,1.12694,1.12694,0,0,1,.73486-.23828,1.19492,1.19492,0,0,1,.57276.13183,1.09875,1.09875,0,0,1,.40722.37891,2.06014,2.06014,0,0,1,.252.60352,4.03651,4.03651,0,0,1,.0913.96,3.70867,3.70867,0,0,1-.147,1.15234,1.44447,1.44447,0,0,1-.43847.67871,1.12849,1.12849,0,0,1-.7378.23926,1.11382,1.11382,0,0,1-.92187-.4209A2.67418,2.67418,0,0,1,103.20707,36.79874Zm.51221,0a2.54217,2.54217,0,0,0,.23388,1.33007.66862.66862,0,0,0,1.1543-.002,2.54593,2.54593,0,0,0,.23389-1.32812,2.54609,2.54609,0,0,0-.23389-1.332.694.694,0,0,0-.583-.32911.639.639,0,0,0-.54785.29A2.575,2.575,0,0,0,103.71928,36.79874Z"
          />
          <path
            className="cls-7"
            d="M106.6768,35.27335v-.4795h2.62695v.3877a5.53506,5.53506,0,0,0-.76807,1.09668,6.65047,6.65047,0,0,0-.58838,1.40625,5.2265,5.2265,0,0,0-.19091,1.11523h-.51221a4.95775,4.95775,0,0,1,.18848-1.15722,6.06191,6.06191,0,0,1,.51611-1.30762,5.59147,5.59147,0,0,1,.71533-1.06152Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A008'
              ? 'white-text'
              : elementOnHover === 'A08'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 8)}
          onMouseOver={() => setElementOnHover('A08')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.899,65.57364l1.5586-4.05859h.57861l1.66065,4.05859H102.085l-.47314-1.22949H99.91508l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.197,5.197,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M103.20756,63.57266a3.72079,3.72079,0,0,1,.14795-1.15918,1.44441,1.44441,0,0,1,.44043-.67676,1.12694,1.12694,0,0,1,.73486-.23828,1.19488,1.19488,0,0,1,.57275.13184,1.0987,1.0987,0,0,1,.40723.37891,2.06,2.06,0,0,1,.252.60351,4.036,4.036,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15235,1.44459,1.44459,0,0,1-.43848.67871,1.12844,1.12844,0,0,1-.73779.23925,1.11381,1.11381,0,0,1-.92187-.42089A2.67422,2.67422,0,0,1,103.20756,63.57266Zm.51221,0a2.54219,2.54219,0,0,0,.23388,1.33008.66862.66862,0,0,0,1.1543-.00195,3.89887,3.89887,0,0,0,0-2.66016.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54785.29A2.5749,2.5749,0,0,0,103.71977,63.57266Z"
          />
          <path
            className="cls-7"
            d="M107.41068,63.37247a.93833.93833,0,0,1-.45947-.32325.84908.84908,0,0,1-.14941-.5039.98619.98619,0,0,1,.31836-.74512,1.34,1.34,0,0,1,1.70263.00684,1.00106,1.00106,0,0,1,.32373.752.826.826,0,0,1-.14795.49121.93151.93151,0,0,1-.45019.32227,1.095,1.095,0,0,1,.56885.39355,1.07666,1.07666,0,0,1,.19531.64746,1.16487,1.16487,0,0,1-.36817.875,1.49938,1.49938,0,0,1-1.9375-.001,1.18366,1.18366,0,0,1-.36816-.8877,1.07147,1.07147,0,0,1,.20068-.66309A1.01122,1.01122,0,0,1,107.41068,63.37247Zm-.26025,1.03027a.90119.90119,0,0,0,.10107.41211.71018.71018,0,0,0,.3003.30859.8755.8755,0,0,0,.4292.10938.80385.80385,0,0,0,.58984-.22949.787.787,0,0,0,.23242-.584.79839.79839,0,0,0-.23926-.59472.82018.82018,0,0,0-.59961-.23536.79284.79284,0,0,0-.814.81348Zm.16064-1.874a.63384.63384,0,0,0,.18555.4707.65551.65551,0,0,0,.48145.18262.64309.64309,0,0,0,.47216-.18164.59991.59991,0,0,0,.18409-.44434.62211.62211,0,0,0-.18946-.46094.69749.69749,0,0,0-.94531-.0039A.58886.58886,0,0,0,107.31107,62.52872Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A009'
              ? 'white-text'
              : elementOnHover === 'A09'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 9)}
          onMouseOver={() => setElementOnHover('A09')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11332,125.974l1.55859-4.05859h.57862l1.66064,4.05859h-.61181l-.47315-1.22949H55.12943l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.5127-.2876-.8418a5.19816,5.19816,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M58.42191,123.97305a3.72109,3.72109,0,0,1,.148-1.15918,1.44453,1.44453,0,0,1,.44043-.67675,1.12694,1.12694,0,0,1,.73487-.23829,1.19488,1.19488,0,0,1,.57275.13184,1.09886,1.09886,0,0,1,.40723.37891,2.06022,2.06022,0,0,1,.25195.60351,4.036,4.036,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15235,1.44461,1.44461,0,0,1-.43848.67871,1.12849,1.12849,0,0,1-.73779.23926,1.11384,1.11384,0,0,1-.92188-.4209A2.67422,2.67422,0,0,1,58.42191,123.97305Zm.51221,0a2.54216,2.54216,0,0,0,.23389,1.33008.66861.66861,0,0,0,1.15429-.00195,3.89887,3.89887,0,0,0,0-2.66016.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54786.29A2.575,2.575,0,0,0,58.93412,123.97305Z"
          />
          <path
            className="cls-7"
            d="M61.93314,125.03555l.479-.04394a.82916.82916,0,0,0,.23242.48926.638.638,0,0,0,.43994.15234.76643.76643,0,0,0,.40283-.10449.86275.86275,0,0,0,.28369-.28125,1.65288,1.65288,0,0,0,.18555-.47461,2.50282,2.50282,0,0,0,.07471-.60938c0-.02246-.001-.05566-.00244-.09961a1.14458,1.14458,0,0,1-.40869.38672,1.10955,1.10955,0,0,1-.56055.14746,1.13454,1.13454,0,0,1-.85254-.36523,1.33606,1.33606,0,0,1-.34863-.96289,1.369,1.369,0,0,1,.36377-.99414,1.21679,1.21679,0,0,1,.91211-.377,1.29827,1.29827,0,0,1,.72412.21387,1.32781,1.32781,0,0,1,.498.60742,2.98489,2.98489,0,0,1,.17041,1.1416A3.6947,3.6947,0,0,1,64.358,125.101a1.44692,1.44692,0,0,1-.50244.70117,1.2959,1.2959,0,0,1-.78174.24122,1.07274,1.07274,0,0,1-1.14063-1.00782Zm2.04-1.791a.97639.97639,0,0,0-.228-.68067.71621.71621,0,0,0-.54981-.25195.7571.7571,0,0,0-.57861.27148,1.00554,1.00554,0,0,0-.2461.70313.86561.86561,0,0,0,.23389.62891.76767.76767,0,0,0,.57715.24218.73668.73668,0,0,0,.56885-.24218A.95288.95288,0,0,0,63.97318,123.24454Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A010'
              ? 'white-text'
              : elementOnHover === 'A10'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 10)}
          onMouseOver={() => setElementOnHover('A10')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11332,91.99893l1.55859-4.05859h.57862l1.66064,4.05859h-.61181l-.47315-1.22949H55.12943l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.51269-.2876-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M60.29887,91.99893h-.49854v-3.1748a2.59009,2.59009,0,0,1-.47168.34277,3.39763,3.39763,0,0,1-.5249.25781v-.48144a3.02807,3.02807,0,0,0,.731-.47656,1.87326,1.87326,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M61.85844,89.998a3.72075,3.72075,0,0,1,.148-1.15918,1.44436,1.44436,0,0,1,.44043-.67676,1.12689,1.12689,0,0,1,.73486-.23828,1.19487,1.19487,0,0,1,.57275.13183,1.09878,1.09878,0,0,1,.40723.37891,2.06007,2.06007,0,0,1,.252.60351,4.03606,4.03606,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15234,1.44454,1.44454,0,0,1-.43848.67871,1.12847,1.12847,0,0,1-.73779.23926,1.11385,1.11385,0,0,1-.92188-.4209A2.67427,2.67427,0,0,1,61.85844,89.998Zm.5122,0a2.54215,2.54215,0,0,0,.23389,1.33007.66862.66862,0,0,0,1.1543-.00195,3.899,3.899,0,0,0,0-2.66016.69407.69407,0,0,0-.583-.3291.639.639,0,0,0-.54785.29A2.5749,2.5749,0,0,0,62.37064,89.998Z"
          />
        </g>
      </g>
      <g id="state_2_" className="st9">
        <path
          id="plate_12_"
          className="st10"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />
        <text
          id="_x33__3_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st10 st11 st12"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st10 st11 st12"
        >
          2021
        </text>
        <g className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="_x33__2_" className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="down_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g id="up_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 5 ? (
          <g id="A05">
            <linearGradient
              id="A11cone_00000015340400832559526000000011931581949944641927_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000046301205331163834020000002076902043362108855_"
              style={{
                fill: 'url(#A11cone_00000015340400832559526000000011931581949944641927_)',
              }}
              d="
			M33.1855202,69.3973846c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 6 ? (
          <g id="A06">
            <linearGradient
              id="A11cone_00000182492727560463523950000013252654202542463411_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone"
              style={{
                fill: 'url(#A11cone_00000182492727560463523950000013252654202542463411_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 7 ? (
          <g id="A07">
            <linearGradient
              id="A11cone_00000050630519111913727360000010641306466645247405_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000074412922326238041190000007361132677057823653_"
              style={{
                fill: 'url(#A11cone_00000050630519111913727360000010641306466645247405_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 8 ? (
          <g id="A04_00000181769660711712626670000002301819996166066853_">
            <linearGradient
              id="A11cone_00000085237212540388948970000013468201391475963050_"
              gradientUnits="userSpaceOnUse"
              x1="211.7723846"
              y1="309.9545898"
              x2="211.7723846"
              y2="265.0445862"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000016055994746572630200000005119066722710925238_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000085237212540388948970000013468201391475963050_)',
              }}
              d="
			M123.146759,67.1088257c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.2567596,41.838829V85.62883L123.146759,67.1088257z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 9 ? (
          <g id="A61_00000035498655630565547720000003113169033805950858_">
            <linearGradient
              id="A11cone_00000167358737660613973420000007595951494202595731_"
              gradientUnits="userSpaceOnUse"
              x1="273.7284241"
              y1="219.9933624"
              x2="273.7284241"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000155862107164258549950000007174105641519592081_"
              style={{
                fill: 'url(#A11cone_00000167358737660613973420000007595951494202595731_)',
              }}
              d="
			M33.1855202,129.0648804c-0.3699951-0.8899994-0.5800018-1.8600082-0.5800018-2.890007
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900085L33.1855202,129.0648804z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 10 ? (
          <g id="A61_00000035498655630565547720000003113169033805950858_">
            <linearGradient
              id="A11cone_00000118368268381764626270000012919074639710031246_"
              gradientUnits="userSpaceOnUse"
              x1="242.9080505"
              y1="220.4395905"
              x2="242.9080505"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000155862107164258549950000007174105641519592081_"
              style={{
                fill: 'url(#A11cone_00000118368268381764626270000012919074639710031246_)',
              }}
              d="
			M33.6317444,98.2444992c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900009L33.6317444,98.2444992z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_2_">
        <path
          id="plate_11_"
          className="st6"
          d="M172.1359558,110.5138321h-0.0000153c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802
		V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802h0.0000153
		c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_10_"
          className="st14"
          d="M178.3349304,90.8048477l-0.243988-38.549572
		c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688l0.243988,38.549572
		c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__00000068655531915604490620000001096603031290918062_">
          <path
            className="st15"
            d="M174.2900848,85.500885v0.7182617h-4.0234375c-0.0058594-0.1796875,0.0234375-0.3530273,0.0869141-0.519043
			c0.1025391-0.2739258,0.2666016-0.5439453,0.4921875-0.8095703c0.2255859-0.2661133,0.5517578-0.5732422,0.9775391-0.921875
			c0.6621094-0.5424805,1.1083984-0.972168,1.3408203-1.2895508c0.2333984-0.3168945,0.3496094-0.6162109,0.3496094-0.8989258
			c0-0.2958984-0.1064453-0.5458984-0.3183594-0.7495117c-0.2109375-0.203125-0.4873047-0.3051758-0.828125-0.3051758
			c-0.359375,0-0.6474609,0.1079102-0.8632813,0.3242188c-0.2158203,0.2158203-0.3251953,0.5146484-0.328125,0.8964844
			l-0.7685547-0.0786133c0.0527344-0.5732422,0.2509766-1.0097656,0.59375-1.3100586
			c0.34375-0.300293,0.8046875-0.4506836,1.3828125-0.4506836c0.5839844,0,1.046875,0.1621094,1.3867188,0.4858398
			c0.3408203,0.3237305,0.5107422,0.7255859,0.5107422,1.2041016c0,0.2436523-0.0498047,0.4833984-0.1494141,0.7182617
			c-0.0996094,0.2353516-0.2646484,0.4833984-0.4960938,0.7436523s-0.6152344,0.6171875-1.1523438,1.0712891
			c-0.4482422,0.3764648-0.7363281,0.6318359-0.8632813,0.7661133c-0.1279297,0.1342773-0.2324219,0.269043-0.3154297,0.4047852
			H174.2900848z"
          />
        </g>
        <path
          id="up_9_"
          className="st16"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_9_"
          className="st16"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st6"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st6"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  5: [37.7855263, 66.627388],
  6: [62.2647095, 14.9237556],
  7: [103.8317642, 14.6850052],
  8: [127.7467651, 64.338829],
  9: [37.7855263, 126.2948761],
  10: [38.2317505, 95.4745026],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    font-family: 'ArialMT';
  }
  .st8 {
    font-size: 5.6692901px;
  }
  .st9 {
    display: none;
  }
  .st10 {
    display: inline;
    fill: #3f3f3c;
  }
  .st11 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st12 {
    font-size: 8.5039396px;
  }
  .st13 {
    display: inline;
  }
  .st14 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #d3d2cf;
  }
  .st16 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: url(#A11cone_00000008120256124071874400000010034805335755051939_);
  }
  .st18 {
    fill: #c65805;
  }
  .st19 {
    fill: url(#A11cone_00000135652548252759222030000008744670581868138123_);
  }
  .st20 {
    fill: url(#A11cone_00000111163287796974182240000004788516557161928070_);
  }
  .st21 {
    fill: url(#A11cone_00000121260040955275705670000004581406506079436711_);
  }
  .st22 {
    fill: url(#A11cone_00000137843019401434487470000008553635876691190939_);
  }
  .st23 {
    fill: url(#A11cone_00000030449797352811660540000010238556964741274023_);
  }

  #A6,
  #A7,
  #A8,
  #A9,
  #A10,
  #B36,
  #B37,
  #B38,
  #C59,
  #C60,
  #C61,
  #C62,
  #C63,
  #C64 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-7 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
