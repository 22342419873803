import styled from 'styled-components'

export default function YITLogo() {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.83 233.9">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M394.48,233.9H494.73V0H394.48Zm-194-156.85L188,62.34,241.38,0H353.84L255.09,113.21c-8.47,9.72-16,17-19.7,24.44C231.9,121.94,224.67,105.48,200.48,77.05ZM127.67,233.9V180c0-22.44-4.24-35.16-21.19-55.11L0,0H113.46l71.81,84c23.44,27.68,38.9,54.61,38.9,87.53V233.9ZM753.81,0c-64.83-.25-106,51.12-106,109.22V233.9h96.5V125.43c0-19.45,8.48-29.18,27.43-29.18h70.07V0ZM680.75,9c-26.93,17-47.63,54.36-47.63,88.77-2.49-1.25-7.73-1.5-13.71-1.5H550.33V0h89.52C654.07,0,667.28,2.49,680.75,9ZM394.48,233.9H494.73V0H394.48Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const SVG = styled.svg`
  width: 85px;

  .cls-1 {
    fill: #00aeef;
  }
`
