export const FLOOR_1_DATA = [
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 1,
    sceneName: 'scene_A001',
    sceneName_future: 'scene_A001_future',
    name: 'A01',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 10,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 2,
    sceneName: 'scene_A002',
    sceneName_future: 'scene_A002_future',
    name: 'A02',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 5,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 3,
    sceneName: 'scene_A003',
    sceneName_future: 'scene_A003_future',
    name: 'A03',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      2: 6,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 4,
    sceneName: 'scene_A004',
    sceneName_future: 'scene_A004_future',
    name: 'A04',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      2: 7,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 61,
    sceneName: 'scene_A061',
    sceneName_future: 'scene_A061_future',
    name: 'A05',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      2: 6,
    },
  },
]
