export const FLOOR_4_DATA = [
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 20,
    sceneName: 'scene_A020',
    sceneName_future: 'scene_A020_future',
    name: 'A20',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      3: 11,
      5: 29,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 21,
    sceneName: 'scene_A021',
    sceneName_future: 'scene_A021_future',
    name: 'A21',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      3: 12,
      5: 30,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 22,
    sceneName: 'scene_A022',
    sceneName_future: 'scene_A022_future',
    name: 'A22',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      3: 13,
      5: 31,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 23,
    sceneName: 'scene_A023',
    sceneName_future: 'scene_A023_future',
    name: 'A23',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      3: 14,
      5: 32,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 24,
    sceneName: 'scene_A024',
    sceneName_future: 'scene_A024_future',
    name: 'A24',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      3: 15,
      5: 33,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 25,
    sceneName: 'scene_A025',
    sceneName_future: 'scene_A025_future',
    name: 'A25',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      3: 16,
      5: 34,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 26,
    sceneName: 'scene_A026',
    sceneName_future: 'scene_A026_future',
    name: 'A26',
    lookAt: '-189, 0, 120',
    face: 'south',

    relatedApartments: {
      3: 17,
      5: 35,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 27,
    sceneName: 'scene_A027',
    sceneName_future: 'scene_A027_future',
    name: 'A27',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      3: 18,
      5: 36,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 28,
    sceneName: 'scene_A028',
    sceneName_future: 'scene_A028_future',
    name: 'A28',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      3: 19,
      5: 37,
    },
  },
]
