import * as React from 'react'
import styled from 'styled-components'

export default function Floor8({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_00000043437290443096465390000017388535084949173646_">
        <g id="static_00000183232049580272824950000000300083558226855340_">
          <rect
            id="plate_00000075841762760085457130000000375704970211240866_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments_00000059296754809199769170000001784730401276248501_">
        <g
          onClick={() => changeView(8, 56)}
          onMouseOver={() => setElementOnHover('A56')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A56"
            className={`st1 ${
              sceneName === 'scene_A056'
                ? 'active-apt'
                : elementOnHover === 'A56'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301
		42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 57)}
          onMouseOver={() => setElementOnHover('A57')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A57"
            className={`st1 ${
              sceneName === 'scene_A057'
                ? 'active-apt'
                : elementOnHover === 'A57'
                ? 'on-hover'
                : ''
            }`}
            points="41.2536697,10.6093349 42.2604675,55.7983246 77.8867569,55.7983246 77.8867569,45.5375061
		85.0117569,45.7250023 85.0117569,10.5916872 	"
          />
        </g>
        <g
          onClick={() => changeView(8, `${58}_north`)}
          onMouseOver={() => setElementOnHover('A58')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A58_00000062178755667116846350000011792703342169866633_"
            className={`st1 ${
              sceneName === 'scene_A058_north' ||
              sceneName === 'scene_A058_east'
                ? 'active-apt'
                : elementOnHover === 'A58'
                ? 'on-hover'
                : ''
            }`}
            points="94.387 65.163 94.387 72.163 85.012 72.163 85.012 10.609 123.406 10.609 123.406 57.725 132.074 57.725 132.074 70.6 122.824 70.6 123.115 65.413"
          />
        </g>
        <g
          onClick={() => changeView(8, `${58}_east`)}
          onMouseOver={() => setElementOnHover('A58')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A58_00000062178755667116846350000011792703342169866633_"
            className={`st1 ${
              sceneName === 'scene_A058_east'
                ? 'active-apt'
                : elementOnHover === 'A58'
                ? 'on-hover'
                : ''
            }`}
            points="122.824 70.6 122.824 57.725 132.074 57.725 132.074 70.6"
          />
        </g>
        <g
          onClick={() => changeView(8, 59)}
          onMouseOver={() => setElementOnHover('A59')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A59"
            className={`st1 ${
              sceneName === 'scene_A059'
                ? 'active-apt'
                : elementOnHover === 'A59'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,106.7914276 85.0117569,106.7914276 85.0117569,79.9125061
		94.3867569,79.9125061 94.3867569,65.1625061 123.1149292,65.4125061 122.8242569,83.7250061 132.0742645,83.7250061
		132.0742645,96.3031311 122.8242569,96.3031311 	"
          />
        </g>
        <g
          onClick={() => changeView(8, 60)}
          onMouseOver={() => setElementOnHover('A60')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A60"
            className={`st1 ${
              sceneName === 'scene_A060'
                ? 'active-apt'
                : elementOnHover === 'A60'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061 70.8242493,74.2875061 70.8242569,90.3806229 77.4492569,90.3806229
		77.4492569,106.7914276 42.8933792,107.0390778 42.750164,101.9393234 33.9492569,101.9393234 33.9492569,89.0643234
		42.750164,88.9417953 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000034809812919616017680000005536113126202814646_">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,106.7914276 42.8242569,106.7914276 	"
        />
        <polyline
          className="st3"
          points="41.2536697,19.9926529 41.2536697,10.6093349 123.4056015,10.6093349 123.4056015,19.4634018 	"
        />
        <polyline
          className="st3"
          points="122.8242569,57.7250061 132.0742645,57.7250061 132.0742645,70.6000061 122.8242569,70.6000061 	"
        />
        <polyline
          className="st3"
          points="42.4986992,101.9198456 33.9492569,101.9198456 33.9492569,89.0065994 42.750164,89.0065994 	"
        />
        <polyline
          className="st3"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,10.5916872 85.0117569,45.7250023 85.0117569,106.7914276 	"
        />
        <polyline
          className="st4"
          points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,45.5375061 85.0117569,45.5375061
		77.8867569,45.5375061 77.8867569,106.7914276 	"
        />
        <line
          className="st4"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st4"
          x1="42.5387955"
          y1="106.7914276"
          x2="77.8867569"
          y2="106.7914276"
        />
        <polyline
          className="st3"
          points="122.8242569,96.4750061 132.0742645,96.4750061 132.0742645,83.7250061 122.8242569,83.7250061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.0375137 94.3867569,65.2875061
		122.8242569,65.2875061 	"
        />
        <polyline
          className="st4"
          points="70.8242569,73.9698181 70.8242569,90.3806229 77.8867569,90.3806229 	"
        />
        <line
          className="st4"
          x1="85.3867569"
          y1="72.1625061"
          x2="94.3867569"
          y2="72.1625061"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A056'
              ? 'white-text'
              : elementOnHover === 'A56'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, 56)}
          onMouseOver={() => setElementOnHover('A56')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M53.80228,66.53751l1.5586-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H54.8184l-.4458,1.2295Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.1973,5.1973,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-6"
            d="M58.11088,65.474l.523-.04395a.93814.93814,0,0,0,.27.57422.73.73,0,0,0,.51074.19239.79525.79525,0,0,0,.60889-.27051,1.02225,1.02225,0,0,0,.24951-.71973.92424.92424,0,0,0-.23975-.67285.83625.83625,0,0,0-.627-.24707.87564.87564,0,0,0-.43457.10937.85061.85061,0,0,0-.30469.28418l-.46777-.06054.39306-2.085h2.01807v.47656H58.99125L58.7725,64.102a1.31949,1.31949,0,0,1,.7666-.25488,1.21223,1.21223,0,0,1,.897.36816,1.28482,1.28482,0,0,1,.36524.94629,1.4786,1.4786,0,0,1-.3208.95215,1.28544,1.28544,0,0,1-1.06592.49316,1.31126,1.31126,0,0,1-.90381-.31054A1.20809,1.20809,0,0,1,58.11088,65.474Z"
          />
          <path
            className="cls-6"
            d="M64.13285,63.47305l-.4956.03907a.9416.9416,0,0,0-.188-.42676.66117.66117,0,0,0-.49854-.21289.68312.68312,0,0,0-.418.13281,1.1379,1.1379,0,0,0-.37109.501,2.62519,2.62519,0,0,0-.14111.93847,1.15767,1.15767,0,0,1,.44043-.40722,1.19115,1.19115,0,0,1,.54541-.13282,1.12746,1.12746,0,0,1,.84814.36719,1.31613,1.31613,0,0,1,.3501.94824,1.5614,1.5614,0,0,1-.16455.71,1.19879,1.19879,0,0,1-.45264.502,1.23646,1.23646,0,0,1-.65332.1748,1.2749,1.2749,0,0,1-1.01562-.458,2.313,2.313,0,0,1-.39307-1.51074,2.70142,2.70142,0,0,1,.43457-1.71,1.248,1.248,0,0,1,1.02148-.46582,1.0869,1.0869,0,0,1,1.15137,1.01074Zm-2.03467,1.75a1.15585,1.15585,0,0,0,.10938.49219.81187.81187,0,0,0,.30566.3584.76656.76656,0,0,0,.4126.12305.70469.70469,0,0,0,.54248-.25391,1.00266,1.00266,0,0,0,.227-.69238.93839.93839,0,0,0-.22412-.66309.73793.73793,0,0,0-.56445-.24219.76958.76958,0,0,0-.57325.24219A.87315.87315,0,0,0,62.09818,65.22305Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A057'
              ? 'white-text'
              : elementOnHover === 'A57'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, 57)}
          onMouseOver={() => setElementOnHover('A57')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M53.80228,38.79971l1.5586-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H54.8184l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.2876-.84179a5.19764,5.19764,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-6"
            d="M58.11088,37.73624l.523-.044a.93817.93817,0,0,0,.27.57422.72994.72994,0,0,0,.51074.19238.79524.79524,0,0,0,.60889-.2705,1.02226,1.02226,0,0,0,.24951-.71973.92424.92424,0,0,0-.23975-.67285.83621.83621,0,0,0-.627-.24707.87564.87564,0,0,0-.43457.10937.85052.85052,0,0,0-.30469.28418l-.46777-.06055.39306-2.085h2.01807v.47657H58.99125l-.21875,1.09082a1.31943,1.31943,0,0,1,.7666-.25489,1.2122,1.2122,0,0,1,.897.36817,1.28479,1.28479,0,0,1,.36524.94629,1.47862,1.47862,0,0,1-.3208.95215,1.28544,1.28544,0,0,1-1.06592.49316,1.31127,1.31127,0,0,1-.90381-.31055A1.20807,1.20807,0,0,1,58.11088,37.73624Z"
          />
          <path
            className="cls-6"
            d="M61.58061,35.27335v-.4795h2.627v.3877a5.53506,5.53506,0,0,0-.76807,1.09668,6.65,6.65,0,0,0-.58838,1.40625,5.22711,5.22711,0,0,0-.19092,1.11523H62.148a4.9582,4.9582,0,0,1,.18847-1.15722,6.06246,6.06246,0,0,1,.51612-1.30762,5.59147,5.59147,0,0,1,.71533-1.06152Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A058_north' || sceneName === 'scene_A058_east'
              ? 'white-text'
              : elementOnHover === 'A58'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, `${58}_north`)}
          onMouseOver={() => setElementOnHover('A58')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-6"
            d="M103.20707,37.73624l.52295-.044a.93822.93822,0,0,0,.27.57422.72994.72994,0,0,0,.51074.19238.79524.79524,0,0,0,.60889-.2705,1.02226,1.02226,0,0,0,.24951-.71973.92424.92424,0,0,0-.23975-.67285.8362.8362,0,0,0-.62695-.24707.87566.87566,0,0,0-.43457.10937.8506.8506,0,0,0-.30469.28418l-.46777-.06055.39307-2.085h2.01806v.47657h-1.61914l-.21875,1.09082a1.31943,1.31943,0,0,1,.7666-.25489,1.21224,1.21224,0,0,1,.897.36817,1.28482,1.28482,0,0,1,.36523.94629,1.47857,1.47857,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49316,1.31127,1.31127,0,0,1-.90381-.31055A1.20807,1.20807,0,0,1,103.20707,37.73624Z"
          />
          <path
            className="cls-6"
            d="M107.41019,36.59854a.93825.93825,0,0,1-.45947-.32324.84911.84911,0,0,1-.14941-.50391.98621.98621,0,0,1,.31836-.74511,1.34,1.34,0,0,1,1.70263.00683,1.00108,1.00108,0,0,1,.32373.752.82611.82611,0,0,1-.14794.49122.93158.93158,0,0,1-.4502.32226,1.095,1.095,0,0,1,.56885.39356,1.07662,1.07662,0,0,1,.19531.64746,1.16491,1.16491,0,0,1-.36816.875,1.49936,1.49936,0,0,1-1.9375-.001,1.18359,1.18359,0,0,1-.36817-.88769,1.07137,1.07137,0,0,1,.20069-.66309A1.01113,1.01113,0,0,1,107.41019,36.59854Zm-.26025,1.03027a.901.901,0,0,0,.10108.41211.71018.71018,0,0,0,.30029.3086.8756.8756,0,0,0,.4292.10937.80385.80385,0,0,0,.58984-.22949.78692.78692,0,0,0,.23242-.584.79843.79843,0,0,0-.23925-.59473.8202.8202,0,0,0-.59961-.23535.79284.79284,0,0,0-.814.81347Zm.16065-1.874a.63382.63382,0,0,0,.18554.4707.65551.65551,0,0,0,.48145.18262.64313.64313,0,0,0,.47217-.18164.5999.5999,0,0,0,.18408-.44433.62211.62211,0,0,0-.18946-.46094.69748.69748,0,0,0-.94531-.00391A.58885.58885,0,0,0,107.31059,35.75479Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A059'
              ? 'white-text'
              : elementOnHover === 'A59'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, 59)}
          onMouseOver={() => setElementOnHover('A59')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M98.89848,92.02872l1.55859-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H99.91459l-.4458,1.2295Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-6"
            d="M103.20707,90.96524l.52295-.04394a.93819.93819,0,0,0,.27.57421.73.73,0,0,0,.51074.19239.79525.79525,0,0,0,.60889-.27051,1.02225,1.02225,0,0,0,.24951-.71973.92424.92424,0,0,0-.23975-.67285.83624.83624,0,0,0-.62695-.24707.87556.87556,0,0,0-.43457.10938.85051.85051,0,0,0-.30469.28418l-.46777-.06055.39307-2.085h2.01806v.47656h-1.61914l-.21875,1.09082a1.31949,1.31949,0,0,1,.7666-.25488,1.21227,1.21227,0,0,1,.897.36816,1.28485,1.28485,0,0,1,.36523.94629,1.47855,1.47855,0,0,1-.3208.95215,1.28543,1.28543,0,0,1-1.06592.49316,1.31126,1.31126,0,0,1-.90381-.31054A1.20809,1.20809,0,0,1,103.20707,90.96524Z"
          />
          <path
            className="cls-6"
            d="M106.7183,91.09024l.479-.04394a.82913.82913,0,0,0,.23243.48925.638.638,0,0,0,.43994.15235.76634.76634,0,0,0,.40283-.1045.86265.86265,0,0,0,.28369-.28125,1.65339,1.65339,0,0,0,.18555-.4746,2.5033,2.5033,0,0,0,.0747-.60938c0-.02246-.001-.05566-.00244-.09961a1.14458,1.14458,0,0,1-.40869.38672,1.10949,1.10949,0,0,1-.56054.14746,1.13454,1.13454,0,0,1-.85254-.36523,1.336,1.336,0,0,1-.34864-.96289,1.36905,1.36905,0,0,1,.36377-.99415,1.21685,1.21685,0,0,1,.91211-.377,1.29827,1.29827,0,0,1,.72412.21387,1.32783,1.32783,0,0,1,.498.60742,2.98489,2.98489,0,0,1,.17041,1.1416,3.69474,3.69474,0,0,1-.16894,1.23926,1.44687,1.44687,0,0,1-.50245.70117,1.29593,1.29593,0,0,1-.78173.24121,1.07273,1.07273,0,0,1-1.14063-1.00781Zm2.04-1.791a.97634.97634,0,0,0-.228-.68066.71619.71619,0,0,0-.5498-.252.75712.75712,0,0,0-.57862.27148,1.00553,1.00553,0,0,0-.24609.70313.86557.86557,0,0,0,.23389.6289.76767.76767,0,0,0,.57715.24219.73667.73667,0,0,0,.56884-.24219A.95281.95281,0,0,0,108.75834,89.29922Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A060'
              ? 'white-text'
              : elementOnHover === 'A60'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, 60)}
          onMouseOver={() => setElementOnHover('A60')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M53.8018,91.99893l1.55859-4.05859H55.939l1.66065,4.05859h-.61182l-.47314-1.22949H54.81791l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-6"
            d="M60.69584,88.93448l-.49561.03906a.94155.94155,0,0,0-.188-.42676.66119.66119,0,0,0-.49854-.21289.68312.68312,0,0,0-.418.13282,1.13779,1.13779,0,0,0-.37109.501,2.62548,2.62548,0,0,0-.14112.93848,1.15784,1.15784,0,0,1,.44043-.40723,1.19114,1.19114,0,0,1,.54541-.13281,1.12747,1.12747,0,0,1,.84815.36719,1.31612,1.31612,0,0,1,.3501.94824,1.5614,1.5614,0,0,1-.16455.71,1.19883,1.19883,0,0,1-.45264.502,1.2365,1.2365,0,0,1-.65332.17481,1.275,1.275,0,0,1-1.01563-.458,2.3131,2.3131,0,0,1-.39306-1.51074,2.70142,2.70142,0,0,1,.43457-1.71,1.248,1.248,0,0,1,1.02148-.46582,1.08688,1.08688,0,0,1,1.15137,1.01074Zm-2.03467,1.75a1.15585,1.15585,0,0,0,.10938.49219.8119.8119,0,0,0,.30566.35839.76648.76648,0,0,0,.4126.123.70468.70468,0,0,0,.54248-.2539,1.0027,1.0027,0,0,0,.227-.69239.93838.93838,0,0,0-.22412-.66308.73791.73791,0,0,0-.56445-.24219.76954.76954,0,0,0-.57325.24219A.8731.8731,0,0,0,58.66117,90.68448Z"
          />
          <path
            className="cls-6"
            d="M61.54691,89.998a3.721,3.721,0,0,1,.148-1.15918,1.44451,1.44451,0,0,1,.44043-.67676,1.12693,1.12693,0,0,1,.73487-.23828,1.19487,1.19487,0,0,1,.57275.13183,1.09886,1.09886,0,0,1,.40723.37891,2.06034,2.06034,0,0,1,.252.60351,4.03606,4.03606,0,0,1,.09131.96,3.709,3.709,0,0,1-.147,1.15234,1.44461,1.44461,0,0,1-.43848.67871,1.12849,1.12849,0,0,1-.73779.23926,1.11384,1.11384,0,0,1-.92188-.4209A2.6742,2.6742,0,0,1,61.54691,89.998Zm.51221,0A2.54215,2.54215,0,0,0,62.293,91.328a.66861.66861,0,0,0,1.15429-.00195,3.89887,3.89887,0,0,0,0-2.66016.694.694,0,0,0-.583-.3291.639.639,0,0,0-.54786.29A2.575,2.575,0,0,0,62.05912,89.998Z"
          />
        </g>
      </g>
      <g
        id="state_00000174592292822661413310000006516419108657300119_"
        className="st8"
      >
        <path
          id="plate_00000120520702801398346500000005609378499872026507_"
          className="st9"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />

        <text
          id="_x33__00000109708249062504413440000015770396609778578108_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st9 st10 st11"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st9 st10 st11"
        >
          2021
        </text>
        <g className="st12">
          <path
            className="st5"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st5"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st5"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st5"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g
          id="_x33__00000127045452648852253730000014942618971556579484_"
          className="st12"
        >
          <path
            className="st5"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st5"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st5"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st5"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g
          id="down_00000129925844372042021410000010816674126863248792_"
          className="st12"
        >
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g
          id="up_00000054943993165942081640000008856949021129890235_"
          className="st12"
        >
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 56 ? (
          <g id="A56">
            <linearGradient
              id="A11cone_00000038381365204733844820000017723854033136703140_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000013904883225026078930000015321096160211963026_"
              style={{
                fill: 'url(#A11cone_00000038381365204733844820000017723854033136703140_)',
              }}
              d="
			M33.1855202,69.3973846c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st17"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 57 ? (
          <g id="A57">
            <linearGradient
              id="A11cone_00000097493579230669789970000008911545517078456481_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone_00000097493579230669789970000008911545517078456481_"
              style={{
                fill: 'url(#A11cone_00000097493579230669789970000008911545517078456481_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st17"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === `${58}_north` ? (
          <g id="A58_north">
            <linearGradient
              id="A11cone_00000143582587126029173900000014284417649479667097_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000143582587126029173900000014284417649479667097_"
              style={{
                fill: 'url(#A11cone_00000143582587126029173900000014284417649479667097_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st17"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === `${58}_east` ? (
          <g id="A58_east">
            <linearGradient
              id="A11cone_00000023998851013228662790000014767995468078781087_"
              gradientUnits="userSpaceOnUse"
              x1="211.7723846"
              y1="309.9545898"
              x2="211.7723846"
              y2="265.0445862"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000023998851013228662790000014767995468078781087_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000023998851013228662790000014767995468078781087_)',
              }}
              d="
			M123.146759,67.1088257c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.2567596,41.838829V85.62883L123.146759,67.1088257z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st17"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 59 ? (
          <g id="A59">
            <linearGradient
              id="A11cone_00000106127213891054714850000016557141265714018473_"
              gradientUnits="userSpaceOnUse"
              x1="237.6501465"
              y1="309.7736206"
              x2="237.6501465"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000106127213891054714850000016557141265714018473_"
              style={{
                fill: 'url(#A11cone_00000106127213891054714850000016557141265714018473_)',
              }}
              d="
			M122.9657669,92.9865952c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,67.7165985v43.7900009L122.9657669,92.9865952z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st17"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 60 ? (
          <g id="A60">
            <linearGradient
              id="A11cone_00000119824735070666561060000018379517538251560335_"
              gradientUnits="userSpaceOnUse"
              x1="242.9080505"
              y1="220.4395905"
              x2="242.9080505"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000119824735070666561060000018379517538251560335_"
              style={{
                fill: 'url(#A11cone_00000119824735070666561060000018379517538251560335_)',
              }}
              d="
			M33.6317444,98.2444992c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900009L33.6317444,98.2444992z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st17"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevatorz">
        <path
          id="plate_00000060752880666010865320000017445495829014813078_"
          className="st5"
          d="M172.1359558,110.5138321h-0.0000153
		c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000153c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_00000132066504361918250930000015209979343773839248_"
          className="st13"
          d="M178.3349304,90.8048477
		l-0.243988-38.549572c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688
		l0.243988,38.549572c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915
		C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__00000023249282240392610050000016269934754426648502_">
          <path
            className="st14"
            d="M171.5117645,82.9178772c-0.3095703-0.1132813-0.5400391-0.2753906-0.6894531-0.4858398
			c-0.1494141-0.2099609-0.2236328-0.4619141-0.2236328-0.7553711c0-0.4428711,0.1591797-0.8154297,0.4775391-1.1171875
			s0.7412109-0.4526367,1.2705078-0.4526367c0.53125,0,0.9589844,0.1542969,1.2832031,0.4628906
			c0.3232422,0.309082,0.4853516,0.6845703,0.4853516,1.1274414c0,0.2822266-0.0742188,0.5283203-0.2216797,0.7373047
			c-0.1484375,0.2089844-0.3730469,0.3701172-0.6748047,0.4833984c0.3730469,0.1220703,0.6582031,0.3183594,0.8535156,0.5898438
			c0.1943359,0.2714844,0.2919922,0.5952148,0.2919922,0.9716797c0,0.5205078-0.1835938,0.9575195-0.5517578,1.3120117
			s-0.8525391,0.5317383-1.453125,0.5317383c-0.6015625,0-1.0859375-0.1782227-1.4541016-0.5336914
			c-0.3681641-0.355957-0.5517578-0.7993164-0.5517578-1.3310547c0-0.3955078,0.1005859-0.7270508,0.3007813-0.9941406
			C170.8555145,83.1971741,171.1406708,83.0150452,171.5117645,82.9178772z M171.1221161,84.4627991
			c0,0.2128906,0.0498047,0.4194336,0.1513672,0.6186523c0.1005859,0.1992188,0.2509766,0.3535156,0.4501953,0.4628906
			s0.4140625,0.1640625,0.6435547,0.1640625c0.3574219,0,0.6523438-0.1147461,0.8847656-0.3447266
			c0.2324219-0.2294922,0.3486328-0.5214844,0.3486328-0.8759766c0-0.3598633-0.1201172-0.6577148-0.359375-0.8925781
			c-0.2392578-0.2353516-0.5390625-0.3530273-0.8984375-0.3530273c-0.3515625,0-0.6435547,0.1162109-0.8740234,0.3486328
			C171.2373505,83.8231506,171.1221161,84.1141663,171.1221161,84.4627991z M171.3623505,81.6517639
			c0,0.2875977,0.0927734,0.5229492,0.2783203,0.7055664c0.1855469,0.1831055,0.4267578,0.2744141,0.7226563,0.2744141
			c0.2880859,0,0.5234375-0.0908203,0.7080078-0.2719727c0.1835938-0.1816406,0.2763672-0.4038086,0.2763672-0.6665039
			c0-0.2744141-0.0957031-0.5048828-0.2851563-0.6914063c-0.1894531-0.1870117-0.4257813-0.2802734-0.7080078-0.2802734
			c-0.2851563,0-0.5214844,0.0913086-0.7099609,0.2739258S171.3623505,81.3968811,171.3623505,81.6517639z"
          />
        </g>
        <path
          id="up_00000041282158469008337730000014402396641932582823_"
          className="st15"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_00000106830174805281732220000006154433325184688552_"
          className="st15"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st5"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up">
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st5"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  56: [37.7855263, 66.627388],
  57: [62.2647095, 14.9237556],
  '58_north': [103.8317642, 14.6850052],
  '58_east': [127.7467651, 64.338829],
  59: [127.565773, 90.2165985],
  60: [38.2317505, 95.4745026],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #3f3f3c;
  }
  .st6 {
    font-family: 'ArialMT';
  }
  .st7 {
    font-size: 5.6692901px;
  }
  .st8 {
    display: none;
  }
  .st9 {
    display: inline;
    fill: #3f3f3c;
  }
  .st10 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st11 {
    font-size: 8.5039396px;
  }
  .st12 {
    display: inline;
  }
  .st13 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st14 {
    fill: #d3d2cf;
  }
  .st15 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st16 {
    fill: url(#A11cone_00000042001567154420613490000009481131785229756579_);
  }
  .st17 {
    fill: #c65805;
  }
  .st18 {
    fill: url(#A11cone_00000052062998647616622930000001753371639129986238_);
  }
  .st19 {
    fill: url(#A11cone_00000031174520492510294940000004979733844157162908_);
  }
  .st20 {
    fill: url(#A11cone_00000054258068787082463590000005913612778978127268_);
  }
  .st21 {
    fill: url(#A11cone_00000121987633558076261320000015835475841596483489_);
  }
  .st22 {
    fill: url(#A11cone_00000104676841344544364740000000147761252736591545_);
  }

  #A11,
  #A12,
  #A13,
  #A14,
  #A15,
  #B39,
  #B40,
  #B41,
  #C65,
  #C66,
  #C67,
  #C68,
  #C69,
  #C70 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #up:hover {
    cursor: not-allowed;

    .st0 {
      fill: var(--yit-grey);
    }

    .st5 {
      fill: var(--yit-white);
    }
  }

  #down:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st5 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-6 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
