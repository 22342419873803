export const FLOOR_2_DATA = [
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 5,
    sceneName: 'scene_A005',
    sceneName_future: 'scene_A005_future',
    name: 'A05',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      1: 2,
      3: 11,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 6,
    sceneName: 'scene_A006',
    sceneName_future: 'scene_A006_future',
    name: 'A06',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      1: 61,
      3: 12,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 7,
    sceneName: 'scene_A007',
    sceneName_future: 'scene_A007_future',
    name: 'A07',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      1: 4,
      3: 13,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 8,
    sceneName: 'scene_A008',
    sceneName_future: 'scene_A008_future',
    name: 'A08',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      1: 4,
      3: 14,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 9,
    sceneName: 'scene_A009',
    sceneName_future: 'scene_A009_future',
    name: 'A09',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      1: 1,
      3: 18,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 10,
    sceneName: 'scene_A010',
    sceneName_future: 'scene_A010_future',
    name: 'A10',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      1: 1,
      3: 19,
    },
  },
]
