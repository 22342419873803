export const FLOOR_7_DATA = [
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 47,
    sceneName: 'scene_A047',
    sceneName_future: 'scene_A047_future',
    name: 'A47',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      6: 38,
      8: 56,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 48,
    sceneName: 'scene_A048',
    sceneName_future: 'scene_A048_future',
    name: 'A48',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      6: 39,
      8: 57,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 49,
    sceneName: 'scene_A049',
    sceneName_future: 'scene_A049_future',
    name: 'A49',
    lookAt: '-8, 0, 120',
    face: 'north',

    relatedApartments: {
      6: 40,
      8: 58,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 50,
    sceneName: 'scene_A050',
    sceneName_future: 'scene_A050_future',
    name: 'A50',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      6: 41,
      8: 58,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 51,
    sceneName: 'scene_A051',
    sceneName_future: 'scene_A051_future',
    name: 'A51',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      6: 42,
      8: 59,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 52,
    sceneName: 'scene_A052',
    sceneName_future: 'scene_A052_future',
    name: 'A52',
    lookAt: '81, 0, 120',
    face: 'east',

    relatedApartments: {
      6: 43,
      8: 59,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 53,
    sceneName: 'scene_A053',
    sceneName_future: 'scene_A053_future',
    name: 'A53',
    lookAt: '-189, 0, 120',
    face: 'south',

    relatedApartments: {
      6: 44,
      8: 59,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 54,
    sceneName: 'scene_A054',
    sceneName_future: 'scene_A054_future',
    name: 'A54',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      6: 45,
      8: 60,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 55,
    sceneName: 'scene_A055',
    sceneName_future: 'scene_A055_future',
    name: 'A55',
    lookAt: '-98.5, 0, 120',
    face: 'west',

    relatedApartments: {
      6: 46,
      8: 60,
    },
  },
]
