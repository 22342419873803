import * as React from 'react'
import styled from 'styled-components'

export default function Floor3({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      style={{ enableBackground: 'new 0 0 210.4068146 162' }}
      xmlSpace="preserve"
    >
      <g id="bg_00000083790275358450881470000005034345430719224718_">
        <g id="static_00000114044698350951475360000007648033134264605852_">
          <rect
            id="plate_00000160905630175029346080000005447948830385383590_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(3, 11)}
          onMouseOver={() => setElementOnHover('A11')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A11"
            className={`st1 ${
              sceneName === 'scene_A011'
                ? 'active-apt'
                : elementOnHover === 'A11'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,56.4125061
		77.8867569,56.4125061 77.8867569,73.9698181 42.1680069,73.6521301 42.2604675,72.9745026 33.9492569,72.9745026
		33.9492569,60.1000061 41.8242569,60.1000061 	"
          />
        </g>

        <g
          onClick={() => changeView(3, 12)}
          onMouseOver={() => setElementOnHover('A12')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A12"
            className={`st1 ${
              sceneName === 'scene_A012'
                ? 'active-apt'
                : elementOnHover === 'A12'
                ? 'on-hover'
                : ''
            }`}
            points="41.2536697,10.6093349
		42.2604675,55.7983246 77.8867569,55.7983246 77.8867569,45.5375061 85.0117569,45.7250023 85.0117569,10.5916872 	"
          />
        </g>

        <g
          onClick={() => changeView(3, 13)}
          onMouseOver={() => setElementOnHover('A13')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="A13"
            className={`st1 ${
              sceneName === 'scene_A013'
                ? 'active-apt'
                : elementOnHover === 'A13'
                ? 'on-hover'
                : ''
            }`}
            x="85.0117569"
            y="10.5916872"
            width="38.3938446"
            height="44.0395699"
          />
        </g>

        <g
          onClick={() => changeView(3, 14)}
          onMouseOver={() => setElementOnHover('A14')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A14"
            className={`st1 ${
              sceneName === 'scene_A014'
                ? 'active-apt'
                : elementOnHover === 'A14'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,72.1625061
		85.0117569,72.1625061 85.0117569,54.6312561 122.8242569,54.6312561 122.8242569,57.7250099 132.0742645,57.7250061
		132.0742645,70.6000061 122.8242569,70.6000061 	"
          />
        </g>

        <g
          onClick={() => changeView(3, 15)}
          onMouseOver={() => setElementOnHover('A15')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A15"
            className={`st1 ${
              sceneName === 'scene_A015'
                ? 'active-apt'
                : elementOnHover === 'A15'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,96.6420212
		85.0117569,96.6420212 85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 123.1149292,72.4125061
		122.8242569,83.7250061 132.0742645,83.7250061 132.0742645,96.3031311 122.8242569,96.3031311 	"
          />
        </g>
        <g
          onClick={() => changeView(3, 16)}
          onMouseOver={() => setElementOnHover('A16')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A16"
            className={`st1 ${
              sceneName === 'scene_A016'
                ? 'active-apt'
                : elementOnHover === 'A16'
                ? 'on-hover'
                : ''
            }`}
            points="122.8242569,123.2562561
		103.4805069,123.2562561 103.4805069,113.8500061 85.0117569,114.1732712 85.0117569,96.6420212 122.8242569,96.6420212
		122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.3500061 122.8242569,120.3500061 	"
          />
        </g>
        <g
          onClick={() => changeView(3, 17)}
          onMouseOver={() => setElementOnHover('A17')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A17"
            className={`st1 ${
              sceneName === 'scene_A017'
                ? 'active-apt'
                : elementOnHover === 'A17'
                ? 'on-hover'
                : ''
            }`}
            points="123.3919067,149.6000061
		89.5169067,149.6000061 89.5169067,141.4750061 85.0117569,141.2381592 85.0117569,113.8500061 103.4805069,113.8500061
		103.6507797,123.7069016 122.8242569,123.7069016 	"
          />
        </g>
        <g
          onClick={() => changeView(3, 18)}
          onMouseOver={() => setElementOnHover('A18')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A18"
            className={`st1 ${
              sceneName === 'scene_A018'
                ? 'active-apt'
                : elementOnHover === 'A18'
                ? 'on-hover'
                : ''
            }`}
            points="42.2604675,106.7914276
		77.4492569,106.7914276 77.4492569,141.4750061 43.0117569,141.7875061 42.750164,132.4725342 33.9492569,132.4725342
		33.9492569,119.5975266 42.750164,119.4750061 	"
          />
        </g>
        <g
          onClick={() => changeView(3, 19)}
          onMouseOver={() => setElementOnHover('A19')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A19"
            className={`st1 ${
              sceneName === 'scene_A019'
                ? 'active-apt'
                : elementOnHover === 'A19'
                ? 'on-hover'
                : ''
            }`}
            points="42.5387955,74.2875061
		70.8242493,74.2875061 70.8242569,90.3806229 77.4492569,90.3806229 77.4492569,106.7914276 42.8933792,107.0390778
		42.750164,101.9393234 33.9492569,101.9393234 33.9492569,89.0643234 42.750164,88.9417953 	"
          />
        </g>
      </g>
      <g id="levelfloor_00000111897384741953396150000006136065435910064306_">
        <polygon
          className="st2"
          points="41.8242569,19.9750061 122.8242569,19.9750061 122.8242569,141.4750061 42.8242569,141.4750061 	"
        />
        <polyline
          className="st3"
          points="41.2536697,19.9926529 41.2536697,10.6093349 123.4056015,10.6093349 123.4056015,19.4634018 	"
        />
        <polyline
          className="st3"
          points="122.8242569,57.7250061 132.0742645,57.7250061 132.0742645,70.6000061 122.8242569,70.6000061 	"
        />
        <polyline
          className="st3"
          points="122.8242569,107.4750061 132.0742645,107.4750061 132.0742645,120.2250061 122.8242569,120.2250061
		"
        />
        <polyline
          className="st3"
          points="123.3919067,141.4750061 123.3919067,149.6000061 89.5169067,149.6000061 89.5169067,141.4750061
		"
        />
        <polyline
          className="st3"
          points="42.750164,132.4725342 33.9492569,132.4725342 33.9492569,119.4750061 42.750164,119.4750061 	"
        />
        <polyline
          className="st3"
          points="42.4986992,101.9198456 33.9492569,101.9198456 33.9492569,89.0065994 42.750164,89.0065994 	"
        />
        <polyline
          className="st3"
          points="42.2604675,72.9745026 33.9492569,72.9745026 33.9492569,60.1000061 41.8242569,60.1000061 	"
        />
        <polyline
          className="st4"
          points="85.0117569,10.5916872 85.0117569,45.7250023 85.0117569,141.4750061 	"
        />
        <polyline
          className="st4"
          points="42.2604675,56.4125061 77.8867569,56.4125061 77.8867569,45.5375061 85.0117569,45.5375061
		77.8867569,45.5375061 77.8867569,141.4750061 	"
        />
        <line
          className="st4"
          x1="42.2686577"
          y1="73.9698181"
          x2="77.8867569"
          y2="73.9698181"
        />
        <line
          className="st4"
          x1="42.5387955"
          y1="106.7914276"
          x2="77.8867569"
          y2="106.7914276"
        />
        <polyline
          className="st4"
          points="85.0117569,113.8500061 103.4805069,113.8500061 103.4805069,123.2562561 122.8242569,123.2562561
		"
        />
        <polyline
          className="st3"
          points="122.8242569,96.4750061 132.0742645,96.4750061 132.0742645,83.7250061 122.8242569,83.7250061 	"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="72.1625061"
          x2="122.8242569"
          y2="72.1625061"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="54.6312561"
          x2="122.8242569"
          y2="54.6312561"
        />
        <line
          className="st5"
          x1="85.0117569"
          y1="96.6420212"
          x2="122.2897873"
          y2="96.3031311"
        />
        <polyline
          className="st4"
          points="85.0117569,79.9125061 94.3867569,79.9125061 94.3867569,72.1625061 	"
        />
        <polyline
          className="st4"
          points="70.8242569,73.9698181 70.8242569,90.3806229 77.8867569,90.3806229 	"
        />
      </g>
      <g id="apartment_nr_2_">
        <g
          className={`${
            sceneName === 'scene_A011'
              ? 'white-text'
              : elementOnHover === 'A11'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 11)}
          onMouseOver={() => setElementOnHover('A11')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11381,66.53751l1.55859-4.0586H56.251l1.66064,4.0586h-.61182L56.8267,65.308H55.12992l-.4458,1.2295Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.5127-.2876-.8418a5.1985,5.1985,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M60.29936,66.53751h-.49854V63.3627a2.58961,2.58961,0,0,1-.47168.34277,3.39868,3.39868,0,0,1-.5249.25782v-.48145a3.02752,3.02752,0,0,0,.731-.47656,1.873,1.873,0,0,0,.44288-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M63.315,66.53751h-.49854V63.3627a2.58951,2.58951,0,0,1-.47167.34277,3.39874,3.39874,0,0,1-.52491.25782v-.48145a3.02778,3.02778,0,0,0,.731-.47656,1.8728,1.8728,0,0,0,.44287-.543H63.315Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A012'
              ? 'white-text'
              : elementOnHover === 'A12'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 12)}
          onMouseOver={() => setElementOnHover('A12')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11381,38.79971l1.55859-4.05859H56.251l1.66064,4.05859h-.61182l-.47314-1.22949H55.12992l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.51269-.2876-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M60.29936,38.79971h-.49854v-3.1748a2.59009,2.59009,0,0,1-.47168.34277,3.39763,3.39763,0,0,1-.5249.25781v-.48144a3.02781,3.02781,0,0,0,.731-.47656,1.87315,1.87315,0,0,0,.44288-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M64.47758,38.3212v.47851H61.795a.88283.88283,0,0,1,.05859-.3457,1.81768,1.81768,0,0,1,.32764-.54,5.0924,5.0924,0,0,1,.65186-.61426,5.52524,5.52524,0,0,0,.89453-.86035,1.02734,1.02734,0,0,0,.23242-.59863.66746.66746,0,0,0-.21191-.5.763.763,0,0,0-.55225-.20313.776.776,0,0,0-.57568.21582.8154.8154,0,0,0-.21875.59766l-.51221-.05273a1.25989,1.25989,0,0,1,.396-.873,1.35108,1.35108,0,0,1,.92188-.30078,1.28672,1.28672,0,0,1,.92432.32422,1.063,1.063,0,0,1,.34082.80273,1.21517,1.21517,0,0,1-.09961.47852,1.85656,1.85656,0,0,1-.33106.49609,8.003,8.003,0,0,1-.76806.71387c-.29883.251-.49121.42187-.57569.51074a1.6264,1.6264,0,0,0-.21045.27051Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A013'
              ? 'white-text'
              : elementOnHover === 'A13'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 13)}
          onMouseOver={() => setElementOnHover('A13')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,38.79971l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M105.084,38.79971h-.49853v-3.1748a2.59044,2.59044,0,0,1-.47168.34277,3.39807,3.39807,0,0,1-.5249.25781v-.48144a3.028,3.028,0,0,0,.731-.47656,1.87293,1.87293,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M106.64652,37.72842l.49805-.0664a1.101,1.101,0,0,0,.292.61035.71991.71991,0,0,0,.50244.18652.806.806,0,0,0,.59375-.24316.82054.82054,0,0,0,.24219-.60352.76565.76565,0,0,0-.22412-.5664.775.775,0,0,0-.57031-.22266,1.42229,1.42229,0,0,0-.35157.05566l.05567-.4375a.71276.71276,0,0,0,.08008.00586,1.01992,1.01992,0,0,0,.57275-.167.5675.5675,0,0,0,.25488-.51172.607.607,0,0,0-.18554-.4541.658.658,0,0,0-.479-.17969.67828.67828,0,0,0-.48437.18262.92118.92118,0,0,0-.249.54785l-.498-.08789a1.28428,1.28428,0,0,1,.415-.77734,1.2002,1.2002,0,0,1,.80566-.27539,1.33238,1.33238,0,0,1,.61182.14258,1.03657,1.03657,0,0,1,.42773.38964.9969.9969,0,0,1,.14795.52247.8578.8578,0,0,1-.14111.47949.96679.96679,0,0,1-.418.34277.94614.94614,0,0,1,.55908.34473,1.05061,1.05061,0,0,1,.19922.65527,1.19758,1.19758,0,0,1-.3877.90039,1.36036,1.36036,0,0,1-.98.37012,1.27678,1.27678,0,0,1-.8872-.31836A1.23406,1.23406,0,0,1,106.64652,37.72842Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A014'
              ? 'white-text'
              : elementOnHover === 'A14'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 14)}
          onMouseOver={() => setElementOnHover('A14')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.899,65.57364l1.5586-4.05859h.57861l1.66065,4.05859H102.085l-.47314-1.22949H99.91508l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.5127-.2876-.8418a5.197,5.197,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M105.08451,65.57364H104.586V62.39883a2.59044,2.59044,0,0,1-.47168.34278,3.3986,3.3986,0,0,1-.52491.25781V62.518a3.02728,3.02728,0,0,0,.731-.47656,1.8728,1.8728,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M108.24125,65.57364V64.602h-1.76074v-.457l1.85205-2.62988h.40722v2.62988h.54786v.457h-.54786v.97168Zm0-1.42871v-1.8291l-1.27051,1.8291Z"
          />
        </g>

        <g
          className={`${
            sceneName === 'scene_A015'
              ? 'white-text'
              : elementOnHover === 'A15'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 15)}
          onMouseOver={() => setElementOnHover('A15')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,86.21915l1.55859-4.0586h.57861l1.66065,4.0586h-.61182l-.47314-1.2295H99.91459l-.4458,1.2295Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.5127-.28759-.8418a5.19936,5.19936,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M105.084,86.21915h-.49853V83.04434a2.59044,2.59044,0,0,1-.47168.34278,3.39989,3.39989,0,0,1-.5249.25781v-.48145a3.02771,3.02771,0,0,0,.731-.47656,1.8728,1.8728,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M106.64359,85.15567l.52295-.04395a.93825.93825,0,0,0,.27.57422.73.73,0,0,0,.51074.19239.79523.79523,0,0,0,.60889-.27051,1.02225,1.02225,0,0,0,.24951-.71973.92428.92428,0,0,0-.23974-.67285.83627.83627,0,0,0-.627-.24707.87559.87559,0,0,0-.43457.10938.85046.85046,0,0,0-.30468.28417l-.46778-.06054.39307-2.085h2.01807v.47656H107.524l-.21875,1.09082a1.31954,1.31954,0,0,1,.76661-.25488,1.21223,1.21223,0,0,1,.897.36816,1.28485,1.28485,0,0,1,.36523.94629,1.47855,1.47855,0,0,1-.3208.95215,1.28542,1.28542,0,0,1-1.06592.49316,1.31122,1.31122,0,0,1-.9038-.31054A1.20814,1.20814,0,0,1,106.64359,85.15567Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A016'
              ? 'white-text'
              : elementOnHover === 'A16'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 16)}
          onMouseOver={() => setElementOnHover('A16')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.898,108.4252l1.55859-4.05859h.57861l1.66065,4.05859H102.084l-.47314-1.22949H99.9141l-.4458,1.22949Zm1.1709-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M105.08353,108.4252H104.585v-3.1748a2.59056,2.59056,0,0,1-.47168.34277,3.39763,3.39763,0,0,1-.5249.25781v-.48144a3.02754,3.02754,0,0,0,.731-.47657,1.873,1.873,0,0,0,.44288-.543h.32128Z"
          />
          <path
            className="cls-7"
            d="M109.22855,105.36075l-.4956.03906a.94144.94144,0,0,0-.188-.42676.66117.66117,0,0,0-.49853-.21289.68314.68314,0,0,0-.418.13281,1.13784,1.13784,0,0,0-.3711.501,2.62552,2.62552,0,0,0-.14111.93848,1.15777,1.15777,0,0,1,.44043-.40723,1.19114,1.19114,0,0,1,.54541-.13281,1.12745,1.12745,0,0,1,.84814.36719,1.31612,1.31612,0,0,1,.3501.94824,1.56143,1.56143,0,0,1-.16455.71,1.1987,1.1987,0,0,1-.45264.502,1.23645,1.23645,0,0,1-.65332.17481,1.275,1.275,0,0,1-1.01562-.458,2.313,2.313,0,0,1-.39307-1.51074,2.70142,2.70142,0,0,1,.43457-1.71,1.24805,1.24805,0,0,1,1.02149-.46582,1.08686,1.08686,0,0,1,1.15136,1.01074Zm-2.03466,1.75a1.156,1.156,0,0,0,.10937.49219.812.812,0,0,0,.30567.35839.76642.76642,0,0,0,.41259.123.70465.70465,0,0,0,.54248-.25391,1.00266,1.00266,0,0,0,.22705-.69238.93834.93834,0,0,0-.22412-.66308.7379.7379,0,0,0-.56445-.24219.76952.76952,0,0,0-.57324.24219A.8731.8731,0,0,0,107.19389,107.11075Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A017'
              ? 'white-text'
              : elementOnHover === 'A17'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 17)}
          onMouseOver={() => setElementOnHover('A17')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M98.89848,134.1752l1.55859-4.05859h.57861l1.66065,4.05859h-.61182l-.47314-1.22949H99.91459l-.4458,1.22949Zm1.17089-1.666h1.376l-.42383-1.124q-.19335-.51269-.28759-.84179a5.2,5.2,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M105.084,134.1752h-.49853v-3.1748a2.59091,2.59091,0,0,1-.47168.34277,3.39807,3.39807,0,0,1-.5249.25781v-.48144a3.02774,3.02774,0,0,0,.731-.47657,1.87278,1.87278,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M106.6768,130.64883v-.47949h2.62695v.3877a5.53506,5.53506,0,0,0-.76807,1.09668,6.65047,6.65047,0,0,0-.58838,1.40625,5.22628,5.22628,0,0,0-.19091,1.11523h-.51221a4.95782,4.95782,0,0,1,.18848-1.15723,6.062,6.062,0,0,1,.51611-1.30761,5.59157,5.59157,0,0,1,.71533-1.06153Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A018'
              ? 'white-text'
              : elementOnHover === 'A18'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 18)}
          onMouseOver={() => setElementOnHover('A18')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11332,125.974l1.55859-4.05859h.57862l1.66064,4.05859h-.61181l-.47315-1.22949H55.12943l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.5127-.2876-.8418a5.19816,5.19816,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M60.29887,125.974h-.49854v-3.17481a2.59009,2.59009,0,0,1-.47168.34278,3.39854,3.39854,0,0,1-.5249.25781v-.48144a3.0278,3.0278,0,0,0,.731-.47657,1.873,1.873,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M62.625,123.77286a.93838.93838,0,0,1-.45947-.32324.84914.84914,0,0,1-.14942-.50391.98623.98623,0,0,1,.31836-.74512,1.34,1.34,0,0,1,1.70264.00684,1.00106,1.00106,0,0,1,.32373.75195.82606.82606,0,0,1-.14795.49121.93149.93149,0,0,1-.4502.32227,1.095,1.095,0,0,1,.56885.39355,1.07666,1.07666,0,0,1,.19531.64746,1.16485,1.16485,0,0,1-.36816.875,1.49935,1.49935,0,0,1-1.9375-.001,1.18362,1.18362,0,0,1-.36816-.8877,1.07135,1.07135,0,0,1,.20068-.66308A1.01119,1.01119,0,0,1,62.625,123.77286Zm-.26026,1.03027a.9012.9012,0,0,0,.10108.41211.71015.71015,0,0,0,.30029.30859.8755.8755,0,0,0,.4292.10938.80385.80385,0,0,0,.58984-.22949.78694.78694,0,0,0,.23243-.584.79839.79839,0,0,0-.23926-.59472.82019.82019,0,0,0-.59961-.23536.79286.79286,0,0,0-.814.81348Zm.16065-1.874a.6338.6338,0,0,0,.18555.4707.65549.65549,0,0,0,.48144.18262.64313.64313,0,0,0,.47217-.18164.5999.5999,0,0,0,.18408-.44434.62214.62214,0,0,0-.18945-.46094.69749.69749,0,0,0-.94531-.0039A.58886.58886,0,0,0,62.52543,122.92911Z"
          />
        </g>
        <g
          className={`${
            sceneName === 'scene_A019'
              ? 'white-text'
              : elementOnHover === 'A19'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 19)}
          onMouseOver={() => setElementOnHover('A19')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M54.11332,91.99893l1.55859-4.05859h.57862l1.66064,4.05859h-.61181l-.47315-1.22949H55.12943l-.4458,1.22949Zm1.1709-1.666h1.376l-.42382-1.124q-.19336-.51269-.2876-.84179a5.19884,5.19884,0,0,1-.21875.77539Z"
          />
          <path
            className="cls-7"
            d="M60.29887,91.99893h-.49854v-3.1748a2.59009,2.59009,0,0,1-.47168.34277,3.39763,3.39763,0,0,1-.5249.25781v-.48144a3.02807,3.02807,0,0,0,.731-.47656,1.87326,1.87326,0,0,0,.44287-.543h.32129Z"
          />
          <path
            className="cls-7"
            d="M61.93314,91.06046l.479-.044a.82916.82916,0,0,0,.23242.48926.638.638,0,0,0,.43994.15234.76633.76633,0,0,0,.40283-.10449.86256.86256,0,0,0,.28369-.28125,1.65288,1.65288,0,0,0,.18555-.47461,2.50273,2.50273,0,0,0,.07471-.60937c0-.02247-.001-.05567-.00244-.09961a1.14447,1.14447,0,0,1-.40869.38671,1.10946,1.10946,0,0,1-.56055.14747,1.13452,1.13452,0,0,1-.85254-.36524,1.336,1.336,0,0,1-.34863-.96289,1.369,1.369,0,0,1,.36377-.99414,1.21678,1.21678,0,0,1,.91211-.377,1.29834,1.29834,0,0,1,.72412.21386,1.32784,1.32784,0,0,1,.498.60743,2.98482,2.98482,0,0,1,.17041,1.1416,3.69478,3.69478,0,0,1-.16894,1.23926,1.447,1.447,0,0,1-.50244.70117,1.296,1.296,0,0,1-.78174.24121,1.07275,1.07275,0,0,1-1.14063-1.00781Zm2.04-1.791a.97636.97636,0,0,0-.228-.68066.71623.71623,0,0,0-.54981-.252.75711.75711,0,0,0-.57861.27149,1.00551,1.00551,0,0,0-.2461.70312.86559.86559,0,0,0,.23389.62891.76768.76768,0,0,0,.57715.24219.73669.73669,0,0,0,.56885-.24219A.95287.95287,0,0,0,63.97318,89.26944Z"
          />
        </g>
      </g>
      <g id="state_2_" className="st9">
        <path
          id="plate_12_"
          className="st10"
          d="M161.9693756,153.6339111h-0.0000305
		c-5.5916138,0-10.1665802-4.5749664-10.1665802-10.1665802v-15.4886246c0-5.5916214,4.5749664-10.1665802,10.1665802-10.1665802
		h0.0000305c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886246
		C172.1359558,149.0589447,167.5609894,153.6339111,161.9693756,153.6339111z"
        />
        <text
          id="_x33__3_"
          transform="matrix(1 0 0 1 176.9271698 145.2484283)"
          className="st10 st11 st12"
        >
          2022-
        </text>
        <text
          transform="matrix(1 0 0 1 177.3119965 130.4934998)"
          className="st10 st11 st12"
        >
          2021
        </text>
        <g className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="_x33__2_" className="st13">
          <path
            className="st6"
            d="M177.6943817,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H177.6943817z"
          />
          <path
            className="st6"
            d="M186.1523895,127.6712952c0,1.8789063-0.6962891,2.9150391-1.9208984,2.9150391
			c-1.0791016,0-1.8095703-1.0107422-1.8271484-2.8378906c0-1.8535156,0.7988281-2.8730469,1.9208984-2.8730469
			C185.4902802,124.8753967,186.1523895,125.9125061,186.1523895,127.6712952z M183.1523895,127.7562561
			c0,1.4365234,0.4423828,2.2529297,1.1220703,2.2529297c0.7646484,0,1.1308594-0.8925781,1.1308594-2.3037109
			c0-1.359375-0.3486328-2.2519531-1.1220703-2.2519531C183.628952,125.4535217,183.1523895,126.2523499,183.1523895,127.7562561z"
          />
          <path
            className="st6"
            d="M187.269577,130.4935608v-0.4589844l0.5859375-0.5693359
			c1.4111328-1.3427734,2.0488281-2.0566406,2.0566406-2.8896484c0-0.5615234-0.2714844-1.0800781-1.0957031-1.0800781
			c-0.5019531,0-0.9179688,0.2548828-1.1728516,0.4677734l-0.2382813-0.5273438
			c0.3828125-0.3222656,0.9267578-0.5605469,1.5634766-0.5605469c1.1904297,0,1.6914063,0.8154297,1.6914063,1.6064453
			c0,1.0195313-0.7392578,1.84375-1.9033203,2.9658203l-0.4423828,0.4082031v0.0166016h2.4814453v0.6210938H187.269577z"
          />
          <path
            className="st6"
            d="M193.6797333,125.6654358h-0.0166016l-0.9609375,0.5185547l-0.1445313-0.5693359l1.2070313-0.6455078
			h0.6376953v5.5244141h-0.7226563V125.6654358z"
          />
        </g>
        <g id="down_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,135.5897064c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248322,3.2714233,7.3962555,7.3962555,7.3962555c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962555
			C169.3656158,138.8611298,165.9519653,135.5897064,161.9693604,135.5897064z"
          />
        </g>
        <g id="up_10_" className="st13">
          <path
            className="st0"
            d="M161.9693604,120.7971802c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962555
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C169.3656158,124.0686035,165.9519653,120.7971802,161.9693604,120.7971802z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 11 ? (
          <g id="A11">
            <linearGradient
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              gradientUnits="userSpaceOnUse"
              x1="214.0609436"
              y1="219.9933624"
              x2="214.0609436"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000116942025443687874220000014186524061492083896_"
              style={{
                fill: 'url(#A11cone_00000116942025443687874220000014186524061492083896_)',
              }}
              d="
			M33.1855202,69.3973846c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L-11.7044773,44.127388v43.7900009L33.1855202,69.3973846z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000057837914670847427640000009873533890992381883_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 12 ? (
          <g id="A12">
            <linearGradient
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              gradientUnits="userSpaceOnUse"
              x1="162.3573151"
              y1="244.4725494"
              x2="162.3573151"
              y2="199.5625458"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>
            <path
              id="A11cone_00000049918791379197865380000014062642777780403637_"
              style={{
                fill: 'url(#A11cone_00000049918791379197865380000014062642777780403637_)',
              }}
              d="
			M57.6647034,17.6937523c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L12.7747059-7.5762444v43.7900009L57.6647034,17.6937523z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11_2_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 13 ? (
          <g id="A13">
            <linearGradient
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              gradientUnits="userSpaceOnUse"
              x1="162.1185608"
              y1="286.0395813"
              x2="162.1185608"
              y2="241.1295929"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000101824782659766024400000003894925743768571553_"
              style={{
                fill: 'url(#A11cone_00000101824782659766024400000003894925743768571553_)',
              }}
              d="
			M99.2317581,17.4550018c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L54.3417587-7.8149948v43.7900009L99.2317581,17.4550018z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000121259743375093461190000013669420362651410074_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 14 ? (
          <g id="A14">
            <linearGradient
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              gradientUnits="userSpaceOnUse"
              x1="211.7723846"
              y1="309.9545898"
              x2="211.7723846"
              y2="265.0445862"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000104685683903993578170000014400541892270177670_"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
              style={{
                fill: 'url(#A11cone_00000104685683903993578170000014400541892270177670_)',
              }}
              d="
			M123.146759,67.1088257c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.2567596,41.838829V85.62883L123.146759,67.1088257z"
            />

            <circle
              id="A11_00000103246458550930406200000005406084220527516043_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 15 ? (
          <g id="A15">
            <linearGradient
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              gradientUnits="userSpaceOnUse"
              x1="237.6501465"
              y1="309.7736206"
              x2="237.6501465"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000132062808605534171860000004161307431760505756_"
              style={{
                fill: 'url(#A11cone_00000132062808605534171860000004161307431760505756_)',
              }}
              d="
			M122.9657669,92.9865952c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,67.7165985v43.7900009L122.9657669,92.9865952z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 16 ? (
          <g id="A16">
            <linearGradient
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              gradientUnits="userSpaceOnUse"
              x1="261.5904846"
              y1="309.7736206"
              x2="261.5904846"
              y2="264.8636169"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000161621834216018166600000011104810949275289753_"
              style={{
                fill: 'url(#A11cone_00000161621834216018166600000011104810949275289753_)',
              }}
              d="
			M122.9657669,116.926918c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982L78.0757675,91.6569214v43.7899933L122.9657669,116.926918z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 17 ? (
          <g id="A17">
            <linearGradient
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              gradientUnits="userSpaceOnUse"
              x1="293.358429"
              y1="288.4524536"
              x2="293.358429"
              y2="243.5424652"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000046338767822234361200000006092721421211844490_"
              style={{
                fill: 'url(#A11cone_00000046338767822234361200000006092721421211844490_)',
              }}
              d="
			M101.6446304,148.69487c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0399933,0.2100067-2.0299988,0.6000061-2.9199982L56.754631,123.4248734v43.7900009L101.6446304,148.69487z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 18 ? (
          <g id="A18">
            <linearGradient
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              gradientUnits="userSpaceOnUse"
              x1="273.7284241"
              y1="219.9933624"
              x2="273.7284241"
              y2="175.0833588"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000119105400635965422190000001418125762866781067_"
              style={{
                fill: 'url(#A11cone_00000119105400635965422190000001418125762866781067_)',
              }}
              d="
			M33.1855202,129.0648804c-0.3699951-0.8899994-0.5800018-1.8600082-0.5800018-2.890007
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900085L33.1855202,129.0648804z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
        {apartmentNumber === 19 ? (
          <g id="A19">
            <linearGradient
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              gradientUnits="userSpaceOnUse"
              x1="242.9080505"
              y1="220.4395905"
              x2="242.9080505"
              y2="175.5295868"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="A11cone_00000030460207653560477860000005665363980855605161_"
              style={{
                fill: 'url(#A11cone_00000030460207653560477860000005665363980855605161_)',
              }}
              d="
			M33.6317444,98.2444992c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299988,0.6000061-2.9199982l-44.9100037-19.4599991v43.7900009L33.6317444,98.2444992z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="A11_00000026121933847005876040000015206048344269310858_"
              className="st18"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator_2_">
        <path
          id="plate_11_"
          className="st6"
          d="M172.1359558,110.5138321h-0.0000153c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665802
		V65.9649048c0-5.5916176,4.5749664-10.1665802,10.1665802-10.1665802h0.0000153
		c5.591629,0,10.1665955,4.5749626,10.1665955,10.1665802v34.3823471
		C182.3025513,105.9388733,177.7275848,110.5138321,172.1359558,110.5138321z"
        />
        <path
          id="plate_10_"
          className="st14"
          d="M178.3349304,90.8048477l-0.243988-38.549572
		c0-2.713562-3.7630005-6.7915688-8.5551605-6.7915688c-4.7922821,0-8.7990112,4.0780067-8.7990112,6.7915688l0.243988,38.549572
		c0,2.713562,3.7627411,6.6959915,8.5550232,6.6959915C174.3279419,97.5008392,178.3349304,93.5184097,178.3349304,90.8048477z"
        />
        <g id="_x33__00000042721953602652879380000005557696751629826692_">
          <path
            className="st15"
            d="M170.3662567,84.6122131l0.7470703-0.0996094c0.0859375,0.4233398,0.2314453,0.7285156,0.4384766,0.9155273
			c0.2060547,0.1870117,0.4570313,0.2802734,0.7529297,0.2802734c0.3515625,0,0.6484375-0.121582,0.890625-0.3652344
			s0.3632813-0.5454102,0.3632813-0.9052734c0-0.3432617-0.1113281-0.6264648-0.3359375-0.8491211
			c-0.2246094-0.2231445-0.5097656-0.3344727-0.8554688-0.3344727c-0.140625,0-0.3164063,0.027832-0.5273438,0.0830078
			l0.0830078-0.6557617c0.0498047,0.0053711,0.0898438,0.0083008,0.1201172,0.0083008
			c0.3183594,0,0.6054688-0.0830078,0.8603516-0.2495117c0.2539063-0.1660156,0.3818359-0.421875,0.3818359-0.7680664
			c0-0.2739258-0.0927734-0.5009766-0.2783203-0.6806641c-0.1855469-0.1801758-0.4248047-0.2700195-0.71875-0.2700195
			c-0.2900391,0-0.5322266,0.0913086-0.7265625,0.2739258c-0.1933594,0.1826172-0.3183594,0.456543-0.3730469,0.8222656
			l-0.7480469-0.1328125c0.0917969-0.5009766,0.2988281-0.8891602,0.6230469-1.1650391
			c0.3242188-0.2753906,0.7265625-0.4130859,1.2080078-0.4130859c0.3320313,0,0.6386719,0.0712891,0.9179688,0.2138672
			s0.4931641,0.3369141,0.6416016,0.5834961s0.2216797,0.5078125,0.2216797,0.784668
			c0,0.2631836-0.0703125,0.5024414-0.2109375,0.7182617c-0.1416016,0.2163086-0.3505859,0.3876953-0.6269531,0.5151367
			c0.359375,0.0830078,0.6386719,0.2553711,0.8378906,0.5170898c0.2001953,0.2612305,0.2998047,0.5888672,0.2998047,0.9819336
			c0,0.53125-0.1943359,0.9819336-0.5820313,1.3515625s-0.8769531,0.5541992-1.4697266,0.5541992
			c-0.5341797,0-0.9775391-0.1591797-1.3310547-0.4775391C170.6172333,85.5311584,170.4160614,85.1185608,170.3662567,84.6122131z"
          />
        </g>
        <path
          id="up_9_"
          className="st16"
          d="M169.1842957,51.8877754l-5.918808,7.6988144
		c-0.2241669,0.2915802-0.0162964,0.7135811,0.3514862,0.7135811h11.837616c0.3677826,0,0.5756531-0.4220009,0.3514862-0.7135811
		l-5.918808-7.6988144C169.7097931,51.656929,169.3617706,51.656929,169.1842957,51.8877754z"
        />
        <path
          id="down_9_"
          className="st16"
          d="M169.8872681,90.2491226l5.918808-7.6988144
		c0.2241669-0.2915802,0.0162964-0.7135849-0.3514862-0.7135849h-11.837616c-0.3677826,0-0.5756531,0.4220047-0.3514862,0.7135849
		l5.918808,7.6988144C169.3617706,90.4799728,169.7097931,90.4799728,169.8872681,90.2491226z"
        />
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M172.1359558,91.9988937c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962631
			c0,4.1248474,3.2714386,7.3962708,7.3962708,7.3962708c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962708
			C179.5322113,95.2703171,176.1185608,91.9988937,172.1359558,91.9988937z"
          />
          <path
            className="st6"
            d="M176.9719696,97.9728012l0.426712,0.5689468l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8360138-3.2714233l0.426712-0.5689468l4.5515442,2.9869537
			c0.1422424,0.1422348,0.2844696,0.1422348,0.426712,0L176.9719696,97.9728012z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M172.1359558,59.1772881c-4.1248322,0-7.3962708,3.2714233-7.3962708,7.3962669
			s3.2714386,7.3962631,7.3962708,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			S176.1185608,59.1772881,172.1359558,59.1772881z"
          />
          <path
            className="st6"
            d="M176.9719696,67.9959106l0.426712-0.5689468l-4.8360138-3.2714233
			c-0.1422424-0.1422348-0.426712-0.1422348-0.5689392,0l-4.8360138,3.2714233l0.426712,0.5689468l4.5515442-2.9869537
			c0.1422424-0.1422348,0.2844696-0.1422348,0.426712,0L176.9719696,67.9959106z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  11: [37.7855263, 66.627388],
  12: [62.2647095, 14.9237556],
  13: [103.8317642, 14.6850052],
  14: [127.7467651, 64.338829],
  15: [127.565773, 90.2165985],
  16: [127.565773, 114.1569214],
  17: [106.2446365, 145.924881],
  18: [37.7855263, 126.2948761],
  19: [38.2317505, 95.4745026],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: #e5e5e5;
  }
  .st2 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173229;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #3f3f3c;
  }
  .st7 {
    font-family: 'ArialMT';
  }
  .st8 {
    font-size: 5.6692901px;
  }
  .st9 {
    display: none;
  }
  .st10 {
    display: inline;
    fill: #3f3f3c;
  }
  .st11 {
    font-family: 'HelveticaNeue-Bold';
  }
  .st12 {
    font-size: 8.5039396px;
  }
  .st13 {
    display: inline;
  }
  .st14 {
    display: none;
    fill: none;
    stroke: #d5d4cf;
    stroke-width: 0.75;
    stroke-miterlimit: 10;
  }
  .st15 {
    fill: #d3d2cf;
  }
  .st16 {
    display: none;
    fill: #ffffff;
    stroke: #d5d4cf;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: url(#A11cone_00000006701802055735474760000008031376133445276836_);
  }
  .st18 {
    fill: #c65805;
  }
  .st19 {
    fill: url(#A11cone_00000048487034634946458470000009053901600795840686_);
  }
  .st20 {
    fill: url(#A11cone_00000160177246357132226490000006235421525919963041_);
  }
  .st21 {
    fill: url(#A11cone_00000171706322554458027240000001977764960933293753_);
  }
  .st22 {
    fill: url(#A11cone_00000105403858612024668370000004960751119937828525_);
  }
  .st23 {
    fill: url(#A11cone_00000089547808944577285040000014967835989955457422_);
  }
  .st24 {
    fill: url(#A11cone_00000023972319614920341830000002838320417749384127_);
  }
  .st25 {
    fill: url(#A11cone_00000112616541736602038460000011179927460975668664_);
  }
  .st26 {
    fill: url(#A11cone_00000102541562687683107140000016918210482507582647_);
  }

  #A11,
  #A12,
  #A13,
  #A14,
  #A15,
  #B39,
  #B40,
  #B41,
  #C65,
  #C66,
  #C67,
  #C68,
  #C69,
  #C70 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .st0 {
      fill: var(--yit-blue);
    }

    .st6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .on-hover {
    fill: var(--yit-blue);
    cursor: pointer;
  }

  .white-text .cls-7 {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
